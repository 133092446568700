import { PodActions } from '../actions/actionTypes';
import { SUCCESS, ERROR } from "../actions/status";
  
  export const initialState = {
    skuLogoPods: {
      pods: {},
      podsError: {}
    },
    contentPods: {
      pods: {},
      podsError: {}
    }
  };
  
  const contentPods = (state = initialState, action) => {
    const { type, response, error, payload } = action;
  
    switch (type) {
      case `${PodActions.GetContentPods}_${SUCCESS}`:
        return {
          ...state,
          contentPods: {
            ...state.contentPods,
            pods: {
              ...state.contentPods.pods,
              [payload.index]: response
            }
          }
        };
      case `${PodActions.GetContentPods}_${ERROR}`:
        return {
          ...state,
          contentPods: {
            ...state.contentPods,
            podsError: {
              ...state.contentPods.podsError,
              [payload.index]: error
            }
          }
        };
      case `${PodActions.GetSkuLogoPods}_${SUCCESS}`:
        return {
          ...state,
          skuLogoPods: {
            ...state.skuLogoPods,
            pods: {
              ...state.skuLogoPods.pods,
              [payload.index]: response
            }
          }
        };
      case `${PodActions.GetSkuLogoPods}_${ERROR}`:
        return {
          ...state,
          skuLogoPods: {
            ...state.skuLogoPods,
            podsError: {
              ...state.skuLogoPods.podsError,
              [payload.index]: error
            }
          }
        };
      default: {
        return state;
      }
    }
  };
  
  export default contentPods;
  