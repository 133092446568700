const httpConfig = {
  mode: 'cors', // no-cors, cors, *same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
};

const httpOptions = {
  get: (headers = {}) => ({
    method: 'GET',
    ...httpConfig,
    headers: {
      ...httpConfig.headers,
      ...headers
    }
  }),
  post: (payload, headers = {}) => ({
    method: 'POST',
    ...httpConfig,
    headers: {
      ...httpConfig.headers,
      ...headers
    },
    payload: JSON.stringify(payload),
    body: JSON.stringify(payload)
  }),
  put: (payload, headers = {}) => ({
    method: 'PUT',
    ...httpConfig,
    headers: {
      ...httpConfig.headers,
      ...headers
    },
    body: JSON.stringify(payload)
  }),
  delete: (headers = {}) => ({
    method: 'DELETE',
    ...httpConfig,
    headers: {
      ...httpConfig.headers,
      ...headers
    }
  }),
  htmlHeaders: {
    'Content-Type': 'text/plain'
  }
};

export default httpOptions;