import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './StructureAndSpectra.scss';
import StructureAndSpectraModal from './StructureAndSpectraModal';
import { CoreCard } from '@komodo-design-system/komodo-components-react';
import { getCountryCode, getLanguageCode } from '../../../../utils/cookies';
import { useDispatch, useSelector } from 'react-redux';
import getStructureAndSpectra from '../../../../actions/documents/getStructureAndSpectra';

// Spectra viewer url
export const spectraViewerUrl = (pnid) =>
  `/${getCountryCode()}/${getLanguageCode()}/home/life-science/cell-analysis/labeling-chemistry/fluorescence-spectraviewer.html?SID=srch-svtool&UID=${pnid}`;


const StructureAndSpectra = () => {
  const dispatch = useDispatch();
  const [modalType, setModalType] = useState(null);

  const { product, productId } = useSelector(state => state.product) ?? {};

  const { data } = useSelector(state => state.structureAndSpectra);

  const structure = data?.probesStructureList?.[0];
  const spectra = data?.probesSpectraList?.[0];

  const closeModal = () => setModalType(null);

  const openStructureModal = () => {
    setModalType('structure');
  };

  const openSpectraModal = () => {
    setModalType('spectra');
  };

  useEffect(() => {
    dispatch(getStructureAndSpectra(productId, product?.familyId))
  }, [productId, product?.familyId]);

  if (!data) {
    return null;
  }

  return (
    <div className="pdp-struct-spectra">
      {structure && (
        <CoreCard variant='outline' className="pdp-struct-spectra__structure">
          <div className="pdp-struct-spectra__details" slot="content">
            <div className="pdp-struct-spectra__title">
              <h2>
                <FormattedMessage id="structureTitle" />
              </h2>
            </div>
            <div className="pdp-struct-spectra__item">
              <FormattedMessage id="structureMolecularFormula" />
              <span
                dangerouslySetInnerHTML={{
                  __html: structure?.components?.[0]?.formula
                }}
              />
            </div>
            <div className="pdp-struct-spectra__item">
              <FormattedMessage id="structureMolecularWeight" />
              <span
                dangerouslySetInnerHTML={{
                  __html: structure?.components?.[0]?.molWeight
                }}
              />
            </div>
            <div className="pdp-struct-spectra__item">
              <FormattedMessage id="structureCasNameNumber" />
              <span
                dangerouslySetInnerHTML={{
                  __html: `${structure?.components?.[0]?.caName} ${structure?.components?.[0]?.caNumber}`
                }}
              />
            </div>
          </div>
          <div className="pdp-struct-spectra__image">
            {structure?.components?.[0]?.imageUrl.url && (
              <img
                alt="Chemical structure"
                src={structure?.components?.[0]?.imageUrl.url}
                onClick={openStructureModal}
                height="220"
                width="250"
              />
            )}
          </div>
        </CoreCard>
      )}
      {spectra && (
        <CoreCard className="pdp-struct-spectra__spectra">
          <div className="pdp-struct-spectra__details" slot="content">
            <div className="pdp-struct-spectra__title">
              <h2>
                <FormattedMessage id="spectraTitle" />
              </h2>
            </div>
            <div>
              <a href={spectraViewerUrl(spectra.pnid)}>
                <FormattedMessage id="spectraLinkTitle" />
              </a>
            </div>
            <div className="pdp-struct-spectra__image">
              {spectra?.mediaUrl && (
                <img
                  alt="Fluorescence spectra"
                  src={spectra?.mediaUrl}
                  onClick={openSpectraModal}
                  height="220"
                  width="250"
                />
              )}
            </div>
          </div>
        </CoreCard>
      )}

      {!!modalType && <StructureAndSpectraModal img={modalType === 'structure' ? structure?.components?.[0]?.imageUrl.url : spectra?.mediaUrl} type={modalType} onClose={closeModal} />}
    </div>
  );
};

StructureAndSpectra.propTypes = {
  docs: PropTypes.object
};

export default StructureAndSpectra;
