import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomerSupport from '../CustomerSupport/CustomerSupport';
import ContactUs from '../../../ContactUs/ContactUs';
import useActiveItem from "../../../../hooks/useActiveItem";
import useDeviceType from '../../../../hooks/useDeviceType';
import DevicesTypes from '../../../../constants/deviceTypes';
import { useSelector } from 'react-redux';

const HaveQuestions = React.memo(() => {
    const activeItem = useActiveItem();
    const isMobile = useDeviceType(DevicesTypes.Mobile);
    const isTablet = useDeviceType(DevicesTypes.Tablet);
    const isTabletOrMobile = isMobile || isTablet;
    const { contactUsPopUpEnabled } = useSelector((state) => state.product?.product?.presentationAttributes) ?? false;
    return (
        <>
            {!isTabletOrMobile && (contactUsPopUpEnabled ? (
                <div className="pdp-product-selector__contact-us-popup">
                    <span className='pdp-product-selector__contact-us-text'>
                        <FormattedMessage id="haveQuestions" />
                    </span>
                    <ContactUs sku={activeItem?.catalogNumber} />
                </div>) : (<CustomerSupport catalogNumber={activeItem?.catalogNumber} />
            ))}
        </>
    )
});

export default HaveQuestions;
