import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import "./ImagesPanel.scss";
import MobileSlider from './MobileSlider/MobileSlider';
import ItemImage from './ItemImage/ItemImage';
import MobileImageModal from './MobileImageModal/MobileImageModal';

const MobileImagesPanel = memo(({ isFamily, images, productTitle }) => {
  const [modalStartImageIdx, setModalStartImageIdx] = useState(null);
  const [activeImageIdx, setActiveImageIdx] = useState(0);

  const moreThanOneImage = images.length > 1;

  const handleImageClick = () => {
    setModalStartImageIdx(activeImageIdx);
  };

  const handleActiveImageChange = (idx) => {
    setActiveImageIdx(idx);
  }

  const handleModalClose = () => {
    setModalStartImageIdx(null);
  };

  return (
    <div className="pdp-images-panel">
      {!moreThanOneImage && <ItemImage isFamily={isFamily} imagePath={images[0]?.path} onImageClick={handleImageClick} />}
      {moreThanOneImage && <MobileSlider isFamily={isFamily} images={images} onImageClick={handleImageClick} onActiveImageChange={handleActiveImageChange} />}
      
      {modalStartImageIdx != null && <MobileImageModal isFamily={isFamily} startImageIdx={modalStartImageIdx} images={images} productTitle={productTitle} onModalClose={handleModalClose} />}
    </div>
  )
});

MobileImagesPanel.propTypes = {
  isFamily: PropTypes.bool,
  images: PropTypes.array.isRequired,
  productTitle: PropTypes.string
};

export default MobileImagesPanel;
