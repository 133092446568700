import { PriceActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";

export const priceInitialState = {
  isLegalChangeAppliedForWOP: false,
  isSpecialOfferEnabled: false,
  isJapanWOPEnabled: false,
  prices: null,
  fetched: false,
  selectedSubscription: null
};

const prices = (state = priceInitialState, action) => {
    const { type, response, payload } = action;
    switch (type) {
      case `${PriceActions.GetPrice}_${SUCCESS}`:
        return {
          ...state,
          isLegalChangeAppliedForWOP: response.isLegalChangeAppliedForWOP,
          isSpecialOfferEnabled: response.isSpecialOfferEnabled,
          isJapanWOPEnabled: response.isJapanWOPEnabled,
          prices: {
            ...response?.products
          },
          fetched: true
        };
      case `${PriceActions.GetPrice}_${PENDING}`:
        return {
          ...state,
          fetched: false
        };
      case `${PriceActions.GetPrice}_${ERROR}`:
        return {
          ...state,
          prices: [],
          fetched: true
        };
      case `${PriceActions.SelectedSubscription}`:
        return {
          ...state,
          selectedSubscription: payload
        };
      default:
        return state;
    }
  };

export default prices;
