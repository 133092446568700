import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';

import { getCountryCode, getLanguageCode } from '../../utils/cookies';
import useClientApp from '../../hooks/useClientApp';
import useLoggedInUser from '../../hooks/useLoggedInUser';

import './ContactUs.scss';
import clsx from 'clsx';

const ContactUs = ({ sku, isButton = false, customClassName }) => {
  const { hydrated } = useClientApp();
  const { isAnonymous } = useLoggedInUser();

  const fallback = <div className="pdp-contact-us"></div>;

  if (!hydrated) {
    return fallback;
  }

  const ContactUsModal = lazy(() => import('./ContactUsModalWrapper'));
  
  const countryCode = getCountryCode();
  const languageCode = getLanguageCode();

  return (
    <Suspense fallback={fallback}>
      <div className={clsx("pdp-contact-us", customClassName ? customClassName : '')}>
        <ContactUsModal
          sku={sku}
          countryCode={countryCode}
          languageCode={languageCode}
          isAnonymous={isAnonymous}
          usePrimary={isButton}
        />
      </div>
    </Suspense>
  );
};

ContactUs.propTypes = {
  sku: PropTypes.string,
  isAnonymous: PropTypes.bool,
  isButton: PropTypes.bool,
  customClassName: PropTypes.string
};

export default ContactUs;
