import React from 'react';
import PropTypes from 'prop-types';

import { CoreButton } from '@komodo-design-system/komodo-components-react';
import { CUSTOMIZE_PRODUCT } from '../../../../constants/constants';

import './CustomForms.scss';

const CustomForms = ({ customForms }) => {
  const handleClick = (customForm) => {
    if (customForm.name === CUSTOMIZE_PRODUCT) {
      window.open("static/assets/Custom_Media_Request_Form.pdf")
    }
    else {
      const path = customForm?.path;
      const formattedPath = path.replace(/(&productDescription=)(.*)/g, (match) => {
        const split = match.split('&productDescription=');
        const div = document?.createElement?.('div');
        div.innerHTML = split[1];
        const description = div.textContent;
        if (window?.encodeURIComponent) {
          return `&productDescription=${window.encodeURIComponent(description)}`;
        }
        return match;
      });
      window.open(formattedPath, '_self');
    }
  };
  return (
    <div className='pdp-custom-forms'>
      {customForms?.map((customForm) => (
        <div className='pdp-custom-forms--button'>
          <CoreButton
            key={customForm?.title}
            onClick={() => handleClick(customForm)}
            type="button"
            variant="outline"
            size="small"
            fullspace
          >
            <span slot="content">{customForm?.title}</span>
          </CoreButton>
        </div>
      ))}
    </div>

  );
};

CustomForms.propTypes = {
  customForms: PropTypes.array
};

export default CustomForms;
