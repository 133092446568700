import React from 'react';
import PropTypes from 'prop-types';

import './ErrorBanners.scss';

const ErrorBanners = ({ errorBanner }) => {
  if (errorBanner == null) {
    return null;
  }

  return (
    <div className="pdp-error-banners">
      {errorBanner}
    </div>
  );
};

ErrorBanners.propTypes = {
  errorBanner: PropTypes.node
};

export default ErrorBanners;