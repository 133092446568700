import React from 'react';
import PropTypes from 'prop-types';

import { PRICE_RULE_TYPES } from '../../constants/constants';
import { FormattedMessage } from 'react-intl';

const StrikeThroughPrice = ({ itemPrice }) => {
  let priceTypeStriked = null;

  if (itemPrice?.secondBestPriceType === PRICE_RULE_TYPES.SPECIALOFFER) {
    priceTypeStriked = 'specialOffer';
  }

  if (itemPrice?.strikethrough) {
    return (
      <span className="pdp-price-orderable__striked">
        {itemPrice?.secondBestPrice}&nbsp;

        {priceTypeStriked != null && (
          <span>
            <FormattedMessage id={priceTypeStriked} />
          </span>
        )}

      </span>
    );
  }
};

StrikeThroughPrice.propTypes = {
  itemPrice: PropTypes.object,
};

export default StrikeThroughPrice;
