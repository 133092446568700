import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import "./CertificateSdsLinks.scss";
import SearchIcon from "../../../../styles/images/icon-search.svg";
import SdsIcon from "../../../../styles/images/icon-sds.svg";
import { CERTIFICATES_BLOCK_ID, SDS_BLOCK_ID, PDP_TABS_IDS } from '../../../../constants/constants';
import { useSelector } from 'react-redux';
import { calcTabindex, getTabsList } from '../../../PdpDetails/MobileNav/MobileNav';
import scrollToElement from '../../../../utils/scrollToElement';
import useDeviceType from '../../../../hooks/useDeviceType';
import DevicesTypes from '../../../../constants/deviceTypes';
import clsx from 'clsx';
import useActiveItem from '../../../../hooks/useActiveItem';

const CertificateSdsLinks = () => {
  const history = useHistory();
  const isDesktop = useDeviceType(DevicesTypes.Desktop);
  const activeItem = useActiveItem();
  const { fetched: certificatesFetched, showCertificates } = useSelector((state) => state.certificates);
  const { sdsList, fetched: sdsFetched } = useSelector((state) => state.sds);
  const tabsList = getTabsList();

  const hasSds = sdsList?.length > 0;

  const onMobileClick = (id, searchDocName) => {
    const defIndex = calcTabindex(tabsList, id);
    document
      .getElementsByTagName('footer')?.[0]
      ?.style?.setProperty('display', 'none');
    setTimeout(() => {
      document
        .getElementsByClassName('child-route')?.[0]
        ?.style?.setProperty('position', 'static');
      document
        .getElementsByTagName('footer')?.[0]
        ?.style?.setProperty('display', 'block');
    }, 500);

    if (searchDocName) {
      history.push(
        `/${activeItem.catalogNumber}/tabs?defaultTab=${defIndex}&searchDoc=${searchDocName}`
      );
    }
    else {
      history.push(`/${activeItem.catalogNumber}/tabs?defaultTab=${defIndex}`);
    }
  };

  const handleCertificatesClick = () => {
    isDesktop ? scrollToElement(CERTIFICATES_BLOCK_ID) : onMobileClick(PDP_TABS_IDS.DOCUMENTS);
  };

  const handleSdsClick = () => {
    isDesktop ? scrollToElement(SDS_BLOCK_ID) : onMobileClick(PDP_TABS_IDS.DOCUMENTS, PDP_TABS_IDS.SDS);
  };

  if (certificatesFetched && sdsFetched && !showCertificates && !hasSds) {
    return null;
  }

  return (
    <div className={clsx({ "certificate-sds-links": true, "hide-links": !certificatesFetched && !sdsFetched })}>
      {showCertificates && (
        <div className="certificates-link" onClick={handleCertificatesClick} data-testid="certificates-link">
          <div className="certificates-link-icon">
            <img src={SearchIcon}></img>
          </div>
          <div className="certificates-link-text">
            <FormattedMessage id="certificates" />
          </div>
        </div>
      )}

      {hasSds && (
        <div className="sds-link" onClick={handleSdsClick} data-testid="sds-link">
          <div className="sds-link-icon">
            <img src={SdsIcon}></img>
          </div>
          <div className="sds-link-text">
            <FormattedMessage id="sds" />
          </div>
        </div>
      )}

    </div>
  );
};

export default CertificateSdsLinks;