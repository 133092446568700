import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CoreChip } from '@komodo-design-system/komodo-components-react';

import selectDifferentiator from '../../../../../../actions/productSelector/selectDifferentiator';
import { analyticsProductSpecificationClicks } from '../../../../../../services/analyticsEvents';
import MobileSkuDifferentiatorModal from './MobileSkuDifferentiatorModal/MobileSkuDifferentiatorModal';
import useActiveItem from '../../../../../../hooks/useActiveItem';
import { PRODUCT_SIZE_ID } from '../../../../../../constants/constants';
import useProduct from '../../../../../../hooks/useProduct';
import { waitTillNextCycle } from '../../../../../../utils/wait';

import "./MobileSkuDifferentiator.scss";
import { isInScrollableViewport } from '../../../../../../utils/viewport';

const MobileSkuDifferentiator = ({ skuDifferentiator, isFirst = false }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  const activeItem = useActiveItem();
  const product = useProduct();
  const price = useSelector((state) => state.prices?.prices?.find((price) => price.catalogNumber === activeItem?.catalogNumber));

  const differentiatorValues =
    useSelector(state => state.productSelector.differentiators[skuDifferentiator?.id])?.
      sort((a, b) => a.sequence - b.sequence) ?? [];
  
  const selectedDifferentiatorValue = differentiatorValues.find(value => value.selected);

  const isMatches = product?.items?.some((item, i, arr) => {
    return arr?.some((itemArr, index) => {
          return item?.catalogNumber === itemArr?.formattedCatalogNumber && i != index
    });
  });

  const isUnitSizeAndValueOne = skuDifferentiator.id === PRODUCT_SIZE_ID && (differentiatorValues.length === 1 || !isMatches);

  const innerContainerRef = useRef(null);
  const [mobileChipsViewPortHeightCss, setMobileChipsViewPortHeightCss] = useState({});
  const [innerScrollCss, setInnerScrollCss] = useState({});

  // This effect is for mobile only
  // It regulates height of each differentiator viewport to hide the scroll bar or the inner container
  // but keep the chips horizontally scrollable
  useEffect(() => {
    // Have to wait and let browser finish this cycle and process web-component core-chips tag rendering
    // So we could get correct height of the inner container
    (async () => {
      await waitTillNextCycle();

      const innerContainer = innerContainerRef.current;
      if (innerContainer) {
        const height = innerContainer.getBoundingClientRect().height + 10 /* to account for padding*/;

        setInnerScrollCss({ overflowX: "scroll" });
        setMobileChipsViewPortHeightCss({ height: height + "px" });
      }
    })();
  }, [differentiatorValues]);

  useEffect(() => {
    (async () => {
      await waitTillNextCycle();

      if (selectedDifferentiatorValue) {
        const innerContainer = innerContainerRef.current;
        if (innerContainer) {
          const selectedChip = innerContainer.querySelector('.pdp-chip-container[data-selected=true]');
          if (selectedChip) {

            // If the selected chip is not in the viewport, scroll to it
            if (!isInScrollableViewport(selectedChip, innerContainer)) {
              const selectedChipBounds = selectedChip.getBoundingClientRect();
              const scrollToLeft = selectedChipBounds.left - 4; // 4 to account for padding
              innerContainer.scrollTo({ left: scrollToLeft, behavior: 'smooth' });
            }
          }
        }
      }
    })();
  }, [selectedDifferentiatorValue]);

  if (!skuDifferentiator) {
    return null;
  }

  const handleSelectDifferentiatorValue = (specValue, isSelected) => {
    if (isSelected) {
      return false;
    }

    analyticsProductSpecificationClicks(activeItem, price?.formattedPrice?.listPrice, skuDifferentiator.name, specValue);
    dispatch(selectDifferentiator(skuDifferentiator.id, specValue));
    setModalOpen(false);
  };

  const handleMobileViewAll = () => {
    setModalOpen(true);
  };
if(!isUnitSizeAndValueOne)  {
  return (
    <div className="pdp-mobile-sku-differentiator">
      <div className="header-label">
        {skuDifferentiator.name}
      </div>
      
      {differentiatorValues.length > 1 && (
        <div className="header-label-mobile-view-all" onClick={handleMobileViewAll} data-testid="view-all">
          <FormattedMessage id="viewAll" />
        </div>
      )}

      <div className="chips-outer-container" style={mobileChipsViewPortHeightCss}>
        <div ref={innerContainerRef} className="chips-inner-container" style={innerScrollCss}>

          {isFirst === false && (
            <div className="pdp-chip-container" data-testid="pdp-single-chip-container">
              <span dangerouslySetInnerHTML={{ __html: selectedDifferentiatorValue.value }}></span>
            </div>
          )}

          {isFirst === true && differentiatorValues.map(({ value, selected }, index) => {
            return (
              <div className="pdp-chip-container" key={index} data-testid="pdp-chip-container" data-selected={selected}>
                <CoreChip
                  className="diff-chip"
                  usehtml
                  onChipClicked={() => handleSelectDifferentiatorValue(value, selected)}
                  selected={selected}
                >
                  <div dangerouslySetInnerHTML={{ __html: value }}></div>
                </CoreChip>
              </div>
            );
          })}

        </div>
      </div>

      {isModalOpen && <MobileSkuDifferentiatorModal
        name={skuDifferentiator.name}
        differentiatorValues={differentiatorValues}
        onSelectSpecValue={handleSelectDifferentiatorValue}
        onClose={() => setModalOpen(false)}
      />}
    </div>
  );
}
};

MobileSkuDifferentiator.propTypes = {
  skuDifferentiator: PropTypes.object.isRequired,
  isFirst: PropTypes.bool
};

export default MobileSkuDifferentiator;