import React from 'react';
import PropTypes from 'prop-types';

import { CoreButton } from '@komodo-design-system/komodo-components-react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Log } from '../../../../utils/log';
import { useDispatch } from 'react-redux';
import showAlert from '../../../../actions/ui/showAlert';
import setQuantity from '../../../../actions/quantity/setQuantity';
import clsx from "clsx";
import { analyticsAddToCart } from '../../../../services/analyticsEvents';

import './AddToCart.scss';
import { MAX_ADD_TO_CART_QTY } from '../../../../constants/constants';

const AddToCart = ({ item, price, quantity, variant = null, size = null, selectedSubscription, customClassName, onClickAnalyticsRelatedProducts, isSticky }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const finalPrice = price?.unFormattedPrice?.finalPrice || price?.unFormattedPriceForNoPrice?.finalPrice;

  const addToCartSignalsMarkup = {
    className: 'lw_signal_process',
    'data-parameters':
      'doc_id, signal_type, app_id, element_clicked, product_qty, price',
    'data-signal_type': 'add_to_cart',
    'data-element_clicked': 'pdp-add-to-cart',
    'data-app_id': "tfcom-product-details-ui-v3",
    'data-doc_id': item.catalogNumber,
    'data-product_qty': quantity,
    'data-price': `${finalPrice} ${price?.currency}`
  };

  const addToCartThroughMiniCart = (products) => {
    Log("Adding items to cart", products);
    if (window.miniCart) {
      window.miniCart.addProductsToCart(products);
      return true;
    }
    else {
      return false;
    }
  }

  const handleAddToCart = () => {
    if(quantity <= 0) {
      dispatch(
        showAlert(
          `${intl.formatMessage({ id: "commonInformational" })}:`,
          intl.formatMessage(
            { id: 'cartNoQuantity' },
            {
              maxQuantity: MAX_ADD_TO_CART_QTY
            }
          ),
          "inline",
          "information",
          "3000"
        )
      );
    }
    else {
      const product = {
        sku: item.catalogNumber,
        price: finalPrice,
        size: item.productSize,
        qty: quantity,
        description: item.shortDescription
      };
      if (item?.isSubscriptionProduct) {
        product.ratePlanId =
         selectedSubscription?.prodRatePlanId || '';
        product.ratePlanName =
          selectedSubscription?.nameRatePlanName || '';
        product.productRateChargeId =
          selectedSubscription?.ratePlanChargeID || '';
        product.billingPeriod =
         selectedSubscription?.billingPeriod || '';
        product.price =
          selectedSubscription?.price || product.price;
      }
      
      const products = [product];
      if (!addToCartThroughMiniCart(products)) {
        console.log('Error adding items to cart', products);
      } else {
        /* analytics */
        const relatedproductFormat = onClickAnalyticsRelatedProducts? onClickAnalyticsRelatedProducts('addtocart', true) : '';
        analyticsAddToCart(products[0], item, relatedproductFormat);
        dispatch(setQuantity(1));
      }
    }
  };

  return (
    <div className={clsx('pdp-add-to-cart', isSticky && 'sticky', customClassName ? customClassName : "")}>
      <CoreButton
        type="button"
        variant={variant ?? "primary"}
        size={size ?? "small"}
        fullspace
        onClick={handleAddToCart}
        {...addToCartSignalsMarkup}
      >
        <span slot="content">
          <FormattedMessage id="addToCart" />
        </span>
      </CoreButton>
    </div>
  );
};

AddToCart.propTypes = {
  item: PropTypes.object,
  price: PropTypes.object,
  quantity: PropTypes.number,
  isRelatedProduct: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  selectedSubscription: PropTypes.object,
  customClassName: PropTypes.string,
  onClickAnalyticsRelatedProducts: PropTypes.func,
  isSticky: PropTypes.bool
};

export default AddToCart;
