import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode } from '../../utils/cookies';
import { SkuAlternativesActions } from '../actionTypes';

const getSkuAlternatives = (catalogNumbers) => {   
    const countryCode = getCountryCode();

    const url = endpoints.basicSkuAlternatives(catalogNumbers, countryCode);
    const options = httpOptions.get();
  
    return apiRequest(SkuAlternativesActions.GetAlternatives, url, options);
};

export default getSkuAlternatives;
