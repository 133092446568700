import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import "./MobileModalSingleImage.scss";
import imageUrlBuilder from '../../../../../../utils/imageUrlBuilder';

const MobileModalSingleImage = ({ isFamily, image, productTitle }) => {
  if (!image) {
    return null;
  }

  return (
    <div className="pdp-mobile-modal-single-image">
      <img src={imageUrlBuilder(image.path) + "-650.jpg"} alt="Product" />
      {isFamily && (
        <div className='pdp-image-banner'>
          <FormattedMessage id="actualProductMayVary" />
        </div>
      )}
      {productTitle && <div className="pdp-mobile-modal-single-image-title" dangerouslySetInnerHTML={{ __html: productTitle }}></div>}
    </div>
  );
};

MobileModalSingleImage.propTypes = {
  isFamily: PropTypes.bool,
  image: PropTypes.object.isRequired,
  productTitle: PropTypes.string
};

export default MobileModalSingleImage;