export const userInitialState = {
    userData: null
};

const user = (state = userInitialState, action) => {
    const { type } = action;
    switch (type) {
        default: {
            return state;
        }
    }
};

export default user;
