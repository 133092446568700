import React from 'react';
import PropTypes from 'prop-types';

import { CoreButton } from '@komodo-design-system/komodo-components-react';
import iMatchIcon from '../../../../styles/images/icon-imatch.svg';
import { useIntl } from 'react-intl';
import './Imatch.scss';

const Imatch = ({ isB2BSession }) => {
  const intl = useIntl();
  const onImatchClick = () => {
    window.open('/order/imatch', isB2BSession ? '_self' : '_blank');
  }
  return (
    <div className='pdp-imatch' >
      <CoreButton
        label={intl.formatMessage({ id: "iMatchText" })}
        type="button"
        variant="outline"
        size="small"
        onClick={onImatchClick}
        showcaret
      >
        <span slot="icon">
          <img className="pdp-imatch__icon" src={iMatchIcon}></img>
        </span>
      </CoreButton>

    </div>
  );
};

Imatch.propTypes = {
  isB2BSession: PropTypes.bool
};

export default Imatch;
