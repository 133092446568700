import apiRequest from "../../http/apiRequest";
import endpoints from "../../http/endpoints";
import httpOptions from "../../server/http/httpOptions";
import { PodActions } from "../actionTypes";
import PropTypes from 'prop-types';

export const fetchContentPods = (contentPath, index) => {
    const options = httpOptions.get({
        'Content-Type': 'text/plain'
    }
    );
    options.payload = {
        index: index.toString()
    };
    return apiRequest(PodActions.GetContentPods, endpoints.getContentPods(contentPath), options);
};

fetchContentPods.propTypes = {
    contentPath: PropTypes.string.isRequired
};

export default fetchContentPods;