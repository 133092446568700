import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import endpoints from '../../../../http/endpoints';
import { getCountryCode, getLanguageCode } from '../../../../utils/cookies';
import './CustomerSupport.scss';

const CustomerSupport = ({ catalogNumber, centered }) => {
  const customerSupportUrl = endpoints.customerServiceUrl(catalogNumber, getCountryCode(), getLanguageCode());
  const technicalSupportUrl = endpoints.techSupportUrl(catalogNumber, getCountryCode(), getLanguageCode());

  return (
    <div
      className={classNames({
        'pdp-customer-support': true,
        'pdp-customer-support--centered': centered
      })}
    >
      <a
        className="pdp-customer-support__tech-support"
        href={technicalSupportUrl}
      >
        <FormattedMessage id="technicalSupport" />
      </a>
      <span className="pdp-customer-support__spacer" />
      <a
        className="pdp-customer-support__customer-support"
        href={customerSupportUrl}
      >
        <FormattedMessage id="customerService" />
      </a>
    </div>
  );
};

CustomerSupport.propTypes = {
  catalogNumber: PropTypes.string,
  centered: PropTypes.bool
};

export default CustomerSupport;
