const requestLogin = (url) => {
  console.log(`Invoke login, url: ${url}`);
  if (
    window?.invokeOidcLogin &&
    typeof window?.invokeOidcLogin === 'function'
  ) {
    window.invokeOidcLogin(url);
  }
};
export default requestLogin;
