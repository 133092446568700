import React from 'react';
import PropTypes from "prop-types";
import "./PdpTitle.scss";
import ShortenDescription from '../ShortenDescription';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { greenURL } from '../../../../utils/utils';
import clsx from 'clsx';
import staticImage from '../../../../utils/staticImage';
import useActiveItem from '../../../../hooks/useActiveItem';
import useDeviceType from '../../../../hooks/useDeviceType';
import DevicesTypes from '../../../../constants/deviceTypes';

const greenLeafImage = staticImage("green-leaf.png");

const PdpTitle = ({ showTitle, showDescription }) => {
  const activeItem = useActiveItem();
  const intl = useIntl();
  if (!activeItem) {
    return null;
  }

  const openGreenLeafLink = () => {
    window.open(greenURL(), '_self');
  };

  const isMobile = useDeviceType(DevicesTypes.Mobile);

  let shortDescription = activeItem.teaserText;

  const productTitle = <>
    <div className="umbrella-brand" dangerouslySetInnerHTML={{ __html: activeItem.umbrellaBrand }}></div>
    <span className="product-title" dangerouslySetInnerHTML={{ __html: activeItem.productTitleV3 }}></span>

    {activeItem.green && (
      <span className="green-leaf" onClick={openGreenLeafLink}>
        <CoreTooltip label={intl.formatMessage({ id: "greenLeafTooltip" })} alignment='start' placement='right'>
          <img
            src={greenLeafImage}
            alt={"Green features"}
          />
        </CoreTooltip>
      </span>
    )}
  </>

  const productDescription = activeItem.tfLongDescription ?
    <ShortenDescription description={activeItem.tfLongDescription}></ShortenDescription>
    :
    <div className="short-description" dangerouslySetInnerHTML={{ __html: shortDescription }}></div>;

  return (
    <div
      className={clsx("pdp-title", {
        "title-only": showTitle && !showDescription,
      })}
    >
      {showTitle && (
        <>
          {productTitle}
          {isMobile && (
            <div>
              <span className='pdp-catalog-number'>
                <FormattedMessage id="catalogNumber"></FormattedMessage>:
              </span>
              <span>{activeItem.catalogNumber}</span>
            </div>
          )}
          {showDescription && productDescription}
        </>
      )}
    </div>
  );
};

PdpTitle.propTypes = {
  showTitle: PropTypes.bool,
  showDescription: PropTypes.bool
};

export default PdpTitle;