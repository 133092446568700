import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import "./CopyLinks.scss";
import ShareCircledIcon from "../../../../styles/images/share-circled.svg";
import CopyLinksContextMenu from "./CopyLinksContextMenu";
import DevicesTypes from '../../../../constants/deviceTypes';
import useDeviceType from '../../../../hooks/useDeviceType';
import { CopyLinksTypes } from "./CopyLinks.Constants";
import { decodeHtmlEntities } from "../../../../utils/decodeHtmlEntities";
import { CoreTooltip } from "@komodo-design-system/komodo-components-react";
import { useIntl } from "react-intl";
import { isClientRuntime } from "../../../../utils/isClientRuntime";
import { waitTillElementIsPositioned } from "../../../../utils/wait";

const CopyLinks = ({ productId, productTitle }) => {
  const intl = useIntl();
  const isMobile = useDeviceType(DevicesTypes.Mobile);

  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0, ready: false });
  const imgRef = useRef();

  useEffect(() => {
    if (!isClientRuntime()) {
      return null;
    }
    
    setCurrentPosition().then(() => { 
      setIsVisible(true);
    });

    window.addEventListener("resize", setCurrentPosition);
    document.addEventListener("scroll", handleContextMenuClose);

    return () => {
      window.removeEventListener("resize", setCurrentPosition);
      document.removeEventListener("scroll", handleContextMenuClose);
    };
  }, []);

  const setCurrentPosition = async () => {
    const position = await waitTillElementIsPositioned(imgRef);
    setPosition({ top: position.top + 50, left: position.left - 200, ready: true });
  };

  const toggleContextMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleContextMenuClose = () => {
    setIsOpen(false);    
  };

  const handleClick = useCallback((copyLinksType) => {
    switch(copyLinksType) {
      case CopyLinksTypes.ProductName:
        window.navigator.clipboard.writeText(decodeHtmlEntities(productTitle));
        break;
      case CopyLinksTypes.CatalogNumber:
        window.navigator.clipboard.writeText(productId);
        break;
      case CopyLinksTypes.CatalogNumberAndProductName:
        window.navigator.clipboard.writeText(`${productId} ${decodeHtmlEntities(productTitle)}`);
        break;
      case CopyLinksTypes.Link:
        window.navigator.clipboard.writeText(window.location.href);
        break;
    }
  });

  return (
    <div className="pdp-copy-links">
      <div
        className="pdp-copy-links-icon-container"
        onClick={toggleContextMenu}
      >
        {isMobile ? (
          <img
            ref={imgRef}
            src={ShareCircledIcon}
            alt={"Share"}
            className="pdp-copy-links-icon"
            style={{ visibility: position.ready ? "visible" : "hidden" }}
          />
        ) : (
          <CoreTooltip
            label={intl.formatMessage({ id: "copyLinksTooltip" })}
            alignment="start"
            placement="bottom"
            zindex={1000}
            container={true}
          >
            <img
              ref={imgRef}
              src={ShareCircledIcon}
              alt={"Share"}
              className="pdp-copy-links-icon"
              style={{ visibility: position.ready ? "visible" : "hidden" }}
            />
          </CoreTooltip>
        )}
      </div>
      {isVisible && (
        <>
          {isOpen && <div className="context-blocker"></div>}
          <CopyLinksContextMenu
            top={position.top}
            left={position.left}
            onClick={handleClick}
            onClose={handleContextMenuClose}
            open={isOpen}
          />
        </>        
      )}
    </div>
  );
};

CopyLinks.propTypes = {
  productId: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
};

export default CopyLinks;