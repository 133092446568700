import { ProductActions } from "../actionTypes";
import { getUrlReplacementString, getUrlRouteParams } from "../../utils/utils";

const setActiveItem = (catalogNumber, popState) => {
  return (dispatch, getState) => {
    const path = window?.location?.pathname;

    if (window && path && !popState) {
      const isRegionalUrl = getState().ui.isRegionalUrl;
      const replacementString = getUrlReplacementString();
      const params = getUrlRouteParams(replacementString);
      params[!isRegionalUrl ? 1 : 3] = catalogNumber;
      const newPathname = `${replacementString}${params.join('/')}`;

      if (window?.location.pathname !== newPathname) {
        window.history.pushState(
          null,
          catalogNumber,
          newPathname
        );
      }
    }

    return dispatch({
      type: ProductActions.SetActiveItem,
      payload: catalogNumber
    });
  }
};

export default setActiveItem;