import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import DevicesTypes from "../../constants/deviceTypes";
import Breakpoints from "../../constants/breakpoints";
import setDeviceType from "../../actions/deviceType/setDeviceType";

const DeviceTypeProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onScreenResize = () => {
      const width = window.innerWidth;

      let type;
      if (width < Breakpoints.tabletMin) { 
        type = DevicesTypes.Mobile;
      }
      else if (width >= Breakpoints.tabletMin && width < Breakpoints.desktopMin) {
        type = DevicesTypes.Tablet;
      }
      else {
        type = DevicesTypes.Desktop;
      }

      dispatch(setDeviceType(type));
    };

    onScreenResize(true);
    window.addEventListener("resize", onScreenResize);

    return () => window.removeEventListener("resize", setDeviceType);
  }, []);

  return (
    <>
      {children}
    </>
  );
};

DeviceTypeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DeviceTypeProvider;