import { DocumentActions } from "../../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../../actions/status";

const initialState = {
  documents: [],
  documentsCount: 0,
  loading: false,
  fetched: false
};

const lulls = (state = initialState, action) => {
  switch (action.type) {
    case `${DocumentActions.GetLULL}_${SUCCESS}`:
      return {
        ...state,
        documents: action.response.assetTypes?.[0]?.documentTypes?.[0].assets,
        documentsCount: action.response.assetTypes?.[0]?.documentTypes?.[0].count,
        loading: false,
        fetched: true
      };
    case `${DocumentActions.GetLULL}_${PENDING}`:
      return {
        ...state,
        loading: true,
        fetched: false
      };
    case `${DocumentActions.GetLULL}_${ERROR}`:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default lulls;
