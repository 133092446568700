import { UiActions } from "../actions/actionTypes";
import ProductDetailTabs from "../constants/productDetailTabs";
import { Log } from "../utils/log";

export const uiInitialState = {
  activeTab: ProductDetailTabs.ProductOverview,
  activeImage: null,
  activeDifferentiators: {},
  activeItemIdx: 0,
  alert: null,
  staticBanner: null,
  scrollToTab: null,
  addToCartSticky: false,
  hydrated: false,
  isRegionalUrl: false
};

const ui = (state = uiInitialState, action) => {
  const { type, payload } = action;

  Log("ui", { type, payload });

  switch (type) {
    case UiActions.SetActiveTab:      
      return {
        ...state,
        scrollToTab: null,
        activeTab: payload
      };
    case UiActions.SetActiveImage:
      return {
        ...state,
        activeImage: payload
      };    
    case UiActions.SetAddToCartSticky:
      return {
        ...state,
        addToCartSticky: payload
      };
    case UiActions.ShowAlert:
      return {
        ...state,
        alert: {
          label: action.label,
          message: action.message,
          variant: action.variant,
          state: action.state,
          description: action.description,
          timer: action.timer
        }
      };
    
    case UiActions.ScrollToTab:
      return {
        ...state,
        scrollToTab: payload ?? null
      };
    case UiActions.SetHydrated:
      return {
        ...state,
        hydrated: true
      };
    default:
      return state;
  }
};

export default ui;