import he from "he";
import { isClientRuntime } from "./isClientRuntime";

export const decodeHtmlEntities = (str) => {
  if (isClientRuntime()) {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = str;
    return textarea.value;
  }
  else {
    return he.decode(str);
  }
};
