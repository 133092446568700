import React from 'react';
import PropTypes from 'prop-types';

import { getCountryCode, getLanguageCode } from '../../utils/cookies';
import { useSelector } from 'react-redux';
import useActiveItem from '../../hooks/useActiveItem';
import PriceOrderable from './PriceOrderable';
import { CoreButton } from '@komodo-design-system/komodo-components-react';
import { JAPAN_COUNTRY_CODE } from '../../constants/constants';
import LegacyListPrice from './LegacyPrice/LegacyListPrice';
import LegacyOnlinePrice from './LegacyPrice/LegacyOnlinePrice';
import ContactUs from '../ContactUs/ContactUs';

const PricePVNO = ({ priceState }) => {
  const activeItem = useActiveItem();
  const itemPrice = useSelector((state) => state.prices?.prices?.find((price) => price.catalogNumber === activeItem?.catalogNumber));

  const isSpecialOfferEnabled = priceState?.isSpecialOfferEnabled;
  const priceAccess = itemPrice?.priceAccess;
  const isJapanWOPEnabled = priceState?.isJapanWOPEnabled;

  const { contactUsPopUpEnabled } = useSelector((state) => state.product?.product?.presentationAttributes) ?? false;

  let contactusButton = '';
  if(contactUsPopUpEnabled) {
    contactusButton = (
      <div className='contact-us-link'>
        <ContactUs sku={activeItem?.catalogNumber} isButton={true} customClassName="pdp-commerce-box__contact_us"/>
      </div>
    )
  } else {
    contactusButton = (
      <CoreButton
        type="button"
        variant="primary"
        size="medium"
        fullspace
        onClick={() => onClickContactUs(activeItem)}
    >
      <span slot="content" dangerouslySetInnerHTML={{ __html: priceAccess?.pricingAccessMessage}}></span>
    </CoreButton>
    )
  }


  const onClickContactUs = (item) => {
    return window.open(`/${getCountryCode()}/${getLanguageCode()}/home/technical-resources/contact-us.${item?.catalogNumber}.html?supportType=SL`, '_self');
  }

  return (
    <>
      {isSpecialOfferEnabled && <PriceOrderable itemPrice={itemPrice} />}
      {!isSpecialOfferEnabled && <LegacyListPrice itemPrice={itemPrice} />}
      {(!isSpecialOfferEnabled && isJapanWOPEnabled && (getCountryCode() === JAPAN_COUNTRY_CODE)) && <LegacyOnlinePrice itemPrice={itemPrice} />}
      {contactusButton}
    </>
   
  );
};

PricePVNO.propTypes = {
  priceState: PropTypes.object
};

export default PricePVNO;
