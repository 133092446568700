import React from 'react';
import PropTypes from 'prop-types';
import StrikeThroughPrice from "./StrikeThroughPrice";

import { FormattedMessage } from 'react-intl';

const OnlinePrice = ({ itemPrice }) => {
  return (
    <div className="pdp-price-orderable">
      <span className="pdp-price-orderable__op-value">
        {itemPrice?.formattedPrice?.onlinePrice}
      </span>
      <div className="pdp-price-orderable__exclusive-text-bold">
            <FormattedMessage id="onlineExclusiveText" />
      </div>
      {!!itemPrice?.strikethrough && (
        <StrikeThroughPrice itemPrice={itemPrice} />
      )}
      <div className="pdp-price-orderable__sop-percentage">
        {(itemPrice?.percentageSavings ||
          itemPrice?.formattedPrice?.absoluteSavings) && (
          <span>
            <FormattedMessage id="save"></FormattedMessage>
            {itemPrice?.formattedPrice?.absoluteSavings && (
              <>&nbsp;{itemPrice?.formattedPrice?.absoluteSavings} </>
            )}
            {itemPrice?.percentageSavings && (
              <>{`(${itemPrice?.percentageSavings}%)`}</>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

OnlinePrice.propTypes = {
  itemPrice: PropTypes.object
};

export default OnlinePrice;
