import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';

import { CoreTooltip } from "@komodo-design-system/komodo-components-react";
import requestLogin from "../../utils/requestLogin";
import { useSelector, useDispatch } from "react-redux";
import FavoritesContextMenu from "./FavoritesContextMenu";
import { getFavoritesLists } from "../../actions/favorites/favorites";
import useDeviceType from "../../hooks/useDeviceType";
import { OLD_BASE_NAME } from '../../constants/constants';
import DevicesTypes from "../../constants/deviceTypes";
import { useIntl } from "react-intl";
import clsx from "clsx";
import './Favorites.scss';

import { isClientRuntime } from "../../utils/isClientRuntime";
import { waitTillElementIsPositioned } from "../../utils/wait";

export const FAVORITES_CONTEXT_MENU_WIDTH = 280;

const Favorites = ({ item, quantity, setRelatedProductsQuantity, onClickAnalyticsRelatedProducts, fromRelatedProducts }) => {
  const dispatch = useDispatch();
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const ref = useRef(null);
  const intl = useIntl();
  const user = useSelector((state) => state.user.userData);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ right: 0, ready: false });
  const [favoritesAdded, setFavoritesAdded] = useState(false);

  useEffect(() => {
    if (!isClientRuntime()) {
      return null;
    }
    
    if (user?.userId) {
      dispatch(getFavoritesLists(user?.userId));
    }
  }, [dispatch, user?.userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, setIsOpen, isOpen]);

  const setCurrentPosition = async () => {
    const position = await waitTillElementIsPositioned(ref);
    const pageDimensions = document.body.getBoundingClientRect();
    
    let right = 0;    
    if (fromRelatedProducts) {      
      const offset = 40;
      right = position.left - FAVORITES_CONTEXT_MENU_WIDTH > 0 ? -offset : -FAVORITES_CONTEXT_MENU_WIDTH + offset;
    }
    else {
      const offset = 70;
      right = pageDimensions.width - FAVORITES_CONTEXT_MENU_WIDTH > position.right ? -offset : 0;
    }

    setPosition({ right, ready: true });
  };

  const favouritesOnclick = (event) => {
    if (user?.isAnonymous) {
      requestLogin(`${OLD_BASE_NAME}/${item.catalogNumber}`);
    }
    else {
      setCurrentPosition().then(() => {
        setIsOpen(true);
      });
    }
    event.stopPropagation();
  };

  return (
    <div ref={ref}>
      <span className={clsx('pdp-favorites-icon', favoritesAdded ? "pdp-favorites-icon--active" : "")}>
        {(isMobile || !user?.isAnonymous) ?  <span onClick={(event) => favouritesOnclick(event)} className="icon-24-rating-mono"></span> :
          (
            <CoreTooltip label={intl.formatMessage({ id: "favoritesTooltip" })} alignment={fromRelatedProducts? 'start':'end'} placement='bottom'>
              <span onClick={(event) => favouritesOnclick(event)} className="icon-24-rating-mono"></span>
            </CoreTooltip>
          )
        }
        {isOpen && position.ready &&
          <FavoritesContextMenu
            right={position.right}
            user={user} 
            setFavoritesAdded={setFavoritesAdded} 
            setIsOpen={setIsOpen} 
            item={item} 
            quantity={quantity} 
            setRelatedProductsQuantity={setRelatedProductsQuantity} 
            onClickAnalyticsRelatedProducts={onClickAnalyticsRelatedProducts}
            fromRelatedProducts={fromRelatedProducts}
          />
        }
      </span>
    </div>
  )
};

Favorites.propTypes = {
  item: PropTypes.object,
  quantity: PropTypes.number,
  setRelatedProductsQuantity: PropTypes.func,
  fromRelatedProducts: PropTypes.bool,
  onClickAnalyticsRelatedProducts: PropTypes.func
};

export default Favorites;
