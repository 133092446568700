import { RecommendationActions } from "../actions/actionTypes";

export const recommendationsInitialState = {
  isDataAvailable: null
};

const recommendations = (state = recommendationsInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RecommendationActions.SetDataAvailable:    
      return {
        ...state,
        isDataAvailable: payload
      };
    default:
      return state;
  }
};

export default recommendations;