import { SubscribeActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";

const initialState = {
    stockAlert : null,
    error: null,
    isFetching: false
};

const stockAlert = (state = initialState, action) => {
  const { type, response, error } = action;
  switch (type) {
    case `${SubscribeActions.subscribeAlert}_${SUCCESS}`:
        return {
            ...state,
            stockAlert: response.items,
            isFetching: false
        };
    case `${SubscribeActions.subscribeAlert}_${ERROR}`:
      return {
          ...state,
          stockAlert: null,
          isFetching: false,
          error
      };
    case `${SubscribeActions.subscribeAlert}_${PENDING}`:
      return {
          ...state,
          isFetching: true
      };
    default:
      return state;
  }
};

export default stockAlert;
