import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CoreNotification } from '@komodo-design-system/komodo-components-react';

import endpoints from '../../../../http/endpoints';
import { getCountryCode, getLanguageCode } from '../../../../utils/cookies';
import { PRODUCT_ERROR_TYPES } from '../../../../constants/productErrorTypes';
import { Bold, Link } from '../MessageHelpers/MessageHelpers';

import "./ProductErrorBanner.scss";

const ProductErrorBanner = ({ productId, currentItem }) => {
  const intl = useIntl();

  const countryCode = getCountryCode(); 
  const langCode = getLanguageCode();  
  const countryI18nId = countryCode ? `country${countryCode}` : 'yourCountry';
  
  const label = intl.formatMessage({ id: "commonError" }) + ": "; 

  const catalogNumber = (currentItem?.catalogNumber ?? productId ?? "").toUpperCase();

  const customerService = intl.formatMessage({ id: 'customerService' });
  const customerServiceUrl = endpoints.customerServiceUrl(currentItem?.catalogNumber, countryCode, langCode);

  const technicalSupport = intl.formatMessage({ id: 'technicalSupport' });
  const technicalSupportUrl = endpoints.techSupportUrl(currentItem?.catalogNumber, countryCode, langCode);

  const replacementSKU = currentItem?.replacementSKU;
  const replacementSKUUrl = endpoints.oldCatalogUrl(replacementSKU);

  let messageId;

  switch (currentItem?.error) {
    case PRODUCT_ERROR_TYPES.ENTITLEMENT:
      messageId = 'notOrderableProduct';
      break;
    case PRODUCT_ERROR_TYPES.BLOCKED:
      messageId = 'blockedProduct';
      break;
    case PRODUCT_ERROR_TYPES.SKU_NOT_FOUND:
      messageId = 'skuNotFound';
      break;
    default:
      messageId = 'unhandledProductError';
  }
  
  const message = intl.formatMessage({ id: `${messageId}` }, {
    catalogNumber: <Bold message={catalogNumber} />,
    country: intl.formatMessage({ id: countryI18nId }),
    customerServiceURL: <Link name={customerService} url={customerServiceUrl} />,
    technicalSupportURL: <Link name={technicalSupport} url={technicalSupportUrl} />,
    replacementSKU: replacementSKU ? <Link name={replacementSKU} url={replacementSKUUrl} /> : ""
  });

  return (
    <div className="product-error-banner">
      <CoreNotification label={label} variant="inline" state="error" nocloseicon>
        <div className="product-error-banner-content" slot="content">
          {message}
        </div>
      </CoreNotification>
    </div>
  );
};

ProductErrorBanner.propTypes = {
  productId: PropTypes.string,
  currentItem: PropTypes.object
};

export default ProductErrorBanner;