import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CoreChip } from '@komodo-design-system/komodo-components-react';
import selectDifferentiator from '../../../../../../actions/productSelector/selectDifferentiator';
import { analyticsProductSpecificationClicks } from '../../../../../../services/analyticsEvents';
import useActiveItem from '../../../../../../hooks/useActiveItem';

import "./SkuDifferentiator.scss";
import resetViewAll from '../../../../../../actions/productSelector/resetViewAll';
import setViewAll from '../../../../../../actions/productSelector/setViewAll';

const SkuDifferentiator = ({ skuDifferentiator, isFirst = false }) => {
  const dispatch = useDispatch();

  const isViewAll = isFirst || useSelector(state => state.productSelector.viewAll[skuDifferentiator?.id]) === true;

  const activeItem = useActiveItem();
  const price = useSelector((state) => state.prices?.prices?.find((price) => price.catalogNumber === activeItem?.catalogNumber));

  const differentiatorValues =
    useSelector(state => state.productSelector.differentiators[skuDifferentiator?.id])?.
      sort((a, b) => a.sequence - b.sequence) ?? [];

  const applicableDifferentiatorValues = useMemo(() => differentiatorValues.filter(value => value.active), [differentiatorValues]);
  const displayedDifferentiatorValues = isViewAll ? differentiatorValues : applicableDifferentiatorValues;
  
  const hasOnlyOneDifferentiatorValue = differentiatorValues.length === 1;
  const allAreApplicable = differentiatorValues.length === applicableDifferentiatorValues.length;

  const handleSelectDifferentiatorValue = (value, isSelected) => {
    if (isSelected) {
      return false;
    }

    dispatch(resetViewAll());

    analyticsProductSpecificationClicks(activeItem, price?.formattedPrice?.listPrice, skuDifferentiator.name, value);
    dispatch(selectDifferentiator(skuDifferentiator.id, value));
  };

  const handleViewAll = () => {
    dispatch(setViewAll(skuDifferentiator.id, true));
  };

  return (
    <div className="pdp-sku-differentiator">
      <div className="pdp-sku-differentiator-header">
        <div className="header-label" dangerouslySetInnerHTML={{ __html: `${skuDifferentiator.name}:` }}></div>
        {isViewAll === false && allAreApplicable === false && hasOnlyOneDifferentiatorValue == false && (
          <div className="header-view-all" onClick={handleViewAll} data-testid="view-all">
            <FormattedMessage id="viewAll" />
          </div>
        )}
      </div>

      {displayedDifferentiatorValues.length === 1 && (
        <div className="pdp-chip-container" data-testid="pdp-single-chip-container">
          <span dangerouslySetInnerHTML={{ __html: displayedDifferentiatorValues[0].value }}></span>
        </div>
      )}

      {displayedDifferentiatorValues.length > 1 && displayedDifferentiatorValues.map(({ value, selected }, index) => {
        return (
          <div className="pdp-chip-container" key={index} data-testid="pdp-chip-container">
            <CoreChip
              className="diff-chip"
              usehtml
              onChipClicked={() => handleSelectDifferentiatorValue(value, selected)}
              selected={selected}
            >
              <div dangerouslySetInnerHTML={{ __html: value }}></div>
            </CoreChip>
          </div>
        );
      })}
    </div>
  );

};

SkuDifferentiator.propTypes = {
  skuDifferentiator: PropTypes.object.isRequired,
  isFirst: PropTypes.bool
};

export default SkuDifferentiator;