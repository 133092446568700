import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AVAILABILITY_CODES } from '../../../constants/constants';
import { SkuAlternatives } from '@tfecom-thermofisher/tfcom-sku-alternatives-v2';
import useSkuAlternatives from '../../../hooks/useSkuAlternatives';
import { getLanguageCode } from '../../../utils/cookies';
import useActiveItem from '../../../hooks/useActiveItem';

const ConditionalSkuAlternatives = ({ template }) => {
  const availabilityState = useSelector((state) => state.availability?.availability);
  const activeItem = useActiveItem();
  const availability = availabilityState?.find((availability) => availability.catalogNumber === activeItem?.catalogNumber);
  const { ready: skuAlternativesReady, skuAlternatives } = useSkuAlternatives();
  let hasSkuAlternatives = false;

  skuAlternatives?.forEach((alternative) => {
    if (alternative?.requestedCatalogNumber === activeItem.catalogNumber) {
      if (alternative?.skuAlternatives?.length > 0) {
        hasSkuAlternatives = true;
      } else {
        hasSkuAlternatives = false;
      }
    }
  });

  if (skuAlternativesReady && hasSkuAlternatives && availability?.availabilityCode === AVAILABILITY_CODES.OUT_OF_STOCK && !activeItem.isHideStockAvailability) {
    const lang = getLanguageCode();
    const analyticsObject = {
      availabilityLocation: template?.toLowerCase(),
      addMethod: 'pdp-commerce-box',
      availabilityPosition: 1,
      totalPositions: 1
    };

    return (
      <div className={clsx("pdp-commerce-box__available-alternatives-link")}>
        <SkuAlternatives
          portalId={"modal"}
          sku={activeItem.catalogNumber}
          skipMiniCart={false}
          locale={lang}
          analyticsData={analyticsObject}
        />
      </div>
    );
  }

  return null;
};

ConditionalSkuAlternatives.propTypes = {
  template: PropTypes.string
};

export default ConditionalSkuAlternatives;
