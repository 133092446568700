import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { PRODUCT_TYPES } from '../constants/constants';
import BundlePrice  from '../components/PdpHeader/PdpTitleAndProductSelector/PdpProductSelector/Bundles/BundlePrice/BundlePrice';
import NoImage from '../components/PdpHeader/PdpImages/NoImage/NoImage';
import { fetchBundleInformation } from '../actions/bundles/bundleInformation';

export default function useBundleSku() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const bundleListState = useSelector((state) => {
    return state.bundleInformation.bundleList;
  }, shallowEqual);

  const isLegalChangeAppliedForWOP = useSelector(
    (state) => state.bundleInformation.isLegalChangeAppliedForWOP,
    shallowEqual
  );

  const isSpecialOfferEnabled = useSelector(
    (state) => state.bundleInformation.isSpecialOfferEnabled,
    shallowEqual
  );

  const [bundlesOpen, setBundlesOpen] = useState({});

  const handleClickBundleBtn = (sku) => {
    if (!bundleListState?.[sku]?.data && !bundlesOpen[sku]) {
      dispatch(fetchBundleInformation(sku)).catch((e) => {
        console.log(e);
      });
    }
    setBundlesOpen((prevState) => ({ ...prevState, [sku]: !prevState[sku] }));
  };

  const getColumnsConfig = ({
    template,
    isSubscriptionProduct,
    isPriceVisible,
    isQuantityVisible,
    skuDifferentiators,
    isMobileView
  }) => {
    const catalogNumberLabel = (
      <FormattedMessage id="catalogNumberCapsColon" />
    );
    const unitSizeLabel = <FormattedMessage id="unitSizeCapsColon" />;
    const quantityLabel = <FormattedMessage id="quantityCapsColon" />;

    let columns = [];
    let colSpanDescription = 2;
    if (!isMobileView) {
      if (
        isSubscriptionProduct ||
        template === PRODUCT_TYPES.PFP ||
        (!isSubscriptionProduct && template === PRODUCT_TYPES.PDP)
      ) {
        colSpanDescription++;
      }
      //increase the span size with specs.length
      //when specs.length > 1 because description is always pushed and description is included in specs
      if (skuDifferentiators?.length && skuDifferentiators.length > 1) {
        colSpanDescription += skuDifferentiators.length;
      }

      columns.push({
        colSpan: colSpanDescription,
        value: (row) => {
          return (
            <div className="pdp-bundle-cell__description">
              <div className="pdp-bundle-cell__description-container">
                <div className="pdp-bundle-cell__description-img">
                  {row?.images?.[0]?.path && (
                    <img
                      src={`${row.images?.[0]?.path}-250.jpg`}
                      alt={intl.formatMessage({
                        id: 'bundleRelatedProductImage'
                      })}
                    />
                  )}
                  {!row.images?.[0]?.path && (
                    <NoImage smallSize/>
                  )}
                </div>
                <div className="pdp-bundle-cell__description-text-area">
                  <div>
                    {!!row.cannotBuyAsStandalone && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: row?.productTitle
                        }}
                      />
                    )}
                    {!row.cannotBuyAsStandalone && (
                      <a
                        href={row.productUrl}
                        dangerouslySetInnerHTML={{
                          __html: row?.productTitle
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <span className="pdp-bundle-cell-label">
                      {catalogNumberLabel}
                    </span>
                    <span className="pdp-bundle-cell-value">
                      {row?.catalogNumber}
                    </span>
                  </div>
                  <div>
                    <span className="pdp-bundle-cell-label">
                      {unitSizeLabel}
                    </span>
                    <span
                      className="pdp-bundle-cell-value"
                      dangerouslySetInnerHTML={{
                        __html: row?.productSize
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        },
        id: 'description'
      });
      if (isPriceVisible) {
        columns.push({
          colSpan: 2,
          value: (row) => (
            <BundlePrice
              priceData={row}
              mobile={isMobileView}
              isLegalChangeAppliedForWOP={isLegalChangeAppliedForWOP}
              isSpecialOfferEnabled={isSpecialOfferEnabled}
            />
          ),
          id: 'price'
        });
      }
      if (isQuantityVisible) {
        columns.push({
          value: (row) => (
            <div className="pdp-bundle-cell-value__quantity">
              {row?.baseQuantity}
            </div>
          ),
          id: 'quantity'
        });
      }
    } else {
      if (isMobileView) {
        columns.push({
          value: (row) => {
            return (
              <>
                {row?.images?.[0]?.path && (
                  <img
                    className="pdp-bundle-table__image"
                    src={`${row.images?.[0]?.path}-250.jpg`}
                    alt={intl.formatMessage({
                      id: 'bundleRelatedProductImage'
                    })}
                  />
                )}
                {!row.images?.[0]?.path && (
                  <NoImage smallSize/>
                )}
              </>
            );
          },
          id: 'image'
        });
      }
      columns.push({
        value: (row) => {
          return (
            <>
              {!!row.cannotBuyAsStandalone && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: row?.productTitle
                  }}
                />
              )}
              {!row.cannotBuyAsStandalone && (
                <a
                  href={row.productUrl}
                  dangerouslySetInnerHTML={{
                    __html: row?.productTitle
                  }}
                />
              )}
            </>
          );
        }
      });

      columns.push({
        value: (row) => (
          <>
            <div className="pdp-bundle-table__label bold">{catalogNumberLabel}</div>
            <div className="pdp-bundle-table__value">{row?.catalogNumber}</div>
          </>
        )
      });

      if (!isSpecialOfferEnabled) {
        columns.push({
          value: (row) => (
            <>
              <div className="pdp-bundle-table__label bold">{unitSizeLabel}</div>
              <div
                className="pdp-bundle-table__value"
                dangerouslySetInnerHTML={{
                  __html: row?.productSize
                }}
              />
            </>
          )
        });
      }

      if (isPriceVisible) {
        columns.push({
          value: (row) => (
            <BundlePrice
              priceData={row}
              mobile={isMobileView}
              isLegalChangeAppliedForWOP={isLegalChangeAppliedForWOP}
              isSpecialOfferEnabled={isSpecialOfferEnabled} /* Bundle Mobile view Pricing */
            />
          ),
          id: 'price'
        });
      }
      if (isQuantityVisible && !isMobileView) {
        columns.push({
          value: (row) => (
            <>
              <span className="pdp-bundle-table__label bold">{quantityLabel}</span>
              <span className="pdp-bundle-table__value">{row?.baseQuantity}</span>
            </>
          )
        });
      }
      if (isMobileView) {
        columns.push({
          value: (row) => (
            <>
              <div className="pdp-bundle-table__quantity-box">
                <input
                  class="c-input"
                  aria-label="quantity-value"
                  alt="item-quantity"
                  disabled
                  value={row?.baseQuantity}
                ></input>
              </div>
            </>
          ),
          id: 'quantity'
        });
      }
    }
    return columns;
  };

  return {
    handleClickBundleBtn,
    bundleListState,
    getColumnsConfig,
    bundlesOpen
  };
}
