import { DocumentActions } from '../../actions/actionTypes';
import { SUCCESS, ERROR, PENDING } from "../../actions/status";

export const sdsInitialState = {
  sdsList: null,
  kitSkuMap: {},
  isFetching: false,
  fetched: false,
  error: null
};

const sds = (state = sdsInitialState, action) => {
  const { type, response, error } = action;

  switch (type) {
    case `${DocumentActions.GetSDS}_${PENDING}`:
      return {
        ...state,
        isFetching: true
      };
    case `${DocumentActions.GetSDS}_${SUCCESS}`:
      return {
        ...state,
        sdsList: response.assetTypes?.[0]?.documentTypes?.[0]?.assets ?? null,
        kitSkuMap: response.kitSkuMap ?? {},
        count: response.assetTypes?.[0]?.documentTypes?.[0]?.assets?.count ?? 0,
        isFetching: false,
        fetched: true,
        error: null
      };
    case `${DocumentActions.GetSDS}_${ERROR}`:
      return {
        ...state,
        sdsList: null,
        kitSkuMap: {},
        count: 0,
        isFetching: false,
        fetched: true,
        error
      };
    default: {
      return state;
    }
  }
};

export default sds;
