import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import PdpImages from './PdpImages/PdpImages';
import PdpTitleAndProductSelector from './PdpTitleAndProductSelector/PdpTitleAndProductSelector';
import CommerceBox from './CommerceBox/CommerceBox';
import ContactUs from '../ContactUs/ContactUs';
import "./PdpHeader.scss";
import useDeviceType from '../../hooks/useDeviceType';
import DevicesTypes from '../../constants/deviceTypes';
import PdpTitle from './PdpTitleAndProductSelector/PdpTitle/PdpTitle';
import { CHEMICAL_TEMPLATES } from '../../constants/constants';
import ElementsStructureSearch from './PdpTitleAndProductSelector/ElementStructureSearch/ElementStructureSearch';
import CustomerSupport from './PdpTitleAndProductSelector/CustomerSupport/CustomerSupport';
import Subscription from './PdpTitleAndProductSelector/PdpProductSelector/Subscription/Subscription';
import useActiveItem from '../../hooks/useActiveItem';
import ProductBanner from '../ProductBanner/ProductBanner';

const PdpHeader = () => {
  const activeItem = useActiveItem();
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const isTablet = useDeviceType(DevicesTypes.Tablet);
  const isTabletOrMobile = isMobile || isTablet;
  const { product } = useSelector((state) => state.product);
  const productItem = product?.items?.[0];
  const prices = useSelector((state) => state.prices);
  const { contactUsPopUpEnabled } = useSelector((state) => state.product?.product?.presentationAttributes) ?? false;

  const isChemicalPage =!!CHEMICAL_TEMPLATES.includes(productItem.productTemplate);

  return (
    <div className="pdp-header">
      <div className='pdp-header__breadcrumbs-element-structure-container'>
        <Breadcrumbs activeItem={activeItem}/>
        {isChemicalPage && <ElementsStructureSearch />}
      </div>

      <ProductBanner />

      {isTabletOrMobile && <PdpTitle showTitle />}
      <div className="pdp-header-content row">
        <PdpImages />
        {isTabletOrMobile && (contactUsPopUpEnabled ? (<div className="pdp-product-selector__contact-us-popup">
          <span className='pdp-product-selector__contact-us-text'>
            <FormattedMessage id="haveQuestions" />
          </span>
          <ContactUs sku={activeItem?.catalogNumber} />
        </div>) : (<CustomerSupport catalogNumber={activeItem?.catalogNumber} />
        ))}
        {isTabletOrMobile && activeItem?.isSubscriptionProduct && <Subscription prices={prices} />}
        {isTabletOrMobile && <CommerceBox />}
        <PdpTitleAndProductSelector />
        {!isTabletOrMobile && <CommerceBox />}
      </div>
    </div>
  );
};

export default PdpHeader;