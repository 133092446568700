import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode } from '../../utils/cookies';
import { DocumentActions } from '../actionTypes';

const getSDS = (skus) => {
  const countryCode = getCountryCode();

  const url = endpoints.getSDS(skus, countryCode);
  const options = httpOptions.get();

  return apiRequest(DocumentActions.GetSDS, url, options);
};

export default getSDS;
