export const isInViewport = (element, headerRect, b2borSCMSheaderRect) => {
  const rect = element?.getBoundingClientRect();
  const headerBottoms = (headerRect?.height ?? 0) + (b2borSCMSheaderRect?.bottom ?? 0);
  return (
    rect?.top >= headerBottoms &&
    rect?.left >= 0 &&
    rect?.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isBelowAddToCart = (element, headerRect, b2borSCMSheaderRect) => {
  const rect = element?.getBoundingClientRect();
  const headerHeights = (headerRect?.height ?? 0) + (b2borSCMSheaderRect?.height ?? 0);
  return rect?.bottom < headerHeights;
};

export const isInScrollableViewport = (element, scrollableElement) => {
  const rect = element?.getBoundingClientRect();
  const scrollableRect = scrollableElement?.getBoundingClientRect();

  return (
    rect?.top >= scrollableRect?.top &&
    rect?.left >= scrollableRect?.left &&
    rect?.bottom <= scrollableRect?.bottom &&
    rect?.right <= scrollableRect?.right
  );
};