import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import './AlsoKnownAs.scss';
import clsx from 'clsx';

const AlsoKnownAs = ({ catalogNumber, formattedCatalogNumber, isActive }) => {
  const intl = useIntl();
  const isVisible = Boolean(
    catalogNumber &&
      formattedCatalogNumber &&
      String(catalogNumber) !== String(formattedCatalogNumber)
  );

  return (
    isVisible && (
      <div className={clsx({"pdp-also-known-as": true, "pdp-also-known-as--non-active": !isActive})} >
        <FormattedMessage
          id="alsoKnownAs"
          values={{
            catalogNumber: formattedCatalogNumber
          }}
        />
        <CoreTooltip label={intl.formatMessage({id: "alsoKnownAsTooltip"})} alignment='start' placement='top'>
          <span className="icon-16-info-mono pdp-also-known-as__tooltip" />
        </CoreTooltip>
      </div>
    )
  );
};

AlsoKnownAs.propTypes = {
  catalogNumber: PropTypes.string,
  formattedCatalogNumber: PropTypes.string,
  isActive: PropTypes.bool
};

export default AlsoKnownAs;
