import { SkuAlternativesActions } from '../actions/actionTypes';
import { SUCCESS, ERROR, PENDING } from "../actions/status";

export const skuAlternativesInitialState = {
  skuAlternatives: [],
  isFetching: false,
  fetched: false,
  error: null
};

const skuAlternatives = (state = skuAlternativesInitialState, action) => {
  const { type, response, error } = action;

  switch (type) {
    case `${SkuAlternativesActions.GetAlternatives}_${PENDING}`:
      return {
        ...skuAlternativesInitialState,
        isFetching: true
      };
    case `${SkuAlternativesActions.GetAlternatives}_${SUCCESS}`:
      return {
        ...state,
        skuAlternatives: response?.catalog ?? [],
        isFetching: false,
        fetched: true,
        error: null
      };
    case `${SkuAlternativesActions.GetAlternatives}_${ERROR}`:
      return {
        ...state,
        skuAlternatives: [],
        isFetching: false,
        fetched: true,
        error
      };
    default: {
      return state;
    }
  }
};

export default skuAlternatives;
