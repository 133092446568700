import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../Divider/Divider';

const NavItemLink = ({ tabId, label, onMobileLinkClick }) => {  
  return (
    <div className="pdp-pfp-mob-tab">
      <div onClick={() => onMobileLinkClick(tabId)}>
        <span className="pdp-pfp-mob-tab-title">
          {label}
        </span>
        <span className="pdp-pfp-mob-tab-icon icon-24-chevron-right-mono"></span>
      </div>
      <Divider />
    </div>
  )
};

NavItemLink.propTypes = {
  tabId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onMobileLinkClick: PropTypes.func.isRequired
};

export default NavItemLink;

