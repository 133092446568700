import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import imageUrlBuilder from '../../../../../../utils/imageUrlBuilder';
import { FormattedMessage } from 'react-intl';

import './MobileImageCarousel.scss';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  centerPadding: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  arrows: false,
  lazyLoad: false
};

const MobileImageCarousel = ({ isFamily, images, productTitle, startImageIdx = 0 }) => {  
  const slickRef = useRef();

  return (
    <div className="pdp-mobile-image-carousel" >

      <div className="arrow-btn prev" onClick={() => slickRef.current.slickPrev()}>
        <span className="icon-btn icon-24-chevron-left-mono"></span>
      </div>

      <div className="pdp-mobile-image-slider-container">
      
        <Slider ref={slickRef} {...sliderSettings} initialSlide={startImageIdx} className='pdp-mobile-image-carousel-slider'>
          {
            images.map((image, idx) =>
              <div key={idx}>
                <img src={imageUrlBuilder(image.path) + "-650.jpg"} alt="Product" />
              </div>
            )
          }
        </Slider>
        { isFamily && (
          <div className="pdp-mobile-image-carousel-vary">
            <FormattedMessage id="actualProductMayVary" />
          </div>
        )}
        <div className="pdp-mobile-image-carousel-title" dangerouslySetInnerHTML={{ __html: productTitle }}></div>
      </div>
     

      <div className="arrow-btn next" onClick={() => slickRef.current.slickNext()}>
        <span className="icon-btn icon-24-chevron-right-mono"></span>
      </div>

    </div>
  );
};

MobileImageCarousel.propTypes = {
  isFamily: PropTypes.bool,
  startImageIdx: PropTypes.number,
  images: PropTypes.array.isRequired,
  productTitle: PropTypes.string
};

export default MobileImageCarousel;