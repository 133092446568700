import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PriceRFQ from './PriceRFQ';
import PricePVNO from './PricePVNO';
import PriceLearnOrContactUs from './PriceLearnOrContactUs';
import PriceOrderable from './PriceOrderable';
import PriceDSVP from './PriceDSVP';
import useActiveItem from '../../hooks/useActiveItem';
import { PRICE_STATUS, STATUS_CODES } from '../../constants/constants';
import useDeviceType from '../../hooks/useDeviceType';
import LegacyPriceOrderable from './LegacyPrice/LegacyPriceOrderable';
import './Price.scss';
import DevicesTypes from '../../constants/deviceTypes';

const Price = ({ stickyPrice }) => {
  const priceState = useSelector((state) => state.prices);
  const activeItem = useActiveItem();
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const price = useSelector((state) => state.prices?.prices?.find((price) => price.catalogNumber === activeItem?.catalogNumber));
  const isSpecialOfferEnabled = priceState?.isSpecialOfferEnabled;
  if (!price || !activeItem) return null;

  const priceAccessStatus = price?.priceAccess?.status;
  const isPriceMasked = price?.isPriceMasked;
  const orderablePVNOPRICE = [PRICE_STATUS.ORDERABLE, PRICE_STATUS.PVNO, PRICE_STATUS.NO_PRICE];
  const orderablePVNO = [PRICE_STATUS.ORDERABLE, PRICE_STATUS.PVNO];

  let itemNotOrderable = false;
  if (Number(activeItem.statusCode) === STATUS_CODES.NOT_ORDERABLE ||
    Number(activeItem.statusCode) === STATUS_CODES.WEB_ORDERABLE_NOT_VIEWABLE
  ) {
    itemNotOrderable = true;
  }

  const getPriceComponent = () => {
    if (!itemNotOrderable && !isPriceMasked) {
      switch (priceAccessStatus) {
        case PRICE_STATUS.RFQ:
          return <PriceRFQ item={activeItem} priceAccess={price?.priceAccess} />;
        case PRICE_STATUS.PVNO:
          return <PricePVNO priceState={priceState} />;
        case PRICE_STATUS.LEARN_WHERE_TO_BUY:
        case PRICE_STATUS.CONTACT_US:
          return <PriceLearnOrContactUs item={activeItem} priceAccess={price?.priceAccess} />;
        case PRICE_STATUS.NO_PRICE:
          return <div> *** </div>;
        case PRICE_STATUS.DSVP:
          return <PriceDSVP />;
        case PRICE_STATUS.ORDERABLE:
          if(isSpecialOfferEnabled || !!priceState?.selectedSubscription) {
            return <PriceOrderable selectedSubscription={priceState?.selectedSubscription} itemPrice={price} />;
          }
          else {
            return <LegacyPriceOrderable selectedSubscription={priceState?.selectedSubscription} itemPrice={price} stickyPrice={stickyPrice}/>;
          }
        default:
          return <> - </>;
      }
    }
  }
  let showPriceLabel = true;
  if(isMobile && !orderablePVNOPRICE.includes(priceAccessStatus)) {
    showPriceLabel = false;
  }
  else if(isMobile && orderablePVNO.includes(priceAccessStatus) && !isSpecialOfferEnabled) {
    showPriceLabel = false;
  }


  const showUnderScore = !itemNotOrderable && priceAccessStatus !== PRICE_STATUS.ORDERABLE && priceAccessStatus !== PRICE_STATUS.NO_PRICE && priceAccessStatus !== PRICE_STATUS.PVNO;
  return (
    <>
      <div className={clsx({'pdp-price': true, 'sticky': stickyPrice})}>
        {showPriceLabel && (
          <>
           <span className='pdp-price__price-label'>
            <FormattedMessage id="pricelabelcurrency" values={{ currency: price?.currency }} />
           </span>
           {!activeItem?.isSubscriptionProduct && (<span className="pdp-price__productSize" dangerouslySetInnerHTML={{ __html: `/ ${activeItem?.productSize}` }}></span>)}
          </>
        )}
        {itemNotOrderable && <div className='pdp-price__not-orderable'> <FormattedMessage id="itemNotOrderable" /> </div>}
        {showUnderScore && showPriceLabel && <div> - </div>}
        {isPriceMasked && <div> *** </div>}
        {!priceState?.fetched && <div> - </div>}
        {priceState?.fetched && getPriceComponent()}
      </div>
    </>
  );
};

Price.propTypes = {
  stickyPrice: PropTypes.bool
};

export default Price;
