
export const DeviceTypeActions = Object.freeze({
  Set: "DEVICE_TYPE_SET"
});

export const UiActions = Object.freeze({
  SetActiveTab: "UI_SET_ACTIVE_TAB",
  SetActiveImage: "UI_SET_ACTIVE_IMAGE",
  ShowExtendedTable: "PRODUCT_SELECTOR_SHOW_EXTENDED_TABLE",
  ShowAlert: "SHOW_ALERT",
  ScrollToTab: "SCROLL_TO_TAB",
  SetAddToCartSticky: "SET_ADD_TO_CART_STICKY",
  SetHydrated: "SET_HYDRATED"
});

export const ProductSelectorActions = Object.freeze({
  SetView: "PRODUCT_SELECTOR_SET_VIEW",
  SetActiveDifferentiator: "PRODUCT_SELECTOR_SET_ACTIVE_DIFFERENTIATOR",
  SetSelectedValue: "PRODUCT_SELECTOR_SET_SELECTED_VALUE",
  SetActiveValue: "PRODUCT_SELECTOR_SET_ACTIVE_VALUE",
  SetViewAll: "PRODUCT_SELECTOR_SET_VIEW_ALL",
  ResetViewAll: "PRODUCT_SELECTOR_RESET_VIEW_ALL"
});

export const FavoritesActions = Object.freeze({
  AddToFavorites: "ADD_TO_FAVORITES",
  GetFavoritesLists: "GET_FAVORITES_LISTS"
});

export const PriceActions = Object.freeze({
  GetPrice: "GET_PRICE",
  SelectedSubscription: "SELECTED_SUBSCRIPTION",
});

export const ProductActions = Object.freeze({
  SetActiveItem: "PRODUCT_SET_ACTIVE_ITEM",
});

export const AvailabilityActions = Object.freeze({
  GetAvailability: "GET_AVAILABILITY"
});

export const QuantityActions = Object.freeze({
  setQuantity: "SET_QUANTITY"
})

export const SubscribeActions = Object.freeze({
  subscribeAlert: "SUBSCRIBE_ALERT"
});

export const CitationActions = Object.freeze({
  GetCitations: "GET_CITATIONS",
  ClearCitations: "CLEAR_CITATIONS"
});

export const BundleInformationActions = Object.freeze({
  GetBundleInformation: "GET_BUNDLE_INFORMATION"
});


export const DocumentActions = Object.freeze({
  GetCertificates: "GET_CERTIFICATES",
  GetSDS: "GET_SDS",
  GetOtherDocuments: "GET_OTHER_DOCUMENTS",
  GetLULL: "GET_LULL",
  getMolecularProbes: "GET_MOLECULAR_PROBES",
  getProtocols: "GET_PROTOCOLS",
  GetStructureAndSpectra: "GET_STRUCTURE_AND_SPECTRA"
});

export const FAQActions = Object.freeze({
  GetFAQs: "GET_FAQS"
});

export const RecommendationActions = Object.freeze({
  SetDataAvailable: "SET_RECS_DATA_AVAILABLE"
});

export const DictionaryActions = Object.freeze({
  GetDictionary: "GET_DICTIONARY"
});

export const RelatedProductActions = Object.freeze({
  GetRelatedProducts: "GET_RELATED_PRODUCTS",
  GetRelatedProductsAvailability: "GET_RELATED_PRODUCTS_AVAILABILITY",
  SetRelatedProductFilters: "SET_RELATED_PRODUCT_FILTERS"
});

export const SkuAlternativesActions = Object.freeze({
  GetAlternatives: "GET_ALTERNATIVES"
});

export const UserActions = Object.freeze({
  GetUser: "GET_USER"
});

export const FiguresActions = Object.freeze({
  GetFigures: "GET_FIGURES"
});

export const VideoActions = Object.freeze({
  GetVideos: "GET_VIDEOS"
});

export const MediaFormulationsActions = Object.freeze({
  GetMediaFormulations: "GET_MEDIA_FORMULATIONS"
});

export const PodActions = Object.freeze({
  GetContentPods: "GET_CONTENT_PODS",
  GetSkuLogoPods: "GET_SKU_LOGO_PODS"
});