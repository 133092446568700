import React from 'react';
import PropTypes from 'prop-types';

import "./ProductDescription.scss";
import { Log } from '../../../../../utils/log';
import clsx from 'clsx';
import useActiveItem from '../../../../../hooks/useActiveItem';

const ProductDescription = ({ fullWidth = false }) => {
  const activeItem = useActiveItem();

  if (!activeItem) {
    return null;
  }

  Log("ProductDescription", { catalogNumber: activeItem.catalogNumber });
  const productDescription = activeItem.tfLongDescription ?? activeItem.productLongDescription;
  return (
    <div className={clsx("pdp-product-description col-md-12", fullWidth === true ? "col-lg-12" : "col-lg-7 ")}>
      <div className="pdp-product-description-inner" id="product-description" dangerouslySetInnerHTML={{ __html: productDescription }}></div>
      {!activeItem.tfLongDescription && activeItem.productFeatures && <div className="pdp-product-description-inner" dangerouslySetInnerHTML={{ __html: activeItem.productFeatures }}></div>}
      {activeItem.prop65Warnings?.length > 0 &&
        activeItem.prop65Warnings.map((propWarning) => (
          <div
            key={`prop-warning-${propWarning}`}
            className="pdp-product-description-inner pdp-product-description-warnings"
            dangerouslySetInnerHTML={{ __html: propWarning }}
          />
        ))}
      {activeItem.disclaimerMessage && <div className="pdp-product-description-inner pdp-product-description-disclaimer" dangerouslySetInnerHTML={{ __html: activeItem.disclaimerMessage }}></div>}
      {activeItem.regulatoryDetailsMessage && (
        <div
          className="pdp-product-description-inner pdp-product-description-regulatory-stmt"
          dangerouslySetInnerHTML={{ __html: activeItem.regulatoryDetailsMessage }}
        />
      )}
      {activeItem.regulatoryStatementCatalog && (
         <div
          className="pdp-product-description-inner pdp-product-description-regulatory-stmt-catalog"
          dangerouslySetInnerHTML={{ __html: activeItem.regulatoryStatementCatalog }}
       />
      )}
      {activeItem.techDetailsAndRefs && (
        <div
          className="pdp-product-description-inner pdp-product-description-techdetails-refs"
          dangerouslySetInnerHTML={{ __html: activeItem.techDetailsAndRefs }}
        />
      )}
    </div>
  );
};

ProductDescription.propTypes = {
  fullWidth: PropTypes.bool
};

export default ProductDescription;
