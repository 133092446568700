import React from 'react';
import { useSelector } from 'react-redux';
import CopyLinks from './CopyLinks/CopyLinks';
import Imatch from './Imatch/Imatch';
import CertificateSdsLinks from './CertificateSdsLinks/CertificateSdsLinks';
import useDeviceType from '../../../hooks/useDeviceType';
import DevicesTypes from '../../../constants/deviceTypes';
import useActiveItem from '../../../hooks/useActiveItem';
import DesktopImagesPanel from './ImagesPanel/DesktopImagesPanel';
import MobileImagesPanel from './ImagesPanel/MobileImagesPanel';
import NoImagesPanel from './ImagesPanel/NoImagesPanel';
import useLoggedInUser from '../../../hooks/useLoggedInUser';
import { FAMILY } from '../../../constants/constants';

import "./PdpImages.scss";

const PdpImages = () => {
  const { imatchEnabled } = useSelector((state) => state.product?.product?.presentationAttributes) ?? false;
  const user = useLoggedInUser();
  const activeItem = useActiveItem();
  const isFamily = activeItem?.imageSource === FAMILY || !activeItem?.imageSource;

  if (activeItem == null) {
    return null;
  }

  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const isTablet = useDeviceType(DevicesTypes.Tablet);
  const isTabletOrMobile = isMobile || isTablet;

  const images = activeItem?.images ?? [];

  const hasImages = images.length > 0;

  return (
    <div className={'pdp-images col-lg-3 col-md-12'}>
      <div className="pdp-images-sticky">
        <CopyLinks productId={activeItem.catalogNumber} productTitle={activeItem.productTitleV3} />

        <div className={'pdp-images-inner'}>
          {!hasImages && <NoImagesPanel />}
          {hasImages && isTabletOrMobile && <MobileImagesPanel isFamily={isFamily} images={images} productTitle={activeItem.productTitleV3} />}
          {hasImages && !isTabletOrMobile && <DesktopImagesPanel isFamily={isFamily} images={images} productTitle={activeItem.productTitleV3} />}           
        </div>

        <CertificateSdsLinks />
        {imatchEnabled && <Imatch isB2BSession={user?.b2bSessionId} />}
      </div>
    </div>
  );
};

export default PdpImages;
