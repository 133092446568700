import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CoreCard } from '@komodo-design-system/komodo-components-react';
import getMediaFormulations from '../../../../../actions/mediaFormulations/getMediaFormulations';
import { FormattedMessage } from 'react-intl';
import './MediaFormulations.scss';

const MediaFormulations = () => {
    const dispatch = useDispatch();
    const { product, productId } = useSelector(state => state.product) ?? {};
    const mediaFormulations = useSelector(state => state.mediaFormulations?.mediaFormulationsList);

    useEffect(() => {
        dispatch(getMediaFormulations(productId, product?.familyId));
    }, [productId, product?.familyId]);

    if(mediaFormulations?.length) {
        return (
            <div className="pdp-media-formulations" data-testid="media-formulations">
                <CoreCard variant='floating' className='pdp-media-formulations-card' nopadding>
                    <div className="pdp-media-formulations-card-contents" slot="content">
                        <div className="pdp-media-formulations-title">
                            <FormattedMessage id='mediaFormulationsTitle' />
                        </div>
                        {mediaFormulations.map((mediaFormulation, index) => {
                           return (
                            <div className='pdp-media-formulations-desc' key={index}>
                               <a
                                    key={`media-formulation-${index}`}
                                    href={mediaFormulation.title?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    dangerouslySetInnerHTML={{
                                    __html: mediaFormulation.title?.text || ''
                                }}
                                />
                            </div>
                           )
                        })}
                    </div>
                </CoreCard>
            </div>
        );
    }
    return null;

}

export default MediaFormulations;
