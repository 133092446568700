import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CoreNotification } from '@komodo-design-system/komodo-components-react';
import DevicesTypes from "../../../../constants/deviceTypes";
import useDeviceType from "../../../../hooks/useDeviceType";
import { ellipsedText } from '../../../../utils/utils';
import './ControlCodeMessages.scss';
import { isClientRuntime } from '../../../../utils/isClientRuntime';
import useActiveItem from '../../../../hooks/useActiveItem';

const ControlCodeMessages = () => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const activeItem = useActiveItem();

  if (!isClientRuntime()) {
    return;
  }

  if (!activeItem?.controlCodeMessages?.length) {
    return null;
  }

  const onClickMore = () => {
    setExpanded(true);
  }

  const onClickLess = () => {
    setExpanded(false);
  }

  const controlCodeMsg = () => {
    let msg = '';
    let aTagMore = ` ... <a class="banner-more">More</a>`;
    let aTagLess = ` <a class="banner-less">Less</a>`;
    if (isMobile) {
      msg = ellipsedText(activeItem?.controlCodeMessages?.[0]?.description, 10);
      if (!expanded) {
        msg += aTagMore;
      }
      else {
        msg = activeItem?.controlCodeMessages?.[0]?.description + aTagLess;
      }
    }
    else {
      msg = activeItem?.controlCodeMessages?.[0]?.description;
    }
    return msg;
  };

  setTimeout(() => {
    const moreElement = document.getElementsByClassName('banner-more')[0];
    moreElement.addEventListener("click", onClickMore);
  }, 200);

  setTimeout(() => {
    const lessElement = document.getElementsByClassName('banner-less')[0];
    lessElement.addEventListener("click", onClickLess);
  }, 200);

  return (
    <div className="pdp-control-code-messages">
      <CoreNotification label={`${intl.formatMessage({ id: "commonInformational" })}:`} variant="inline" state="information" nocloseicon nostateicon>
        <span className="control-code-message" slot="content" dangerouslySetInnerHTML={{
          __html: controlCodeMsg()
        }}></span>
      </CoreNotification>
    </div>
  );
};

export default ControlCodeMessages;
