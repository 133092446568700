import React from 'react';
import PropTypes from 'prop-types';

import "./Card.scss";

const Card = ({ children, className, ...props }) => {
  return (
    <div className={`pdp-card ${className}`} {...props}>
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Card;