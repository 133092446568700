import { CoreNotification } from '@komodo-design-system/komodo-components-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';

import "./StaticBanners.scss";

const StaticBanners = () => {
  const intl = useIntl();

  const staticBanner = useSelector((state) => state.ui.staticBanner, shallowEqual);
  const { productTitle, catalogNumber, error, isDisplayed } = staticBanner ?? {};

  if (!isDisplayed) {
    return null;
  }
  
  const notificationType = error ? "error" : "success";
  const messageId = error
      ? 'requestStockAlertUnsubscribeError'
      : 'requestStockAlertUnsubscribeSuccess';

  const message = intl.formatMessage({ id: messageId }, { productTitle, catalogNumber });
  
  return (
    <div className="pdp-static-banners">
      <CoreNotification variant="inline" state={notificationType} nocloseicon>
        <div className="pdp-static-banners-content" slot="content" dangerouslySetInnerHTML={{__html: message}}>
        </div>
      </CoreNotification>
    </div>
  );
};

export default StaticBanners;