import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import fetchSkuLogoPods from '../../../../../actions/pod/fetchSkuLogoPods';
import '../ContentPods/ContentPods.scss';
import { SUPPORT_CENTER_PODNAME, SUPPLY_CENTER_PODNAME } from '../../../../../constants/constants';

const SkuLogoPods = ({ skuLogos }) => {
  const dispatch = useDispatch();
  const skuLogoPods = useSelector((state) => state.pods.skuLogoPods?.pods);

  let filteredSkuLogos = skuLogos.filter(pod => ![SUPPORT_CENTER_PODNAME, SUPPLY_CENTER_PODNAME].includes(pod.name));

  useEffect(() => {
    if (filteredSkuLogos?.length) {
        filteredSkuLogos.forEach((skuLogo, index) => {
          dispatch(
            fetchSkuLogoPods(skuLogo.contentPath, skuLogo.name, index)
          );
        });
      }
  }, []);

  let skuLogoContentPods = [];

  for (let logoKey in skuLogoPods) {
    if (skuLogoPods[logoKey]?.length) {
      skuLogoContentPods.push(
        <div className='pdp-content-pod-item'>
          <div slot="content" key={`logo-${logoKey}`} dangerouslySetInnerHTML={{ __html: skuLogoPods[logoKey] }} ></div>
        </div>
      );
    }
  }

  if(skuLogoContentPods.length > 0) {
    return (
      <div className='pdp-sku-logo-pod'>
        {skuLogoContentPods}
      </div>
    );
  }

  return null;
};

SkuLogoPods.propTypes = {
  skuLogos: PropTypes.array,
};

export default SkuLogoPods;