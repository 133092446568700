import React, { useCallback, useEffect } from "react";
import PdpHeader from "../PdpHeader/PdpHeader";
import "./Main.scss";
import PdpDetails from "../PdpDetails/PdpDetails";
import Alert from "../Alert/Alert";
import SEO from "../SEO/SEO";
import StaticBanners from "./StaticBanners/StaticBanners";
import useErrorBanner from "../../hooks/useErrorBanner";
import ErrorBanners from "./ErrorBanners/ErrorBanners";
import { useDispatch, useSelector } from "react-redux";
import setActiveItem from "../../actions/product/setActiveItem";
import selectDifferentiator from "../../actions/productSelector/selectDifferentiator";

const Main = () => {
  const errorBanner = useErrorBanner();
  const dispatch = useDispatch();
  const productItems = useSelector((state) => state.product.product?.items);
  const staticBanner = useSelector((state) => state.ui.staticBanner);

  const handlePopState = useCallback(() => {
    const parts = window?.location.pathname.split('/')
    const sku = parts[parts.length - 1]

    if (parts[parts.length - 2] === 'products' && sku) {
      const item = productItems.find((obj) => sku === obj.catalogNumber)

      dispatch(setActiveItem(sku, true));

      const specifications = item.specifications.reduce((acc, obj) => {
        acc[obj.id] = obj.value
        return acc
      }, {});

      item.skuDifferentiatorsV3.forEach((skuDifferentiator) => {
        if (specifications[skuDifferentiator.id] === undefined) return

        dispatch(selectDifferentiator(skuDifferentiator.id, specifications[skuDifferentiator.id], true));
      })
    }
  }, [productItems, dispatch])

  // Get page width
  useEffect(() => {
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [handlePopState])

  const canRenderMainContent = productItems != null && errorBanner === null && staticBanner === null;

  return (
    <div className="pdp-main">
      <Alert />
      <ErrorBanners errorBanner={errorBanner} />
      <StaticBanners />

      {canRenderMainContent && <PdpHeader />}
      {canRenderMainContent && <PdpDetails />}

      {canRenderMainContent && <SEO />}
    </div>
  );
};

export default Main;