import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import StrikeThroughPrice from './StrikeThroughPrice';

const AccountPrice = ({ itemPrice }) => {
  return (
    <div className="pdp-price-orderable">
      <div className="pdp-price-orderable__yp-value">
        {itemPrice?.formattedPrice?.yourPrice}
      </div>
      {!!itemPrice?.strikethrough && (
        <StrikeThroughPrice itemPrice={itemPrice} />
      )}
      <div className="pdp-price-orderable__sop-percentage">
        {(itemPrice?.percentageSavings ||
          itemPrice?.formattedPrice?.absoluteSavings) && (
            <span>
              <FormattedMessage id="save"></FormattedMessage>
              {itemPrice?.formattedPrice?.absoluteSavings && (
                <>&nbsp;{itemPrice?.formattedPrice?.absoluteSavings} </>
              )}
              {itemPrice?.percentageSavings && (
                <>{`(${itemPrice?.percentageSavings}%)`}</>
              )}
            </span>
          )}
      </div>
    </div>
  );
};

AccountPrice.propTypes = {
  itemPrice: PropTypes.object
};

export default AccountPrice;
