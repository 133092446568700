import React, { useState } from 'react';
import useActiveItem from '../../hooks/useActiveItem';
import { BANNER_TYPES, HIDE_TITLE_BANNERS } from '../../constants/constants';
import { ellipsedText } from '../../utils/addEllipsis';
import htmlDecode from '../../utils/htmlDecode';
import useDeviceType from '../../hooks/useDeviceType';
import DevicesTypes from '../../constants/deviceTypes';
import { CoreNotification } from '@komodo-design-system/komodo-components-react';

import './ProductBanner.scss';


const getAlertType = (bannerType) => {
    switch (bannerType) {
        case BANNER_TYPES.INFO:
        case BANNER_TYPES.ALERT:
            return 'information';
        case BANNER_TYPES.ERROR:
            return 'error';
        case BANNER_TYPES.WARNING:
            return 'warning';
        case BANNER_TYPES.SUCCESS:
            return 'success';
        default:
            return 'information';
    }
};

const ProductBanner = () => {
    const activeItem = useActiveItem();
    const [closed, setClosed] = useState({});
    const [expanded, setExpanded] = useState({});
    const isMobile = useDeviceType(DevicesTypes.Mobile);;

    const banners = activeItem.banners;

    const handleClose = (description) => {
        setClosed((prevClosed) => ({ ...prevClosed, [description]: true }));
    };

    const handleExpanded = (description) => {
        setExpanded((prev) => ({ ...prev, [description]: !expanded[description] }));
    };


    if (banners && !!banners.filter) {
        const displayedBanners = banners.filter(
            (banner) => !closed[banner.description]
        );

        return (
            <div className="pdp-banners">
                {displayedBanners.map((banner) => {
                    const type = getAlertType(banner.bannerType);
                    const bannerLabel = HIDE_TITLE_BANNERS.includes(banner.bannerType) ? '' : 'Informational:';
                    const needDecode = banner.decode || typeof banner.decode === 'undefined';
                  
                    const decodedBannerDescription = needDecode ? htmlDecode(banner.description) : null;
                    const ellipsedDescription = decodedBannerDescription != null && expanded[banner.description] !== true ? ellipsedText(decodedBannerDescription) : decodedBannerDescription;
                    const isEllipsed = expanded[banner.description] !== true && decodedBannerDescription != ellipsedDescription;
                    
                    return (
                        <div
                            className='pdp-banners__item'
                            onClick={() => handleExpanded(banner.description)}
                            key={banner.description}
                        >
                            <CoreNotification
                                state={type}
                                onNotificationClosed={() => handleClose(banner.description)}
                                variant={banner.float ? 'toast' : 'inline'}
                                label={bannerLabel}
                            >
                                <div slot="content">


                                    {banner.decode || typeof banner.decode === 'undefined' ? (
                                        <>
                                            {(!isMobile ||
                                                banner.bannerType === BANNER_TYPES.ERROR) && (
                                                    <span
                                                        className="pdp-banners__item-desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html: decodedBannerDescription
                                                        }}
                                                    />
                                                )}

                                            {!!isMobile &&
                                                banner.bannerType !== BANNER_TYPES.ERROR && (
                                                  <span>
                                                    <span
                                                        className="commerce-box-item-desc"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                expanded[banner.description]
                                                                    ? decodedBannerDescription
                                                                    : ellipsedDescription
                                                        }}
                                                    />
                                                    {isEllipsed && <span>...</span>}                                                    
                                                  </span>  
                                                )}
                                        </>
                                    ) : (
                                        banner.description
                                    )}
                                </div>
                            </CoreNotification>
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
};

export default ProductBanner;
