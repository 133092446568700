import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import ItemImageThumbnail from './ItemImageThumbnail/ItemImageThumbnail';

import "./ImageSlider.scss";


const ImageSlider = ({ images, isForModal = false, onImageClick, onMouseEnter }) => {
  const [sliderButtonsState, setSliderButtonsState] = useState(null);

  const sliderViewportRef = useRef();
  const overflowRef = useRef();

  const calculateSliderState = () => {
    const sliderViewportWidth = sliderViewportRef.current?.clientWidth;
    const overflowWidth = overflowRef.current?.clientWidth;
    const scrollPositionX = sliderViewportRef.current?.scrollLeft;

    if (sliderViewportWidth < overflowWidth) {
      let state = 0;

      if (scrollPositionX === 0) {
        state = 1;
      } else if (scrollPositionX + sliderViewportWidth > overflowWidth - 1) {
        state = -1;
      }

      setSliderButtonsState(state);
    }
  };

  useEffect(() => {
    calculateSliderState();
  }, [images]);

  const handleScrollButton = (isDisabled, isRight) => {
    if (isDisabled) {
      return;
    }

    const scrollAmount = isRight ? 80 : -80;
    sliderViewportRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth"
    });
  };

  const handleScroll = () => {
    calculateSliderState();
  }

  const handleActiveImageSwitch = (idx) => {
    onImageClick?.(idx);
  };

  return (
    <div className="pdp-image-slider">
      {sliderButtonsState != null && (
        <div className={clsx("pdp-image-slider-left", sliderButtonsState === 1 && "disabled")} onClick={() => handleScrollButton(sliderButtonsState === 1, false)}>
          <span className="icon-16-chevron-left-mono"></span>
        </div>
      )}
      <div className="pdp-image-slider-scrollable" ref={sliderViewportRef} onScroll={() => handleScroll()}>
        <div className="pdp-image-slider-overflow" ref={overflowRef} style={{ "width": isForModal ? "100%" : `${images.length * 80}px` }}>
          {
            images.map((image, idx) => {
              return <ItemImageThumbnail key={idx} image={image} imageIdx={idx} onClick={() => handleActiveImageSwitch(idx)} onMouseEnter={(idx) => {
                onMouseEnter?.(idx);
              }} />;
            })
          }
        </div>
      </div>
      {sliderButtonsState != null && (
        <div className={clsx("pdp-image-slider-right", sliderButtonsState === -1 && "disabled")} onClick={() => handleScrollButton(sliderButtonsState === -1, true)}>
          <span className="icon-16-chevron-right-mono"></span>
        </div>
      )}
    </div>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.array,
  isForModal: PropTypes.bool,
  onImageClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

export default ImageSlider;

