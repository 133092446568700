import React from 'react';
import PropTypes from 'prop-types';

import "./MessageHelpers.scss";

export const Bold = ({ message }) => { 
  return <strong className="message-helper-bold" dangerouslySetInnerHTML={{__html: message}}></strong>;
};

Bold.propTypes = {
  message: PropTypes.string.isRequired
};

export const Link = ({ name, url }) => {
  return <a className="message-helper-link" href={url} target="_blank" rel="noopener" dangerouslySetInnerHTML={{__html: name}}></a>;
};

Link.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

