import React from 'react';
import { FormattedMessage } from 'react-intl';
import Price from '../../../Price/Price';
import Quantity from '../Quantity/Quantity';
import AddToCart from '../AddToCart/AddToCart';
import Availability from '../Availability/Availability';
import PropTypes from "prop-types";
import clsx from 'clsx';

import "./AddToCartStickyDesktop.scss";

const AddToCartStickyDesktop = ({ item, price, quantity, priceAccessStatus, selectedSubscription, isSticky }) => {
  return (
    <div className={clsx("pdp-add-to-cart-sticky-desktop")}>
       <div className='pdp-title-catalog-number'>
          <div className='pdp-title' dangerouslySetInnerHTML={{__html: item.productTitleV3}}></div>
          <div>
            <span className='pdp-catalog-number'>
              <span className='pdp-catalog-number-label'>
                <FormattedMessage  id="catalogNumber"></FormattedMessage> 
              </span>
              {item.catalogNumber}
            </span>
          </div>
       </div>
       <div className='pdp-price-sticky'>
        <Price stickyPrice />
       </div>
       <div className='pdp-quantity'>
          <Quantity priceAccessStatus={priceAccessStatus} quantity={quantity} />
       </div>
       <div className='pdp-availability'>
         <Availability item={item} priceAccessStatus={priceAccessStatus} /> 
       </div>
       <div className="pdp-cart">
          <AddToCart item={item} price={price} quantity={quantity} selectedSubscription={selectedSubscription} isSticky={isSticky} size="medium" ></AddToCart>
        </div>
    </div>
  );
};
AddToCartStickyDesktop.propTypes = {
  price: PropTypes.object,
  item: PropTypes.object,
  quantity: PropTypes.number,
  priceAccessStatus: PropTypes.string,
  selectedSubscription: PropTypes.object,
  isSticky: PropTypes.bool
};


export default AddToCartStickyDesktop;
