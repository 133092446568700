import { upperBreadCrumbs } from '../../utils/utils';
export const BREADCRUMBLIST_TYPE = 'BreadcrumbList';
const CONTEXT = 'https://schema.org';

const getUrl = (xHostHeader) => {
  if (xHostHeader) {
    return `https://${xHostHeader}`;
  }
  return '';
};

const getBreadCrumbsSchema = ({ selectedProduct, intl, xHostHeader }) => {
  const url = getUrl(xHostHeader);

  const topBreadCrumbs = upperBreadCrumbs.map((breadCrumb) => ({
    name: intl.formatMessage({ id: breadCrumb.intlId }),
    url: `${url}${breadCrumb.url}`
  }));
  let availableBreadCrumbs = selectedProduct?.breadcrumbs?.map
    ? selectedProduct?.breadcrumbs
    : [];
  availableBreadCrumbs = availableBreadCrumbs.map((breadCrumb) => ({
    name: breadCrumb.name,
    url: `${url}${breadCrumb.url}`
  }));

  const breadCrumbsList = [
    ...topBreadCrumbs,
    ...availableBreadCrumbs,
    {
      name: selectedProduct?.productTitleV3,
      url: `${url}${selectedProduct?.productUrl}`
    }
  ];

  return {
    '@context': CONTEXT,
    '@type': BREADCRUMBLIST_TYPE,
    itemListElement: breadCrumbsList.map((listItem, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': listItem.url,
        name: listItem.name
      }
    }))
  };
};

export default getBreadCrumbsSchema;
