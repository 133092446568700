import React from 'react';
import PropTypes from 'prop-types';

import useDeviceType from '../../../../../hooks/useDeviceType';
import imageUrlBuilder from '../../../../../utils/imageUrlBuilder';
import DevicesTypes from '../../../../../constants/deviceTypes';
import { FormattedMessage } from 'react-intl';

import "./ItemImage.scss";

const ItemImage = ({ isFamily, imagePath, onImageClick }) => {  
  const isMobile = useDeviceType(DevicesTypes.Mobile);

  const suffix = isMobile ? "250" : "650";

  const handleClick = () => {
    onImageClick?.();
  };

  return (
    <>
      <img className="pdp-item-image" onClick={handleClick} src={`${imageUrlBuilder(imagePath)}-${suffix}.jpg`} data-testid="item-image" />
      {isFamily && ( 
        <div className='pdp-image-banner'>
          <FormattedMessage id="actualProductMayVary" />
        </div>
      )}
    </>
  );
};

ItemImage.propTypes = {
  isFamily: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  onImageClick: PropTypes.func
};

export default ItemImage;