import { decodeHtmlEntities } from '../utils/decodeHtmlEntities';
import ProductSelectorViews from '../constants/productSelectorViews';

const ANALYTICS_PRODUCTVIEW_TYPES = {
  [ProductSelectorViews.ButtonView]: 'Button View',
  [ProductSelectorViews.TableView]: 'Table View'
};

const setCategory = (item) => { return item?.breadcrumbs?.[item?.breadcrumbs?.length - 1] };

const isRelatedProducts = (relatedproductFormat) => {
  if (relatedproductFormat) {
    return {
      relatedproductFormat
    };
  }
  return {};
};

export const analyticsFormatDataFavorites = (
    item,
    listItem,
    prices,
    relatedProductFormat
  ) => {
      const itemPrice = prices.find((price) => price.catalogNumber === item.catalogNumber);
      const category = setCategory(item);
      return {
        ...item,
        quantity: listItem.qty,
        price: itemPrice?.unFormattedPrice?.finalPrice?.toFixed(2) || '',
        categoryId: category?.id || '',
        categoryName: category?.name || '',
        relatedproductFormat: relatedProductFormat || ''
      };
  };

  export const analyticsFavorites = (item) => {
    if (document?.dispatchEvent && window?.CustomEvent) {
      document?.dispatchEvent?.(
        new window.CustomEvent('saveToList', {
          detail: {
            products: {
                categoryId: item?.categoryId || '',
                categoryName: item?.categoryName || '',
                catalogNumber: item.catalogNumber || '',
                lineName: decodeHtmlEntities(item.umbrellaBrand || ''),
                title: decodeHtmlEntities(item.productTitleV3 || ''),
                price: item.price,
                quantity: item.quantity?.toString?.() || '1',
                designId: item.catalogNumber || '',
                uom: item.uom || '',
                ...isRelatedProducts(item?.relatedproductFormat)
            }
        }
    }))
    }
  };

export const analyticsAddToCart = (product, item, relatedproductFormat) => {
  const category = setCategory(item);
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('cartAdd', {
        detail: {
          products: {
              categoryId: category?.id || '',
              categoryName: category?.name || '',
              catalogNumber: item.catalogNumber || '',
              lineName: decodeHtmlEntities(item.umbrellaBrand || ''),
              title: decodeHtmlEntities(item.productTitleV3 || ''),
              price: product.price,
              quantity: product.qty?.toString?.() || '1',
              designId: item.catalogNumber || '',
              uom: item.uom || '',
              addMethod: 'pdp-commerce-box',
              ...isRelatedProducts(relatedproductFormat)
            }
          }
          })
    );
  }
};

export const analyticsOpenRSAModal = () => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('stockNotification:open', {})
    );
  }
};

export const analyticsOnCloseRSAModal = () => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('stockNotification:abandon', {})
    );
  }
};

export const analyticsOnNotifyMeRSAModal = () => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('stockNotification:signUp', {})
    );
  }
};

export const analyticsDocumentDownloaded = (documentType, documentName) => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('documentDownloaded', {
        detail: {
          documentType,
          documentName
        }
      })
    );
  }
};

export const analyticsProductSelectorView = (productSelectorView) => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('productContainerClicks', {
        detail: {
          viewType: ANALYTICS_PRODUCTVIEW_TYPES[productSelectorView],
        }
      })
    );
  }
};

export const analyticsProductSpecificationClicks = (item, price, label, specValue) => {
  const category = setCategory(item);
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('productContainerClicks', {
        detail: {
          products: {
            addMethod : 'specifications',
            catalogNumber : item.catalogNumber || '',
            categoryId : category.id,
            categoryName : category.name,
            designId : item.catalogNumber || '',
            lineName : decodeHtmlEntities(item.umbrellaBrand || ''),
            price : price,
            quantity : item.qty?.toString?.() || '1',
            title : decodeHtmlEntities(item.productTitleV3 || ''),
            uom : item.uom || '',
            universalProductInteraction: `${label}: ${specValue}`,
          }          
        }
      })
    );
  }
 };

 export const analyticsProductRowClicks = (catalogNumber, productItems, prices) => {
  const analyticsItem = productItems.find(
    (item) => item.catalogNumber === catalogNumber
  );
  const itemPrice =
    prices?.find((price) => price.catalogNumber === catalogNumber) || {};
  const category = setCategory(analyticsItem);
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('productContainerClicks', {
        detail: {
          products: {
            addMethod : 'row clicks',
            catalogNumber : analyticsItem.catalogNumber || '',
            categoryId : category.id,
            categoryName : category.name,
            designId : analyticsItem.catalogNumber || '',
            lineName : decodeHtmlEntities(analyticsItem.umbrellaBrand || ''),
            price : itemPrice?.unFormattedPrice?.finalPrice?.toFixed(2) || '',
            quantity : analyticsItem.qty?.toString?.() || '1',
            title : decodeHtmlEntities(analyticsItem.productTitleV3 || ''),
            uom : analyticsItem.uom || '',
            universalProductInteraction: 'Product Row Clicked',
          }
        }
      })
    );
  }
 };
 
 export const analyticsFigureClicks = (figureNamePosition) => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('customLinkClicks', {
        detail: {
          CustomLink: {
            linkName: figureNamePosition
          }
        }
      })
    );
  }
};

export const analyticsRelatedProductsFilters = (relatedProductsFilters) => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('filterClicks', {
        detail: {
          CustomLink: {
            globalFilters: relatedProductsFilters
          }
        }
      })
    );
  }
}

export const analyticsRelatedProducts = (relatedproductValue) => {
  if (document?.dispatchEvent && window?.CustomEvent) {
    document?.dispatchEvent?.(
      new window.CustomEvent('relatedProductsClick', {
        detail: {
          products: [
            {
              relatedproductFormat: relatedproductValue
            }
          ]
        }
      })
    );
  }
};

 export const OMNI_ACTIONS = {
  //used for faq
  VIEW_MORE: 'View more',
  VIEW_ANSWER: 'View answer'
};

