import React from 'react';

import "./ImagesPanel.scss";
import NoImage from '../NoImage/NoImage';

const NoImagesPanel = () => {
  return (
    <div className="pdp-images-panel">
      <NoImage />
    </div>
  );
};


export default NoImagesPanel;