import React, { useState } from "react";
import PropTypes from "prop-types";
import { CoreButton } from "@komodo-design-system/komodo-components-react";
import { FormattedMessage } from "react-intl";

import useBundleSku from "../../../../../../hooks/useBundleSku";
import "./BundlesInformations.scss";
import useActiveItem from "../../../../../../hooks/useActiveItem";
import { useSelector } from "react-redux";
import BundleListRows from "../BundleListRows/BundleListRows";

const BundlesInformation = ({
  isFetching,
  isTabletOrMobile,
  setModalOpen,
  productTemplate,
  isCommerceBoxLink = false
}) => {
  const {
    bundleListState,
    handleClickBundleBtn,
    getColumnsConfig,
    bundlesOpen
  } = useBundleSku();

  const activeItem = useActiveItem();
  const price = useSelector((state) => state.prices?.prices?.find((price) => price.catalogNumber === activeItem?.catalogNumber));
  const prices = useSelector((state) => state.prices.prices);

  const [bundlesClicked, setBundlesClicked] = useState(false);

  const handleBundlesClick = () => {
    if (!isFetching) {
      handleClickBundleBtn(activeItem.catalogNumber);
      setBundlesClicked(!bundlesClicked);
    }
    if (isTabletOrMobile) {
      setModalOpen(true);
    }
  };

  const bundlesInformationTable = (
    <div className="pdp-table-wrapper">
      <div className="pdp-table-product-selector">
        <table>
          <thead>
            <tr>
              <th colSpan={7}>
                <FormattedMessage id="bundleTableHeaderIncludes" />
              </th>
              <th colSpan={2}>
                <FormattedMessage id="pricelabelcurrency" values={{ currency: price?.currency }} />
              </th>
              <th colSpan={1}>
                <FormattedMessage id="packageComponentsQuantity" />
              </th>
            </tr>
          </thead>
          <tbody>
            {!!bundlesOpen?.[activeItem?.catalogNumber] && (
              <BundleListRows
                rows={bundleListState[activeItem?.catalogNumber]}
                catalogNumber={activeItem?.catalogNumber}
                columns={getColumnsConfig({
                  template: productTemplate,
                  isSubscriptionProduct: activeItem?.isSubscriptionProduct,
                  isPriceVisible: !!prices || activeItem?.isSubscriptionProduct,
                  isQuantityVisible: !activeItem?.isAddToCartRedirect,
                  skuDifferentiators: activeItem?.skuDifferentiatorsV3
                })}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  return !isTabletOrMobile ? (
    <div className="pdp-bundles-information-container">
      <div className="pdp-bundles-information-button">
        <CoreButton
          type="button"
          variant="outline"
          size="small"
          onClick={handleBundlesClick}
        >
          {bundlesClicked ? (
            <span slot="content">
              <FormattedMessage id="hideBundle" />&nbsp;
              <span className="icon-8-caret-up" />{" "}
            </span>
          ) : (
            <span slot="content">
              <FormattedMessage id="bundlesAvailable" /> &nbsp;
              <span className="icon-8-caret-down" />{" "}
            </span>
          )}
        </CoreButton>
      </div>      
      {bundlesClicked && bundlesInformationTable}
    </div>
  ) : (
    isCommerceBoxLink && <div className="pdp-bundles-information-mobile">
      <a onClick={handleBundlesClick}>
        <FormattedMessage id="commerceBoxViewBundleText" />
      </a>
    </div>
  );
};

BundlesInformation.propTypes = {
  catalogNumber: PropTypes.string,
  isFetching: PropTypes.bool,
  isTabletOrMobile: PropTypes.bool,
  setModalOpen: PropTypes.func,
  productTemplate: PropTypes.string,
  isCommerceBoxLink: PropTypes.bool
};

export default BundlesInformation;
