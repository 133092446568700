import { isChina } from './cookies';

export const computeCnAssetsUrl = (url) => {
    if (url && url.includes('assets.qa') && isChina()) {
      return url.replace('assets.qa', 'qa1-assets').replace('.com', '.cn');
    }
    if (isChina() && url) {
      return url.replace('.com', '.cn');
    }
    return url;
};
