import { getCountryCode, getLanguageCode } from "../utils/cookies";

const combineUrl = (hostname, path) => {
  const actualHostname = process.env.PROXY_TO_QA ?? hostname;
  return `${actualHostname}${path}`;
};

const dictionary = (locale) => combineUrl('', `/api/store/v3/products/dictionary/${locale}`);

const userTypeUrl = () => combineUrl('', "/api/store/user-type");

const addToFavorites = () => combineUrl('', `/api/store/shared-list/addItemToList`);

const getFavoritesLists = (userId) => combineUrl('', `/api/store/shared-list/getLists/${userId}`);

const priceBySku = () => combineUrl('', `/api/store/sku/price/`);

const getAvailability = () => combineUrl('', `/api/store/sku/availability/`);

const subscribeEmail = () => combineUrl('', `/api/store/sku/availability/email-notification`);

const getCitations = (sku, countryCode, optionalParams) =>
  combineUrl('', `/api/store/Assets/Documents/Others/search?skus=${sku}&country=${countryCode}&targetSite=TF&documentTypes=Reference Materials&subTypes=Citations&${optionalParams.join(
    '&'
  )}`);

const getCertificates = (skus, countryCode, optionalParams) =>
  combineUrl('', `/api/store/Assets/Documents/Certificates/v2/search?skus=${skus}&country=${countryCode}&targetSite=TF&partialLotNumber=true&${optionalParams.join('&')}`);

const getSDS = (skus, countryCode) =>
  combineUrl('', `/api/store/Assets/Documents/SDS/search?skus=${skus}&country=${countryCode}&targetSite=TF&&partialLotNumber=true`);

const getOtherDocuments = (skus, countryCode, optionalParams) =>
  combineUrl('', `/api/store/Assets/Documents/Others/search?skus=${skus}&country=${countryCode}&targetSite=TF&partialLotNumber=true&${optionalParams.join('&')}`);

const getFAQs = (sku, countryCode, langCode) =>
  combineUrl('', `/api/search/documents/sku/${countryCode}/${langCode}/${sku}?docTypes=Product%20FAQs`);

const documentsSupportUrl = (productId) =>
  `/search/results?query=${productId}&persona=DocSupport&refinementAction=true`;

const customerServiceUrl = (productId, countryCode, langCode) =>
  `/${countryCode}/${langCode}/home/technical-resources/contact-us.${productId}.html?supportType=SL`;

const techSupportUrl = (productId, countryCode, langCode) =>
  `/${countryCode}/${langCode}/home/technical-resources/contact-us.${productId}.html?supportType=TS`;

const oldCatalogUrl = (productId) => `/order/catalog/product/${productId}`;

const relatedProductsUrl = (catalogNumber) => {
  if (window?.encodeURIComponent) {
    catalogNumber = window?.encodeURIComponent(catalogNumber);
  }

  return combineUrl('', `/api/store/sku/aggregator/consumables/?catalogNumber=${catalogNumber}&pageNumber=1&pageSize=1000&clientIdentifier=pdp-ui&isPriceRequired=true`);
};

const bundleInformation = (catalogNumber) =>{
  if (window?.encodeURIComponent) {
    catalogNumber = window?.encodeURIComponent(catalogNumber);
  }

  return combineUrl('', `/api/store/sku/aggregator/relatedProducts/?catalogNumbers=${catalogNumber}`);
};

const basicSkuAlternatives = (catalogNumbers, countryCode) =>
  combineUrl('', `/api/store/product/catalog/v1/sku-alternative?countryCode=${countryCode}&catalogNumber=${catalogNumbers}`);

const getLULL = (skus, countryCode) =>
  combineUrl('', `/api/store/Assets/Documents/Others/search?skus=${skus}&country=${countryCode}&targetSite=TF&documentTypes=Reference%20Materials&subTypes=LULLS&sortBy=publishDate&sortType=ASC`);

const getMolecularProbesUrl = (productId, familyId, countryCode, langCode) => {
  const apiName = familyId ? 'family' : 'sku';
  const keyParamValue = familyId || productId;

  return combineUrl('', `/api/search/documents/${apiName}/${langCode}/${countryCode}/${keyParamValue}?docTypes=Molecular%20Probes%20Handbook`);
};

const getProtocolsUrl = (productId, familyId, countryCode, langCode) => {
  const apiName = familyId ? 'family' : 'sku';
  const keyParamValue = familyId || productId;

  return combineUrl('', `/api/search/documents/${apiName}/${langCode}/${countryCode}/${keyParamValue}?docTypes=Experiment%20Protocol`);
};
const getMediaFormulationsUrl = (productId, familyId, countryCode, langCode) => {
  const apiName = familyId ? 'family' : 'sku';
  const keyParamValue = familyId || productId;

  return combineUrl('', `/api/search/documents/${apiName}/${langCode}/${countryCode}/${keyParamValue}?docTypes=Media%20Formulation`);
}
const getFigures = (sku) => combineUrl('', `/api/store/Assets/Figures/search?sku=${sku}&targetSite=TF`);

const getVideoPod = (contentPath) => combineUrl('', contentPath);

const getContentPods = (contentPath) => combineUrl('', contentPath);

const getStructureAndSpectra = (productId, familyId) => {
  return familyId
    ? combineUrl('', `/api/search/documents/family/${getLanguageCode()}/${getCountryCode()}/${familyId}?docTypes=Spectra%20Data`)
    : combineUrl('', `/api/search/documents/sku/${getLanguageCode()}/${getCountryCode()}/${productId}?docTypes=Spectra%20Data`);
}

export default {
  dictionary,
  userTypeUrl,
  addToFavorites,
  getFavoritesLists,
  priceBySku,
  getAvailability,
  subscribeEmail,
  getCitations,
  getCertificates,
  getSDS,
  getFAQs,
  getOtherDocuments,
  documentsSupportUrl,
  customerServiceUrl,
  techSupportUrl,
  oldCatalogUrl,
  relatedProductsUrl,
  bundleInformation,
  basicSkuAlternatives,
  getLULL,
  getMolecularProbesUrl,
  getProtocolsUrl,
  getMediaFormulationsUrl,
  getFigures,
  getVideoPod,
  getStructureAndSpectra,
  getContentPods
};
