import { UiActions } from "../actionTypes";

const showAlert = (label, message, variant, state, timer, description) => {
  return {
    type: UiActions.ShowAlert,
    label,
    message,
    variant,
    state,
    timer,
    description,
  };
};

export default showAlert;