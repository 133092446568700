import { getCountryCode, getLanguageCode } from "./cookies";
import { isClientRuntime } from "./isClientRuntime";
import { BASE_NAME, OLD_BASE_NAME } from '../constants/constants';

export const userProfileUrl = () =>
  `/order/catalog/${getLanguageCode()}/${getCountryCode().toUpperCase()}/direct/lt?cmd=SCMSSupplyCenter`;

export const ellipsedText = (str, limit) => {
  let resultStr = str;
  const parts = str.split(/[ \s\n。、（）]/);

  // Decrease the word limit in half for JP language
  if (/[。、（）]/.test(str)) {
    limit = limit / 2;
  }
  
  if (parts.length > limit) {
    const strLength = parts.slice(0, limit).reduce((acc, part) => acc + part.length + 1, 0);
    resultStr = resultStr.substring(0, strLength-1);
  }

  return resultStr;
};

export const getDomainSufix = () => {
  let sufix = '_LOCAL';
  if (isClientRuntime()) {
    const hostname = window?.location?.hostname || '';
    if (hostname.includes('www.qa') || hostname.includes('www.qa4') || hostname.includes('www.stage')) {
      sufix = '_QA';
    } else if (hostname.includes('www.thermofisher')) {
      sufix = '_PROD';
    }
  }
  return sufix;
}
// green url
export const greenURL = () =>
  `/${getCountryCode()}/${getLanguageCode()}/home/about-us/product-stewardship/greener-alternatives.html`;

export const requestCertificateLink = (catalogNumber) =>
  `/${getCountryCode()}/${getLanguageCode()}/home/technical-resources/contact-us.${catalogNumber}.html?supportType=TS`;

// faq url
export const faqUrl = (productId) =>
  `/search/results?query=${productId}&persona=DocSupport&type=Product+FAQs`;

export const upperBreadCrumbs = [
  {
    id: 'home',
    intlId: 'home',
    url: '/'
  },
  {
    id: 'shopAllProducts',
    intlId: 'shopAllProducts',
    url: `/${getCountryCode()}/${getLanguageCode()}/home/order.html`
  }
];

export const getUrlReplacementString = () => {
  return window?.location?.pathname?.includes(BASE_NAME)
    ? BASE_NAME
    : OLD_BASE_NAME;
};

export const getUrlRouteParams = (replacementString) => {
  return window?.location?.pathname?.replace(replacementString, '')?.split('/');
};

export const getNormalizeBilling = (it) => {
  const normalize = {
    Annual: 'year'
  };
  return normalize[it.billingPeriod] || it.billingPeriod;
};

export const getScrollbarWidth = () => {
  // Create the measurement node
  var scrollDiv = document.createElement("div");

  scrollDiv.style.width = "100px";
  scrollDiv.style.height = "100px";
  scrollDiv.style.overflow = "scroll";
  scrollDiv.style.position = "absolute";
  scrollDiv.style.top = "-9999px";
  scrollDiv.className = "scrollbar-measure";
  scrollDiv.style.scrollbarWidth = "thin";

  document.body.appendChild(scrollDiv);

  // Get the scrollbar width
  var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Delete the DIV 
  document.body.removeChild(scrollDiv);

  return scrollbarWidth
}