import React from 'react';
import PropTypes from 'prop-types';

import { CoreButton } from '@komodo-design-system/komodo-components-react';
import { LEAD_TARGET_TYPES } from '../../constants/constants';
import { getCountryCode, getLanguageCode } from '../../utils/cookies';

const handleOnClickRFQ = (item) => {
    if (item?.leadTarget === LEAD_TARGET_TYPES.SIEBEL) {
        window.open(rfqSeibelForms(item?.catalogNumber), '_self');
      } else {
        window.open(
          rfqELMSForms(item?.catalogNumber),
          '_self'
        );
      }
}
const rfqSeibelForms = (catalogNumber) => {
    return `/${getCountryCode()}/${getLanguageCode()}/home/global/forms/product-request-for-quote.html?catalogNumbers=${catalogNumber}`;
}

const rfqELMSForms = (catalogNumber) => {
   return `/${getCountryCode()}/${getLanguageCode()}/home/global/forms/product-request-for-quote-2.html?catalogNumbers=${catalogNumber}`
}
const PriceRFQ = ({item, priceAccess}) => {
  const priceAccessMessage = priceAccess?.pricingAccessMessage;
  return (
    <CoreButton  
        type="button"
        variant="primary"
        fullspace
        size="medium"
        onClick={() => handleOnClickRFQ(item)}
    >
      <span slot="content" dangerouslySetInnerHTML={{ __html: priceAccessMessage}}></span>
    </CoreButton>
  );
};

PriceRFQ.propTypes = {
    item: PropTypes.object,
    priceAccess: PropTypes.object
};

export default PriceRFQ;
