import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import imageUrlBuilder from '../../../../../utils/imageUrlBuilder';
import DevicesTypes from '../../../../../constants/deviceTypes';
import useDeviceType from '../../../../../hooks/useDeviceType';
import { FormattedMessage } from 'react-intl';

import './MobileSlider.scss';
import { isClientRuntime } from '../../../../../utils/isClientRuntime';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: 0,
  centerMode: true,
  arrows: false,
  lazyLoad: true
};

const MobileSlider = ({ isFamily, images, onImageClick, onActiveImageChange }) => {
  const isTablet = useDeviceType(DevicesTypes.Tablet);
  const imageSize = isTablet ? "-250.jpg" : "-150.jpg";

  useEffect(() => {
    if (isClientRuntime()) { 
      // Preload second and last images
      const secondImage = new window.Image();
      secondImage.src = imageUrlBuilder(images[1].path) + imageSize;

      const lastImage = new window.Image();
      lastImage.src = imageUrlBuilder(images[images.length - 1].path) + imageSize;
    }
  }, [images]);


  const handleImageClick = (idx) => {
    onImageClick?.(idx);
  };

  const handleImageChange = (imageIdx) => {
    onActiveImageChange?.(imageIdx);
  }

  return (
    <div className="pdp-mobile-image-slider-container">
      <Slider className='pdp-image-mobile-slider' {...settings} afterChange={handleImageChange}>
        {
          images.map((image, idx) => {
            const imageUrl = imageUrlBuilder(image.path) + imageSize

            return (
              <div key={idx}>
                <img src={imageUrl} alt="Product" onClick={() => handleImageClick(idx)} />
              </div>
            )
          })
        }
      </Slider>
      { isFamily && (
        <div className="pdp-mobile-image-slider-vary">
          <FormattedMessage id="actualProductMayVary" />
        </div>
      )}
    </div>
  );
};

MobileSlider.propTypes = {
  isFamily: PropTypes.bool,
  images: PropTypes.array.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onActiveImageChange: PropTypes.func.isRequired
};

export default MobileSlider;