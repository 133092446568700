import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import useActiveItem from "./useActiveItem";

import { PRODUCT_TYPES } from "../constants/productTypes";
import { PRODUCT_ERROR_TYPES } from "../constants/productErrorTypes";
import FetchErrorBanner from "../components/Main/ErrorBanners/FetchErrorBanner/FetchErrorBanner";
import ProductDiscontinuedBanner from "../components/Main/ErrorBanners/ProductDiscontinuedBanner/ProductDiscontinuedBanner";
import ProductErrorBanner from "../components/Main/ErrorBanners/ProductErrorBanner/ProductErrorBanner";
import UnknownProductTypeBanner from "../components/Main/ErrorBanners/UnknownProductTypeBanner/UnknownProductTypeBanner";
import { SearchDiscountinuedBanner, SearchDisentitlementBanner } from '@tfecom-thermofisher/tf-search-discontinued-banner/dist/index.esm';


const useErrorBanner = () => {
  const { fetched, error, productId, product } = useSelector((state) => state.product, shallowEqual);
  const staticBanner = useSelector((state) => state.ui.staticBanner);
  const activeItem = useActiveItem();
  const isSearchBannerEnabled = activeItem?.isNewBannerEnabled;

  let bannerComponent = null;

  if (error) {
    bannerComponent = <FetchErrorBanner />;
  }
  if (fetched && staticBanner === null) {
    if (activeItem == null) {
      bannerComponent = <ProductErrorBanner productId={productId} />;
    }
    else if (activeItem.error === PRODUCT_ERROR_TYPES.DISCONTINUED) {
      if(isSearchBannerEnabled) {
        bannerComponent = <SearchDiscountinuedBanner
          pageName="PDP"
          productTitle={activeItem.productTitleV3}
          searchKeyword={productId}
        />
      }
      else {
        bannerComponent = <ProductDiscontinuedBanner currentItem={activeItem} productId={productId} template={product.template} />;
      }
    }
    else if (activeItem.error != null) {
      if(activeItem.error === PRODUCT_ERROR_TYPES.ENTITLEMENT && isSearchBannerEnabled) {
        bannerComponent = <SearchDisentitlementBanner
          pageName="PDP"
          productTitle={activeItem.productTitleV3}
          searchKeyword={productId}
        />
      }
      else {
        bannerComponent = <ProductErrorBanner currentItem={activeItem} />;
      }
    }
    else if (!Object.values(PRODUCT_TYPES).includes(product.template)) {
      bannerComponent = <UnknownProductTypeBanner />;
    }
  }

  if (bannerComponent == null) {
    return null;
  }

  return bannerComponent;
};

export default useErrorBanner;