import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  elementsSearchUrl,
  structureSearchUrl,
} from "../../../../constants/constants";
import IconPeriodicTable from "../../../../styles/images/icon-periodic-table.svg";
import IconStructureSearch from "../../../../styles/images/structure_search_icon_bold.svg";
import "./ElementStructureSearch.scss";

const ElementsStructureSearch = () => {
  const intl = useIntl();
  return (
    <div className="pdp-elements-structure-search">
      <a href={elementsSearchUrl()}>
        <img
          src={IconPeriodicTable}
          alt={intl.formatMessage({ id: "chemicalsElementSearch" })}
          className="pdp-elements-structure-search__icon"
        />
        <FormattedMessage id="chemicalsElementSearch" />
      </a>
      <a href={structureSearchUrl()}>
        <img
          src={IconStructureSearch}
          alt={intl.formatMessage({ id: "chemicalsStructureSearch" })}
          className="pdp-elements-structure-search__icon"
        />
        <FormattedMessage id="chemicalsStructureSearch" />
      </a>
    </div>
  );
};

export default ElementsStructureSearch;
