import React, { useEffect, Suspense, lazy } from 'react';

import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ProductDetailTabs from '../../../constants/productDetailTabs';
import { useDispatch, useSelector } from 'react-redux';
import useActiveItem from '../../../hooks/useActiveItem';
import getCitations from '../../../actions/citations/getCitations';
import getFAQs from '../../../actions/faq/getFAQs';
import useClientApp from '../../../hooks/useClientApp';
import LoadOffers from '../../LoadOffers/LoadOffers';
import { PDP_TABS_IDS, CHEMICAL_TEMPLATES } from '../../../constants/constants';
import ContentPods from '../NavContainers/ProductOverview/ContentPods/ContentPods';
import SkuLogoPods from '../NavContainers/ProductOverview/SkuLogoPods/SkuLogoPods';

import getCertificates from '../../../actions/documents/getCertificates';
import getSDS from '../../../actions/documents/getSDS';

const Figures = lazy(() => import('../NavContainers/Figures/Figures'));
const Video = lazy(() => import('../NavContainers/Video/Video'));
const OtherRecommendations = lazy(() => import('../NavContainers/OtherRecommendations/OtherRecommendations'));
const Recommendations = lazy(() => import('../NavContainers/Recommendations/Recommendations'));

import "./MobileNav.scss";
import StructureAndSpectra from '../NavContainers/StructureAndSpectra/StructureAndSpectra';
import MediaFormulations from '../NavContainers/ProductOverview/MediaFormulations/MediaFormulations';
import PackageComponents from '../NavContainers/ProductOverview/PackageComponents/PackageComponents';
import ContentsAndStorage from '../NavContainers/ProductOverview/ContentsAndStorage/ContentsAndStorage';
import NavItemLink from './NavItemLink';

const calcTabindex = (tabs, id) => {
  let index = 0;
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i]?.available) {
      if (tabs[i].id === id) {
        break;
      }
      index++;
    }
  }
  return index;
};

let tabList = [];
const getTabsList = () => {
  return tabList;
};

const MobileNav = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeItem = useActiveItem();
  const { hydrated } = useClientApp();

  const product = useSelector((state) => state.product?.product);
  const items = useSelector(state => state.product?.product?.items);
  let contentPods = product.contentPods || [];
  const skuLogos = activeItem.skuLogos || [];

  const { count: citationCount, fetched: citationsFetched, visible: citationsVisible } = useSelector((state) => state.citations);
  const { count: faqCount } = useSelector((state) => state.faq);
  const { fetched: certificatesFetched } = useSelector((state) => state.certificates);
  const { fetched: sdsFetched } = useSelector((state) => state.sds);

  const getCatalogNumbers = () => {
    let catalogNumbers = '';
    catalogNumbers = items.map(item => item.catalogNumber);
    return catalogNumbers.join(',');
  };
  const showSafetyAndHandling = !!activeItem?.hazardAndSafety?.length;

  useEffect(() => {
    if (!certificatesFetched) {
      dispatch(getCertificates(getCatalogNumbers(), 0,''));
    }
    if(!sdsFetched) {
      dispatch(getSDS(getCatalogNumbers()));
    }
  }, [certificatesFetched, sdsFetched]);

  const handleMobileLinkClick = (id) => {
    document
      .getElementsByTagName('footer')?.[0]
      ?.style?.setProperty('display', 'none');
    setTimeout(() => {
      document
        .getElementsByClassName('child-route')?.[0]
        ?.style?.setProperty('position', 'static');
      document
        .getElementsByTagName('footer')?.[0]
        ?.style?.setProperty('display', 'block');
    }, 500);
    const defIndex = calcTabindex(tabs, id);
    history.push(`/${activeItem.catalogNumber}/tabs?defaultTab=${defIndex}`);
  };

  useEffect(() => {
    dispatch(getCitations(activeItem?.catalogNumber, 1, ""));
    dispatch(getFAQs(activeItem?.catalogNumber));
  }, [activeItem?.catalogNumber]);

  const tabs = [{
    id: PDP_TABS_IDS.PRODUCT_OVERVIEW,
    available: true,
    title: intl.formatMessage({ id: 'productOverview' }),
    content: (
      <NavItemLink tabId={ProductDetailTabs.ProductOverview} label={intl.formatMessage({ id: "productOverview" })} onMobileLinkClick={handleMobileLinkClick} />
    )
  },
  {
    id: PDP_TABS_IDS.SPECIFICATIONS,
    available: activeItem?.specifications && !CHEMICAL_TEMPLATES.includes(activeItem.productTemplate),
    title: intl.formatMessage({ id: 'specificationsText' }),
    content: (
      <NavItemLink tabId={ProductDetailTabs.Specifications} label={intl.formatMessage({ id: "specifications" })} onMobileLinkClick={handleMobileLinkClick} />
    )
  },
  {
    id: PDP_TABS_IDS.DOCUMENTS,
    available: true,
    title: intl.formatMessage({ id: 'documentsTitle' }),
    content: (
      <NavItemLink tabId={ProductDetailTabs.Documents} label={intl.formatMessage({ id: "documents" })} onMobileLinkClick={handleMobileLinkClick} />
    )
  },
  {
    id: PDP_TABS_IDS.FAQ,
    available: faqCount > 0,
    title: intl.formatMessage({ id: 'faq' }),
    content: (
      <NavItemLink tabId={ProductDetailTabs.Faq} label={intl.formatMessage({ id: "faq" })} onMobileLinkClick={handleMobileLinkClick} />
    )
  },
  {
    id: PDP_TABS_IDS.CITATIONS,
    available: (citationCount > 0 || citationsVisible === true) && citationsFetched,
    title: intl.formatMessage({ id: 'citationsReference' }),
    content: (
      <NavItemLink tabId={ProductDetailTabs.CitationsReference} label={intl.formatMessage({ id: "citationsReference" })} onMobileLinkClick={handleMobileLinkClick} />
    )
  },
  {
    id: PDP_TABS_IDS.SAFETY_AND_HANDLING,
    available: showSafetyAndHandling,
    title: intl.formatMessage({ id: 'commonSafetyAndHandling' }),
    content: (
      <>
        {showSafetyAndHandling && (
          <NavItemLink tabId={ProductDetailTabs.SafetyAndHandling} label={intl.formatMessage({ id: "commonSafetyAndHandling" })} onMobileLinkClick={handleMobileLinkClick} />
        )}
      </>
    )
  }];
  tabList = tabs;

  return (
    <div className="pdp-mobile-nav" >
      <div
        className="col-xs-12 pdp-prod-overview pdp-prod-overview__pods"
        style={{ paddingLeft: 10, paddingRight: 10 }}
      >
        <LoadOffers skus={[activeItem.catalogNumber]} />
      </div>
      {tabs.map((tab) => {
        if (tab.available) {
          return (<div className='tabs-header-item' key={tab.id}>{tab.content}</div>);
        }
        return null;
      })}
      {hydrated && (
        <Suspense>
          <Figures />
        </Suspense>
      )}
      <ContentsAndStorage
        contentsAndStorage={activeItem?.contentsAndStorage}
      />

      {skuLogos && <SkuLogoPods skuLogos={skuLogos} />}

      {contentPods && <ContentPods contentPods={contentPods} />}

      <PackageComponents
        packageComponents={activeItem?.packageSolution?.componentSkus}
      />
      <MediaFormulations />
      {hydrated && (
        <Suspense>
          <Video />
        </Suspense>
      )}
      <StructureAndSpectra />
      {hydrated && (
        <Suspense>
          <Recommendations />
        </Suspense>
      )}
      {hydrated && (
        <Suspense>
          <OtherRecommendations />
        </Suspense>
      )}
    </div>
  );
};

MobileNav.propTypes = {

};

export { calcTabindex, getTabsList };
export default MobileNav;
