import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import "./ProductOverview.scss";
import ProductDescription from './ProductDescription/ProductDescription';
import Specifications from './Specifications/Specifications';
import ContentsAndStorage from './ContentsAndStorage/ContentsAndStorage';
import { Log } from '../../../../utils/log';
import { CHEMICAL_TEMPLATES } from '../../../../constants/constants';
import ProductDetailTabs from '../../../../constants/productDetailTabs';
import LoadOffers from '../../../LoadOffers/LoadOffers';
import PackageComponents from './PackageComponents/PackageComponents';
import MediaFormulations from './MediaFormulations/MediaFormulations';
import useActiveItem from '../../../../hooks/useActiveItem';
import ContentPods from './ContentPods/ContentPods';
import SkuLogoPods from './SkuLogoPods/SkuLogoPods';

const ProductOverview = () => {
  const activeItem = useActiveItem();
  const [ offersLoaded, setOffersLoaded ] = useState(false);
  const intl = useIntl();

  const doNotShowSpecsHere = !!CHEMICAL_TEMPLATES.includes(activeItem.productTemplate);
  const contentsAndStorage = !!activeItem?.contentsAndStorage;

  const packageComponents = activeItem?.packageSolution?.componentSkus;
  const showPackageComponents = packageComponents?.length > 0;

  const product = useSelector((state) => state.product?.product);
  let contentPods = product.contentPods || [];
  const skuLogos = activeItem.skuLogos || [];

  Log("ProductOverview", { catalogNumber: activeItem?.catalogNumber, doNotShowSpecsHere });

  return (
    <div className="pdp-nav-container pdp-product-overview row" id={ProductDetailTabs.ProductOverview}>
      <ProductDescription fullWidth={doNotShowSpecsHere && !contentsAndStorage && !offersLoaded} />

      <div className='col-lg-5 col-md-12'>
        {doNotShowSpecsHere === false && (
          <Specifications specifications={activeItem?.specifications}
            title={intl.formatMessage({ id: 'specifications' })} />
        )}
        {showPackageComponents && (
          <PackageComponents packageComponents={packageComponents} />
        )}
        {contentsAndStorage && (
          <div className="contents-storage">
            <ContentsAndStorage contentsAndStorage={activeItem?.contentsAndStorage} />
          </div>
        )}

        {skuLogos && <SkuLogoPods skuLogos={skuLogos} />}

        {contentPods && <ContentPods contentPods={contentPods}/>}
        
        <MediaFormulations />

        <LoadOffers skus={[activeItem.catalogNumber]} setOffersLoaded={setOffersLoaded}/>
      </div>
    </div>
  );
};

export default ProductOverview;