// Code to be used for tracking analytics on the site
import { decodeHtmlEntities } from "../utils/decodeHtmlEntities";
import { PRODUCT_TYPES } from "../constants/productTypes";
import { isClientRuntime } from "../utils/isClientRuntime";

const ANALYTICS_PAGE_TYPES = {
  [PRODUCT_TYPES.PDP]: 'product detail',
  [PRODUCT_TYPES.PFP]: 'product family',
  [PRODUCT_TYPES.CHM]: 'product selector'
};
const PAGE_EXPERIENCE_TYPE = "Universal PDP";

const analyticsService = (template, items, prices) => {
  if (!isClientRuntime()) {
    return null;
  } else {
    const digitalData = window.digitalData || {};
    if (
      digitalData.page &&
      typeof window.digitalData.setPageType === "function"
    ) {
      digitalData.setPageType(ANALYTICS_PAGE_TYPES[template]);
      digitalData.page.experienceType = PAGE_EXPERIENCE_TYPE;
    }
    if (!digitalData.user) {
      digitalData.user = {};
    }

    const user = window._lt?.user || { displayIso: "us", displayLang: "en" };
    const userData = {};
    if (user.shipToNumber) {
      userData.accountId = user.shipToNumber;
    }
    userData.customerId = "";
    // gigyaId should already be set  : '{unique login ID}',
    userData.ipAddress = "";
    userData.jobFunction = "";
    // loginStatus should already be set : '{logged in|anonymous}',
    userData.punchoutSiteName = "";

    digitalData.user = {
      ...userData,
      ...digitalData.user,
    };

    if (!digitalData.products) {
      const digitalDataProducts =
        items?.map((item) => {
          const itemPrice =
            prices?.find(
              (price) => price.catalogNumber === item.catalogNumber
            ) || {};
          const category = item.breadcrumbs?.[item.breadcrumbs?.length - 1];
          return {
            categoryId: category?.id || "",
            categoryName: category?.name || "",
            catalogNumber: item.catalogNumber || "",
            lineName: decodeHtmlEntities(item.umbrellaBrand || ""),
            title: decodeHtmlEntities(item.productTitleV3 || ""),
            price: itemPrice?.unFormattedPrice?.listPrice?.toFixed(2) || "",
            priceRuleType: itemPrice?.priceRuleType
              ? itemPrice.priceRuleType
              : itemPrice?.priceAccess?.status,
            quantity: "1",
            designId: item.catalogNumber || "",
            uom: item.uom || "",
          };
        }) || [];
      if (digitalData.addDigitDataObject) {
        digitalData.addDigitDataObject("products", digitalDataProducts);
      } else {
        digitalData.products = digitalDataProducts;
      }
    }

    if (!window.digitalData) {
      window.digitalData = digitalData;
    }
    //neccessary for adobe tracking - faq tracking of answers will fail without
    if (!window.pdpSettings && items.length) {
      window.pdpSettings = { sku: items[0].catalogNumber };
    }
  }
};

export default analyticsService;

// digitalAnalytics : products : [{
//   // list each item shown on the page
//   catalogNumber : '{unique catalog number/ID}',
//   categoryId : '{product category ID}',
//   categoryName : '{product category name}',
//   designId : '{design/assay/oligo ID}',
//   lineName : '{product line name}',
//   price : '{product price}',
//   title : '{product name/title}',
//   uom : '{product unit of measure}'
// }]
