import React, { useEffect, useState } from 'react';
import classnames from 'clsx';
import './TabContent.scss';
import PropTypes from 'prop-types';

/* eslint-disable unused-imports/no-unused-vars */
const TabBody = ({ active, children, ...divProps }) => {
  return (
    <div
      {...divProps}
      role="tabpanel"
      className={classnames({
        'c-item__tabpanel': true,
        'c-item__tabpanel--active': active
      })}
    >
      {children}
    </div>
  );
};

TabBody.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node
};

export const Tab = () => null;

export const TabContent = ({
  activeIndex,
  children,
  onTabChange,
  tabs,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState(activeIndex);

  useEffect(() => {
    setActiveTab(activeIndex);
  }, [activeIndex]);

  const setCurrentActiveTab = (index) => {
    setActiveTab(index);
    onTabChange?.(index);
  };

  return (
    <div
      {...rest}
      className={classnames({
        'c-tabs': true
      })}
    >
      <div className="c-item">
        <div className="c-item-header">
          <ul
            role="tablist"
            className={classnames({
              'c-item-tablist': true
            })}
          >
            {tabs.map(({ title }, i) => (
              <li
                key={i}
                role="tab"
                className={classnames({
                  'c-item-tablist__tab': true,
                  'c-item-tablist__tab--active': i === activeTab
                })}
                onClick={() => setCurrentActiveTab(i)}
              >
                {title}
              </li>
            ))}
          </ul>
        </div>
        {tabs.map((item, i) => {
          return (
            <TabBody
              {...item.props}
              key={i}
              active={i === activeTab}
              children={item.content}
            >
              {item.content}
            </TabBody>
          );
        })}
      </div>
    </div>
  );
};

TabContent.propTypes = {
  activeIndex: PropTypes.number,
  children: PropTypes.node,
  onTabChange: PropTypes.func,
  tabs: PropTypes.array
};
