import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { CoreCard } from '@komodo-design-system/komodo-components-react';
import './ContentsAndStorage.scss';

const ContentsAndStorage = ({ contentsAndStorage }) => {
  const intl = useIntl();

  if (contentsAndStorage) {
    return (
      <div className="pdp-contents-and-storage">
        <CoreCard variant='floating' className='pdp-contents-and-storage-card' nopadding>
          <div className="pdp-contents-and-storage__wrapper" slot="content">
            <div className='pdp-contents-and-storage__title'>
              <span className='pdp-contents-and-storage__text'>{intl.formatMessage({ id: 'contentsAndStorageTitle' })}</span>
            </div>
            <div
              className="pdp-contents-and-storage__desc"
              dangerouslySetInnerHTML={{ __html: contentsAndStorage }}
            />
          </div>
        </CoreCard>
      </div>
    );
  }
  return null;
};

ContentsAndStorage.propTypes = {
  contentsAndStorage: PropTypes.string
};

export default ContentsAndStorage;
