import React from 'react';
import PropTypes from 'prop-types';

const ListPrice = ({ itemPrice }) => {

  return (
    <div className="pdp-price-orderable">      
      <span className="pdp-price-orderable__lp-value">
        {itemPrice?.formattedPrice?.listPrice}
      </span>
    </div>
  );
};

ListPrice.propTypes = {
  itemPrice: PropTypes.object
};

export default ListPrice;