import { AvailabilityActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";

const initialState = {
    availability: null,
    isFetching: false,
    fetched: false,
    error: null
};

const availability = (state = initialState, action) => {
  const response = action.response;
  switch (action.type) {
    case `${AvailabilityActions.GetAvailability}_${SUCCESS}`:
        return {
            ...state,
            availability: response?.items,
            isFetching: false,
            fetched: true
        };
    case `${AvailabilityActions.GetAvailability}_${PENDING}`:
      return {
          ...state,
        isFetching: true,
        fetched: false,
        error: null
      };
    case `${AvailabilityActions.GetAvailability}_${ERROR}`:
      return {
          ...state,
          availability: null,
        isFetching: false,
        fetched: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default availability;
