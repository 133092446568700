export const ellipsedText = (str) => {
  let resultStr = str;
  let wordLength = str.split(' ').length;
  if (wordLength > 8) {
    if (
      str
        .split(' ')
        .slice(0, 5)
        .join(' ')
        .match(/(<a([^>]+)>)/gi)
    ) {
      resultStr = str.split(' ').slice(0, 5).join(' ');
    } else {
      resultStr = str.split(' ').slice(0, 8).join(' ');
    }
  }
  return resultStr;
};

export const ellipsedAbstractText = (str, limit) => {
  let resultStr = str;
  let wordLength = str.split(' ').length;
  if (wordLength > limit) {
    resultStr = str.split(' ').slice(0, limit).join(' ');
  }
  return resultStr;
};

export const isEllipsedDescription = (str, isCitationsAbstract) => {
  let wordLength = str.split(' ').length;
  let isEllipsed = false;
  let lengthCheck = isCitationsAbstract ? 25 : 10;
  if (wordLength > lengthCheck) {
    isEllipsed = true;
  }
  return isEllipsed;
};
