import apiRequest from "../../http/apiRequest";
import endpoints from "../../http/endpoints";
import httpOptions from "../../server/http/httpOptions";
import { AvailabilityActions } from "../actionTypes";

export const getAvailability = (products) => {
  const url = endpoints.getAvailability();
  const options = httpOptions.post({
    items: products
  },{
    clientIdentifier: 'pdp-ui'
    }
  );

  return apiRequest(AvailabilityActions.GetAvailability, url, options);
}