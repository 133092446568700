import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import { PRICE_RULE_TYPES, CHINA_COUNTRY_CODE, ONLINE_OFFER_NO_PROMOCODE_COUNTRIES } from '../../../constants/constants';
import { getCountryCode } from '../../../utils/cookies';

const LegacyOnlinePrice = ({ itemPrice }) => {

  if (itemPrice?.priceRuleType === PRICE_RULE_TYPES.ONLINE) {

    let tooltipText = '';
    if (getCountryCode().toLowerCase() === CHINA_COUNTRY_CODE) {
      tooltipText = 'onlineOfferTooltipForCn';
    } else if (ONLINE_OFFER_NO_PROMOCODE_COUNTRIES.includes(getCountryCode())) {
      tooltipText = 'onlineOfferTooltipForHk';
    } else {
      tooltipText = 'legacyOnlineOfferToolTip';
    }

    return (
      <div className='pdp-legacy-online-price'>
        <span className='pdp-legacy-online-price-label'>
          <FormattedMessage id={"onlineOffer"} />
        </span>
        <span className='pdp-legacy-online-price-value'>{itemPrice?.formattedPrice?.onlinePrice}</span>
        <span className='pdp-legacy-online-price-tooltip'>
          <CoreTooltip
            alignment='center'
            placement='bottom'
          >
            <span slot="tooltip-content">
              <FormattedMessage id={tooltipText} />
            </span>
            <span className='icon-16-info-mono'></span>
          </CoreTooltip>
        </span>
        {itemPrice?.onlinePriceEndDate && (
          <div className='pdp-legacy-online-price-end-date'>
            <FormattedMessage id={"onlinePriceEndDate"} values={{ onlinePriceEndDate: itemPrice?.onlinePriceEndDate }} />
          </div>
        )}
      </div>
    );
  }
  return null;
};

LegacyOnlinePrice.propTypes = {
  itemPrice: PropTypes.object
};

export default LegacyOnlinePrice;
