import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import AlsoKnownAs from '../PdpTitleAndProductSelector/AlsoKnownAs/AlsoKnownAs';
import Favorites from '../../Favorites/Favorites';
import Price from '../../Price/Price';
import CustomForms from './CustomForms/CustomForms';
import Quantity from './Quantity/Quantity';
import Availability from './Availability/Availability';
import Divider from '../../Divider/Divider';
import AddToCart from './AddToCart/AddToCart';
import RequestStockAlertLink from './RequestStockAlert/RequestStockAlertLink';
import OrderNow from './OrderNow';
import OrderingAttirbutes from './OrderingAttributes';
import Loyalty from './Loyalty/Loyalty';
import ControlCodeMessages from './ControlCodeMessages/ControlCodeMessages';
import { PRICE_STATUS, PRODUCT_SIZE_ID, STATUS_CODES, CHEMICAL_TEMPLATES } from '../../../constants/constants';
import setQuantity from '../../../actions/quantity/setQuantity';
import DevicesTypes from '../../../constants/deviceTypes';
import useDeviceType from '../../../hooks/useDeviceType';
import { isInViewport, isBelowAddToCart } from '../../../utils/viewport';
import AddToCartMobile from './AddToCartMobile/AddToCartMobile';
import setAddToCartSticky from '../../../actions/ui/setAddToCartSticky';
import ConditionalSkuAlternatives from './ConditionalSkuAlternatives';
import AddToCartStickyDesktop from './AddToCartStickyDesktop/AddToCartStickyDesktop';
import LearnMore from '@tfecom-recurring-orders/learn-more-banner-v2';
import "./CommerceBox.scss";
import { isClientRuntime } from '../../../utils/isClientRuntime';
import useActiveItem from '../../../hooks/useActiveItem';
import clsx from 'clsx';
import BundlesInformation from '../PdpTitleAndProductSelector/PdpProductSelector/Bundles/BundlesInformation/BundlesInformation';
import BundleModal from '../PdpTitleAndProductSelector/PdpProductSelector/Bundles/BundleModal/BundleModal';
import useBundleSku from '../../../hooks/useBundleSku';

const CommerceBox = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const product = useSelector((state) => state.product.product);
  const activeItem = useActiveItem();
  const prices = useSelector((state) => state.prices.prices);
  const { bundleListState, getColumnsConfig } = useBundleSku();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  const { isAddToCartRedirect, addToCartRedirectUrl } = activeItem;  
  const [showStickyCart, setShowStickyCart] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const isDesktop = useDeviceType(DevicesTypes.Desktop);
  const quantity = useSelector((state) => state.quantity.quantity);
  const user = useSelector((state) => state.user.userData);

  const price = useSelector((state) => state.prices?.prices?.find((price) => price.catalogNumber === activeItem?.catalogNumber));
  const selectedSubscription = useSelector((state) => state.prices?.selectedSubscription);

  const priceAccessStatus = price?.priceAccess?.status;
  const isPVNOorOrderableorNoPrice = priceAccessStatus === PRICE_STATUS.ORDERABLE || priceAccessStatus === PRICE_STATUS.PVNO || priceAccessStatus === PRICE_STATUS.NO_PRICE;
  const isOrderableorNoPrice = priceAccessStatus === PRICE_STATUS.ORDERABLE || priceAccessStatus === PRICE_STATUS.NO_PRICE;
  const isSubscriptionProduct = activeItem?.isSubscriptionProduct;

  let itemNotOrderable = false;
  if (Number(activeItem.statusCode) === STATUS_CODES.NOT_ORDERABLE ||
    Number(activeItem.statusCode) === STATUS_CODES.WEB_ORDERABLE_NOT_VIEWABLE
  ) {
    itemNotOrderable = true;
  }

  const isMatches = product?.items?.some((item, i, arr) => {
    return arr?.some((itemArr, index) => {
      return item?.catalogNumber === itemArr?.formattedCatalogNumber && i != index
    });
  });

  const activeSkuDifferentiators = activeItem?.skuDifferentiatorsV3?.filter(skuDifferentiator => {
    const differentiatorValues = useSelector(state => state.productSelector.differentiators[skuDifferentiator?.id], shallowEqual) ?? [];
    const isUnitSizeAndValueOne = skuDifferentiator.id === PRODUCT_SIZE_ID && (differentiatorValues.length <= 1 || !isMatches);
    return !isUnitSizeAndValueOne ? true : false;
  });

  const showGoDirectCatalogNumber = activeItem?.goDirectCatalogNumber && activeItem?.goDirectCatalogNumber !== activeItem?.catalogNumber;

  useEffect(() => {
    dispatch(setQuantity(1));
  }, [activeItem])

  useEffect(() => {
    const handleScroll = () => {
      const commerceCartEl = document.querySelector(
        '.pdp_commerce_add_to_cart'
      );
      const header = document.querySelector('header');
      const headerRect = header?.getBoundingClientRect();
      const b2borSCMSheader = document.getElementById('messagingBar');
      const b2borSCMSheaderRect = b2borSCMSheader?.getBoundingClientRect();
      if (
        !isInViewport(commerceCartEl, headerRect, b2borSCMSheaderRect) &&
        isBelowAddToCart(commerceCartEl, headerRect, b2borSCMSheaderRect)
      ) {
        setShowStickyCart(true);
        dispatch(setAddToCartSticky(true));
      } else {
        setShowStickyCart(false);
        dispatch(setAddToCartSticky(false));
      }
    };

    if (!isClientRuntime()) {
      return;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);

  const setCartTop = () => {
    const headerCheckInterval = setInterval(() => {
      const header = document.querySelector('header');
      const b2borSCMSheader = document.getElementById('messagingBar');
      if (header != null) {
        clearInterval(headerCheckInterval);
        const rect = header.getBoundingClientRect();
        const b2borSCMSheaderRect = b2borSCMSheader?.getBoundingClientRect();
        setHeaderHeight(rect.height + (b2borSCMSheaderRect?.height ?? 0));
      }
    }, 50);
  };

  useEffect(() => {
    if (!isClientRuntime()) {
      return;
    }

    setCartTop();
    window.addEventListener('resize', setCartTop);

    return () => {
      window.removeEventListener('resize', setCartTop);
    };
  }, []);

  if (!activeItem) {
    return null;
  }

  const proxyUrlIfNeeded = process?.env?.NODE_ENV === "development" ? `http://localhost:3001` : '';

  return (
    <>
      <div className="pdp-commerce-box col-lg-3 col-md-12">
        <div className="commerce-box-inner">
          {CHEMICAL_TEMPLATES.includes(activeItem.productTemplate) &&
            <div className='pdp-card pdp-chemdex-wrapper'>
              <a href="/order/dex/?SID=pdp#!/" target="_blank">
                <img src="static/images/chemdex-logo.svg" alt="ChemDex" className="pdp-chemdex-icon" />
                <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "tryChemDex" }) }} />
              </a>
            </div>}

          <div className='pdp-card'>
            <div className="pdp-commerce-box-content" slot="content">
              {!isAddToCartRedirect && <ControlCodeMessages />}
              <div className='pdp-commerce-box--first-row'>
                <span className='pdp-commerce-box--first-row--catalog-number-wrapper'>
                  <span className="pdp-commerce-box--catalog-number"><FormattedMessage id="catalogNumber" /> </span>
                  <span className="pdp-commerce-box--catalog-number-value">{activeItem.catalogNumber}</span>
                  {activeItem.isLoyaltyEligible && <Loyalty />}
                </span>
                {!isAddToCartRedirect && <Favorites item={activeItem} />}
              </div>
              <AlsoKnownAs catalogNumber={activeItem.catalogNumber} formattedCatalogNumber={activeItem.formattedCatalogNumber} />

              {showGoDirectCatalogNumber && (
                <div className="pdp-commerce-box--go-direct">
                  <FormattedMessage
                    id="goDirectCatalogNumber"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                      goDirectCatalogNumber: activeItem.goDirectCatalogNumber
                    }}
                  />
                </div>
              )}

              <Divider />
              <Price />
              {!isDesktop && activeItem.bundledSkuParent &&
                <>
                  <BundlesInformation
                    isFetching={
                      !!bundleListState?.[activeItem.catalogNumber]?.isFetching
                    }
                    template={product?.template}
                    isTabletOrMobile={!isDesktop}
                    isCommerceBoxLink={true}
                    setModalOpen={setModalOpen}
                  />
                  <BundleModal 
                    catalogNumber={activeItem?.catalogNumber}
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    bundleListState={bundleListState}
                    isMobileView={!isDesktop}
                    productTitle={activeItem?.productTitle}
                    columns={getColumnsConfig({
                      template:product.template,
                      isSubscriptionProduct,
                      isPriceVisible: !!prices || activeItem?.isSubscriptionProduct,
                      isQuantityVisible: !activeItem?.isAddToCartRedirect,
                      skuDifferentiators: activeItem.skuDifferentiatorsV3,
                      isMobileView: !isDesktop
                    })} />
                </>
              }
              <Divider />
              {isPVNOorOrderableorNoPrice && !isSubscriptionProduct && !itemNotOrderable && !isAddToCartRedirect && (
                <>
                  <div className='pdp-commerce-box__wrapper'>
                    <Quantity priceAccessStatus={priceAccessStatus} quantity={quantity} />
                    <Availability item={activeItem} priceAccessStatus={priceAccessStatus} />
                  </div>
                  <RequestStockAlertLink />
                  <ConditionalSkuAlternatives template={product?.template} />
                </>
              )}
              {
                isOrderableorNoPrice && !isAddToCartRedirect && !itemNotOrderable && (
                  <AddToCart item={activeItem} price={price} quantity={quantity} selectedSubscription={selectedSubscription} size="medium" customClassName={"pdp_commerce_add_to_cart"}></AddToCart>
                )
              }
              {isAddToCartRedirect && <OrderNow redirectUrl={addToCartRedirectUrl} />}
              <div className='pdp-ordering-attributes'>
                <OrderingAttirbutes skuDifferentiators={activeSkuDifferentiators} specifications={activeItem.specifications} />
              </div>
              {activeItem.isLoyaltyEligible && <Loyalty showText />}

            </div>
          </div>

          <div className='pdp-recurring-order'>
            <LearnMore
              className="pdp-recurring-order__banner"
              sku={activeItem?.catalogNumber}
              isAnonymous={user?.isAnonymous}
              isEligible={product?.presentationAttributes?.recurringOrderEnabled}
              isOrderable={priceAccessStatus === PRICE_STATUS.ORDERABLE}
              proxyURL={proxyUrlIfNeeded}
              asButton
              fullspace
            />
          </div>

          <CustomForms customForms={activeItem?.customForms} />
        </div>
      </div>

      {isOrderableorNoPrice && !isAddToCartRedirect && (
        <>
          <div className={clsx("pdp-add-to-cart-box-mobile", (!isDesktop && showStickyCart) && "pdp-add-to-cart-box-mobile--show")} style={{ top: `${headerHeight}px` }}>
            <AddToCartMobile item={activeItem} price={price} quantity={quantity} priceAccessStatus={priceAccessStatus} selectedSubscription={selectedSubscription} />
          </div>
          <div className={clsx("pdp-add-to-cart-desktop", (isDesktop && showStickyCart) && 'pdp-add-to-cart-desktop--show')} style={{ top: `${headerHeight}px` }}>
            <AddToCartStickyDesktop item={activeItem} price={price} quantity={quantity} priceAccessStatus={priceAccessStatus} selectedSubscription={selectedSubscription} isSticky />
          </div>
        </>
      )}
    </>

  );
};

export default CommerceBox;
