const getBranchPlantConditions = (availability, branchDetails) => {
    let showRequestStockAlert = true;
    let availabilityBranchPlant = availability?.branchPlant || '';
    let detailsBranchPlant = branchDetails?.values?.find((branch) => branch?.itemBranch === availabilityBranchPlant);
    if (typeof detailsBranchPlant === 'object' && detailsBranchPlant !== null) {
        //If lineType= "S" and  stockingType = "H" then its a Tiered Service SKU.
        if (
            detailsBranchPlant.lineType?.toLowerCase() === 's' &&
            detailsBranchPlant.stockingType?.toLowerCase() === 'h'
        ) {
          showRequestStockAlert = false;
        }
        //If lineType = "ds" then its a Dual Channel SKU and if  lineType_= "dx" then its a Drop Ship SKU.
        if (
            detailsBranchPlant.lineType?.toLowerCase() === 'ds' ||
            detailsBranchPlant.lineType?.toLowerCase() === 'dx'
        ) {
          showRequestStockAlert = false;
        }
        // if  stockingType = "K" and plannerNumber = 320587 or 267098 then its a Combo Kit SKU.
        if (
            detailsBranchPlant.stockingType?.toLowerCase() === 'k' &&
          (detailsBranchPlant.plannerNumber === 320587 ||
            detailsBranchPlant.plannerNumber === 267098)
        ) {
          showRequestStockAlert = false;
        }
        //if  lineType = "PS" then its a Package SKU.
        if (detailsBranchPlant.lineType?.toLowerCase() === 'ps') {
          showRequestStockAlert = false;
        }
        // if stockingType = "D" and  Line type = "AB" then it is Pending Discontinuation
        if (
            detailsBranchPlant.stockingType?.toLowerCase() === 'd' ||
            detailsBranchPlant.lineType?.toLowerCase() === 'ab'
        ) {
          showRequestStockAlert = false;
        }
      } else {
        showRequestStockAlert = false;
      }
    return showRequestStockAlert;
}

export default getBranchPlantConditions;