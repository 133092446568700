import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import getSkuAlternatives from "../actions/skuAlternatives/getSkuAlternatives";

const useSkuAlternatives = (skipAndReturnEmpty = false) => {
  const dispatch = useDispatch();

  const items = useSelector(state => state.product?.product?.items);
  const { skuAlternatives, isFetching, fetched, error } = useSelector((state) => state.skuAlternatives);

  const getCatalogNumbers = () => {
    let catalogNumbers = '';
    catalogNumbers = items.map(item => item.catalogNumber);
    return catalogNumbers.join(',');
  };

  useEffect(() => {
    if (skipAndReturnEmpty !== true) {
      dispatch(getSkuAlternatives(getCatalogNumbers()));
    }
  }, []);

  if (skipAndReturnEmpty === true) { 
    return { ready: true, skuAlternatives: [] };
  }
  else if (isFetching || !fetched || error) {
    return { ready: false, skuAlternatives: [] };
  }
  else { 
    return { ready: true, skuAlternatives };
  }
};

export default useSkuAlternatives;