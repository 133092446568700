import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { CoreDropdown } from '@komodo-design-system/komodo-components-react';

import { getNormalizeBilling } from '../../../../../utils/utils';
import { PriceActions } from '../../../../../actions/actionTypes';

import './Subscription.scss';

const Subscription = ({ prices }) => {
    const subscriptionPrices = prices?.prices?.[0]?.subscriptionPrices;
    const [selectedSubscription, setSelectedSubscription] = useState(subscriptionPrices?.find(subscriptionPrice => subscriptionPrice.hasDefaultPlan) ?? subscriptionPrices?.[0]);
    const intl = useIntl();
    const dispatch = useDispatch();

    const getLabel = (it) => {
        if (it.currencyCode && it.price) {
            return `${it.nameRatePlanName} ${it.currencyCode} ${Number(
                it.price
            ).toFixed(2)} / Users / ${getNormalizeBilling(it)}`;
        }
        return it.nameRatePlanName;
    };

    const subscriptionPricesOptions =
        subscriptionPrices?.map((it) => {
            return {
                ...it,
                value: it.prodRatePlanId,
                label: getLabel(it)
            };
        }) || [];

    useEffect(() => {
        if(selectedSubscription?.prodRatePlanId) {
            dispatch({ type: PriceActions.SelectedSubscription, payload: selectedSubscription });    
        }
    }, [selectedSubscription?.prodRatePlanId]);

    const handleSelectSubscription = (event) => {
        const option = event.detail.option;
        setSelectedSubscription(option);
    };

    if(subscriptionPrices?.length > 0) {
        return (
            <div className='pdp-subscription'>
                <CoreDropdown
                    style={{ width: '100%' }}
                    label={intl.formatMessage({ id: 'subscriptionLabel' })}
                    customkey="value"
                    options={subscriptionPricesOptions}
                    selected={[selectedSubscription?.prodRatePlanId]}
                    functionality="single-select"
                    size="large"
                    onSelectionChanged={handleSelectSubscription}
                />
            </div>
        );
    }
    return <></>;

};

Subscription.propTypes = {
  prices: PropTypes.object
};

export default Subscription;
