import { CoreModal } from "@komodo-design-system/komodo-components-react";
import { createPortal } from "react-dom";
import PropTypes from 'prop-types';
import React from "react";

const StructureAndSpectraModal = ({ img, onClose, type }) => {
    const width = window.innerWidth * 0.8;

    return createPortal(
        <CoreModal
            isopen
            width={width}
            bgclose={true}
            nooverflow={true}
            closeicon={true}
            onModalClosed={() => onClose()}
        >
            <img alt={type === 'spectra' ? 'Fluorescence spectra' : 'Chemical structure'} src={img} style={{ width: '100%' }} />
        </CoreModal>, document.getElementById('modal'))
}

StructureAndSpectraModal.propTypes = {
    img: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['structure', 'spectra']).isRequired
};

export default StructureAndSpectraModal;