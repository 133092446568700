import { DocumentActions } from "../../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../../actions/status";
import { EXCLUDE_SUB_TYPE_DOCUMENT_LISTS } from "../../constants/constants";

const initialState = {
  documents: [],
  documentsCount: 0,
  loading: false,
  fetched: false
};
const excludeDocuments = (assetTypes) => {
  return assetTypes?.map((assetType) => {
    return {
      ...assetType,
      documentTypes: assetType?.documentTypes?.map((documentType) => {
        return {
          ...documentType,
          assets: documentType?.assets?.filter((asset) => {
            return !EXCLUDE_SUB_TYPE_DOCUMENT_LISTS.includes(asset?.subType);
          })
        };
      })
    };
  });
};

const otherDocuments = (state = initialState, action) => {
  switch (action.type) {
    case `${DocumentActions.GetOtherDocuments}_${SUCCESS}`:
      const documents = excludeDocuments(action?.response?.assetTypes);
      let currentStateDocuments = [...documents];

      if (state.documents.length > 0) {
        currentStateDocuments = [...state.documents];
        const documentResp = documents[0];
        const docResDocType = documentResp.documentTypes[0];
        const newSetOfAssets = docResDocType.assets;
        const existingDoc = currentStateDocuments.find(d => d.value === documentResp.value);
        const existingDocType = existingDoc.documentTypes.find(dt => dt.value === docResDocType.value);
        existingDocType.assets = newSetOfAssets;
      }
      return {
        ...state,
        documents: currentStateDocuments,
        loading: false,
        fetched: true
      };
    case `${DocumentActions.GetOtherDocuments}_${PENDING}`:
      return {
        ...state,
        loading: true,
        fetched: false
      };
    case `${DocumentActions.GetOtherDocuments}_${ERROR}`:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default otherDocuments;
