import React from "react";
import PropTypes from "prop-types";
import Price from "../../../Price/Price";
import "./AddToCartMobile.scss";
import Quantity from "../Quantity/Quantity";
import AddToCart from "../AddToCart/AddToCart";

const AddToCartMobile = ({ item, price, quantity, priceAccessStatus, selectedSubscription }) => {
  return (
    <div className="pdp-cart-box">
      <div className="pdp-cart-box__price">
        <Price stickyPrice/>
      </div>
      <div className="pdp-cart-box__action">
        {!item?.isSubscriptionProduct && (
          <div className="pdp-cart-box__quantity-value">
            <Quantity priceAccessStatus={priceAccessStatus} quantity={quantity} />
        </div>
        )}
        <div className="pdp-cart-box__add-to-cart-btn">
          <AddToCart item={item} price={price} quantity={quantity} selectedSubscription={selectedSubscription}></AddToCart>
        </div>
      </div>
    </div>
  );
};

AddToCartMobile.propTypes = {
  price: PropTypes.object,
  item: PropTypes.object,
  quantity: PropTypes.number,
  priceAccessStatus: PropTypes.string,
  selectedSubscription: PropTypes.object,
};

export default AddToCartMobile;
