import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useDeviceType from '../../../hooks/useDeviceType';
import DevicesTypes from '../../../constants/deviceTypes';
import './LegacyPrice.scss';

const LegacyListPrice = ({ itemPrice }) => {
  const isMobile = useDeviceType(DevicesTypes.Mobile);

  let pricelabel = '';
  if (isMobile) {
    pricelabel = 'priceLabelCurrencyColon';
  }
  else {
    pricelabel = 'priceLabel';
  }

  return (
    <div className="pdp-legacy-price">
      <span className='pdp-legacy-price-label'>
        <FormattedMessage id={pricelabel} values={{ currency: itemPrice?.currency }} />
      </span>
      <span className="pdp-legacy-price-lp-value">
        {itemPrice?.formattedPrice?.listPrice}
      </span>
    </div>
  );
};

LegacyListPrice.propTypes = {
  itemPrice: PropTypes.object
};

export default LegacyListPrice;
