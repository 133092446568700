import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { CoreChip, CoreModal } from '@komodo-design-system/komodo-components-react';
import "./MobileSkuDifferentiatorModal.scss";

const MobileSkuDifferentiatorModal = ({ name, differentiatorValues, onSelectSpecValue, onClose }) => {
  const handleSelectSpecValue = (value, selected) => {
    onSelectSpecValue(value, selected);
  };

  const height = window.innerHeight * 0.8;

  return createPortal(
    <CoreModal
      isopen
      bgclose={true}
      header={name}
      nooverflow={true}
      closeicon={true}
      onModalClosed={onClose}
      className='mobile-sku-differentiator-modal'
      maxheight={height}
    >
      <div className="pdp-sku-differentiator-mobile-modal">
        {differentiatorValues.map((specValue, index) => {
          return (
            <div className="pdp-chip-container" key={index}>
              <CoreChip className="diff-chip" usehtml selected={specValue.selected} onChipClicked={() => handleSelectSpecValue(specValue.value, specValue.selected)}>
                <div dangerouslySetInnerHTML={{ __html: specValue.value }}></div>
              </CoreChip>
            </div>
          );
        })}
      </div>

    </CoreModal>,
    document.getElementById("modal")
  );
};

MobileSkuDifferentiatorModal.propTypes = {
  name: PropTypes.string.isRequired,
  differentiatorValues: PropTypes.array.isRequired,
  onSelectSpecValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MobileSkuDifferentiatorModal;