import { RelatedProductActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";

export const initialRelatedProductsState = {
  fetchAvailability: false,
  isLegalChangeAppliedForWOP: false,
  isSpecialOfferEnabled: false,
  products: null,
  prices: null,
  availabilities: null,
  isFetching: false,
  fetched: false,
  error: null,
  availabilityError: null,
  filters: null,
  filtersError: null,
  selectedFilters: []
};

const relatedProducts = (state = initialRelatedProductsState, action) => {
  const { type, response, error } = action;

  switch (type) {
    case `${RelatedProductActions.GetRelatedProducts}_${PENDING}`:
      return {
        ...state,
        ...initialRelatedProductsState,
        isFetching: true
      };
    case `${RelatedProductActions.GetRelatedProducts}_${SUCCESS}`:
      return {
        ...state,
        ...processRelatedProductsResponse(response),
        isFetching: false,
        fetched: true,
        error: null
      };
    case `${RelatedProductActions.GetRelatedProducts}_${ERROR}`:
      return {
        ...state,
        products: null,
        prices: null,
        availabilities: null,
        isFetching: false,
        fetched: true,
        error
      };
    case `${RelatedProductActions.GetRelatedProductsAvailability}_${SUCCESS}`:
      return {
        ...state,
        availabilities: response.items,
        availabilityError: null
      };
    case `${RelatedProductActions.GetRelatedProductsAvailability}_${ERROR}`:
      return {
        ...state,
        availabilities: null,
        availabilityError: error
      };
    case RelatedProductActions.SetRelatedProductFilters:
      return {
        ...state,
        selectedFilters: action.filters
      };
    default: {
      return state;
    }
  }
};

export const processRelatedProductsResponse = (response) => {
  const processedData = {
    fetchAvailability: response.fetchAvailability,
    isLegalChangeAppliedForWOP: response.isLegalChangeAppliedForWOP,
    isSpecialOfferEnabled: response.isSpecialOfferEnabled,
    products: [],
    prices: [],
    filters: []
  };

  response.consumables?.reduce?.((acc, relatedProd) => {
    acc.prices.push({
      catalogNumber: relatedProd.catalogNumber,
      ...relatedProd.pricing
    });
    if (
      relatedProd.productDetails.images?.sort &&
      relatedProd.productDetails.images?.length
    ) {
      relatedProd.productDetails.images.sort((a, b) => a.sequence - b.sequence);
    }
    acc.products.push({
      catalogNumber: relatedProd.catalogNumber,
      filterGroup: relatedProd.filterGroup,
      ...relatedProd.productDetails
    });
    if (
      relatedProd?.filterGroup?.filterGroupId &&
      relatedProd?.filterGroup?.filterGroupValue
    ) {
      if (
        !acc.filters.find(
          (filterGroup) =>
            filterGroup.filterGroupId === relatedProd.filterGroup.filterGroupId
        )
      ) {
        acc.filters.push(relatedProd.filterGroup);
      }
    }
    return acc;
  }, processedData);

  if (processedData.filters.length) {
    processedData.filters.sort((filterA, filterB) => {
      if (filterA.filterGroupValue > filterB.filterGroupValue) {
        return 1;
      }
      if (filterA.filterGroupValue < filterB.filterGroupValue) {
        return -1;
      }
      return 0;
    });
  }

  return processedData;
};

export default relatedProducts;