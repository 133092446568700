import { decode } from 'html-entities';

const htmlDecode = (input) => {
  if (input.indexOf('&lt;') !== -1) {
    if (typeof window === 'object' && window?.DOMParser) {
      const doc = new window.DOMParser().parseFromString(input, 'text/html');
      return doc.documentElement.textContent;
    }

    return decode(input);
  }

  return input;
};

export default htmlDecode;
