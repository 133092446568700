import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { CoreModal } from "@komodo-design-system/komodo-components-react";
import { createPortal } from "react-dom";
import BundleListCards from "../BundleListCards/BundleListCards";
import "./BundleModal.scss";

const BundleModal = ({
  catalogNumber,
  isOpen,
  onClose,
  bundleListState,
  productTitle,
  isMobileView,
  columns,
}) => {
  if (!isOpen) {
    return null;
  }

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (isMobileView) {
    const modalWidth = window.innerWidth;
    const modalHeight = 80 * window.innerHeight / 100;

    setDimensions({ width: modalWidth, height: modalHeight });
    }
  }, [isMobileView]);

  return createPortal(
    <CoreModal
      className="pdp-mobile-bundle-modal"
      customcontent
      isopen
      width={dimensions.width}
      height={dimensions.height}
      nooverflow="true"
      bgclose="true"
      closeicon="true"
      onModalClosed={() => onClose()}
    >
      <div className="pdp-mobile-bundle-modal-content" style={{ height: `${dimensions.height}px` }} slot="custom-content">
        <div className="pdp-mobile-bundle-modal-content-title" dangerouslySetInnerHTML={{__html: productTitle}} ></div>
        <BundleListCards
          rows={bundleListState[catalogNumber]}
          catalogNumber={catalogNumber}
          columns={columns}
          isMobileView={isMobileView}
        />
      </div>
    </CoreModal>,
    document.getElementById("modal")
  );
};

BundleModal.propTypes = {
  catalogNumber: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bundleListState: PropTypes.object,
  productTitle: PropTypes.string,
  isMobileView: PropTypes.bool,
  columns: PropTypes.array,
};

export default BundleModal;
