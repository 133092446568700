import React from 'react';
import PropTypes from 'prop-types';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import { PRICE_RULE_TYPES, OLD_BASE_NAME } from '../../../constants/constants';

import LegacyListPrice from './LegacyListPrice';
import LegacyAccountPrice from './LegacyAccountPrice';
import LegacyOnlinePrice from './LegacyOnlinePrice';
import { FormattedMessage } from 'react-intl';
import requestLogin from '../../../utils/requestLogin';

import useLoggedInUserCheck from '../../../hooks/useLoggedInUserCheck';

const LegacyPriceOrderable = ({ itemPrice, stickyPrice }) => {

  const isUserLoggedIn = useLoggedInUserCheck(!!'checkPersistentUser');
  const priceRuleType = itemPrice?.priceRuleType;
  if (stickyPrice) {
    if (priceRuleType === PRICE_RULE_TYPES.LIST) {
      return (
        <LegacyListPrice itemPrice={itemPrice} />
      )
    }
    else if (priceRuleType === PRICE_RULE_TYPES.ACCOUNT) {
      return (
        <LegacyAccountPrice itemPrice={itemPrice} isUserLoggedIn={isUserLoggedIn} stickyPrice={stickyPrice} />
      )
    }
    else if (priceRuleType === PRICE_RULE_TYPES.ONLINE) {
      return (
        <LegacyOnlinePrice itemPrice={itemPrice} />
      )
    }
  }
  else {
    return (
      <div>
        <LegacyListPrice itemPrice={itemPrice} />
        <LegacyAccountPrice itemPrice={itemPrice} isUserLoggedIn={isUserLoggedIn} />
        <LegacyOnlinePrice itemPrice={itemPrice} />
        <div>
          {!isUserLoggedIn && (
            <>
              <span className='pdp-legacy-your-price-label'>
                <FormattedMessage id={"yourPriceLabel"} />
              </span>
              <span className='pdp-legacy-your-price-sign-in' onClick={() => requestLogin(`${OLD_BASE_NAME}/${itemPrice.catalogNumber}`)}>
                <span className='pdp-legacy-your-price-sign-in-label'>
                  <FormattedMessage id={"availabilityTooltipSignIn"} />
                </span>
                <span className='pdp-legacy-your-price-tooltip'>
                  <CoreTooltip
                    alignment='center'
                    placement='bottom'
                  >
                    <span slot="tooltip-content">
                      <FormattedMessage id={"legacyTooltipSignIn"} />
                    </span>
                    <span className='icon-16-info-mono'></span>
                  </CoreTooltip>
                </span>
              </span>
            </>
          )}
        </div>

      </div>
    );
  }
};

LegacyPriceOrderable.propTypes = {
  itemPrice: PropTypes.object,
  stickyPrice: PropTypes.bool
};

export default LegacyPriceOrderable;
