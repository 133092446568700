import { DeviceTypeActions } from "../actions/actionTypes";
import DevicesTypes from "../constants/deviceTypes";
import { isUserAgentMobile } from "../utils/userAgent"; 

export const deviceTypeInitialState = {
  deviceType: isUserAgentMobile() ? DevicesTypes.Mobile : DevicesTypes.Desktop
};

const deviceType = (state = deviceTypeInitialState, action) => {
  const { type, deviceType } = action;

  switch (type) {
    case DeviceTypeActions.Set:
      return {
        ...state,
        deviceType: deviceType
      };
    default:
      return state;
  }
};

export default deviceType;