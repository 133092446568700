import { computeCnAssetsUrl } from '../utils/computeCnAssetsUrl';
import { getDomainSufix } from '../utils/utils';
import { getLanguageCode } from '../utils/cookies';

export const MAX_ADD_TO_CART_QTY = 9999;

export const DIM_COMPONENTS_MEASURED = 'dimComponentsMeasured';

export const MAX_SPECIFICATIONS_ROW = 8;

export const MAX_SPECIFICATIONS_CHEMICALS_ROW = 5;


// Citations
export const MAX_CITATIONS_EDAM_ITEMS = 5;
export const CITATIONS_MIN_SEARCH_CHARS = 3;

//Certificates
export const MAX_CERTIFICATES_ITEMS = 5;
export const CERTIFICATES_MIN_SEARCH_CHARS = 3;
export const MAX_DOCUMENTS_ITEMS = 10;

//FAQ
export const MAX_FAQ_ITEMS = 5;
export const MAX_FAQ_SCHEMA_ITEMS = 5;

// Related Products
export const MAX_RELATED_PRODUCTS_ITEMS = 10;

export const EXCLUDE_SUB_TYPE_DOCUMENT_LISTS = ['Citations', 'LULLS'];

export const CHEMICAL_TEMPLATES = ['CHEMICAL', 'GENERICCHEMICALS'];

export const FAMILY = 'Family';


export const specificationsUrl = (rootSku, catalogNumber) => {
  const sufix = getDomainSufix();
  return `${computeCnAssetsUrl(process.env[`DOCS_ASSETS_URL${sufix}`])}/chem-specs-pdf/retrievePdf?rootSku=${rootSku}&sku=${catalogNumber}`;
};

export const sharedListURL = (listId) =>
  `/store/shared-list/details?id=${listId}#/`;

export const elementsSearchUrl = () =>
  `/content/lifetech/global/${getLanguageCode()}/home/chemicals/interactive-periodic-table-elements.html`;

export const structureSearchUrl = () => '/search/chemical/structure';

export const PRODUCT_TYPES = {
  PDP: 'PDP',
  PFP: 'PFP',
  CHM: 'CHM'
};

export const PDP_TABS_IDS = {
  PRODUCT_OVERVIEW: 'productOverview',
  FIGURES: 'figures',
  VIDEOS: 'videos',
  DOCUMENTS: 'documents',
  FAQ: 'faq',
  CITATIONS: 'citationsReference',
  SPECIFICATIONS: 'specifications',
  SAFETY_AND_HANDLING: 'safetyAndHandling',
  SDS: 'sds'
};

export const SCROLL_THRESHOLD = 27;

export const DEFAULT_VIEW = {
  TABLE: "TABLE",
  BUTTON: "BUTTON"
};

export const BANNER_LABELS = {
  SUCCESS: 'Success:',
  ERROR: 'Error:',
  INFORMATIONAL: 'Informational:',
}

export const PRICE_STATUS = {
  RFQ: 'RFQ',
  ORDERABLE: 'Orderable',
  CONTACT_US: 'ContactUs',
  PVNO: 'PVNO', // this is price + contact us
  NO_PRICE: 'No Price',
  DSVP: 'DSVP',
  LEARN_WHERE_TO_BUY: 'LearnWhereToBuy'
};

export const AVAILABILITY_CODES = {
  IN_STOCK: "INSTOCK",
  OUT_OF_STOCK: "OUTOFSTOCK",
  outOfStock: 'Out of Stock'
};

export const AVAILABILITY_DATE = {
  PENDING: 'PENDING'
};

export const LATAM_COUNTRIES = ['br', 'cl', 'mx'];

export const LEAD_TARGET_TYPES = {
  ELMS: 'ELMS',
  SIEBEL: 'SIEBEL'
};

export const PRICE_RULE_TYPES = {
  LIST: 'LIST',
  ONLINE: 'ONLINE',
  ACCOUNT: 'ACCOUNT',
  SPECIALOFFER: 'SPECIALOFFER'
};

export const CHINA_COUNTRY_CODE = 'cn';
export const ONLINE_OFFER_NO_PROMOCODE_COUNTRIES = ['hk'];
export const JAPAN_COUNTRY_CODE = 'jp';

export const STATUS_CODES = {
  NOT_ORDERABLE: 20,
  WEB_ORDERABLE_NOT_VIEWABLE: 50
};

export const SUPPLY_CENTER_PODNAME = 'SupplyCenter';
export const SUPPORT_CENTER_PODNAME = 'SupportCenter';

export const PICTOGRAMS_DISPLAY_NAME = "Pictograms";
export const PICTOGRAMS_PREFIX = "Approved pictograms/";
export const LABEL_ELEMENTS_CLASS = "Label Elements";
export const CATEGORY_RELATIONSHIPS_CLASS = 'Class & Category relationships';
export const CATEGORY_RELATIONSHIPS_CLASS_TITLE =
  'Classification of the substance or mixture';
export const CATEGORY_RELATIONSHIPS_CLASS_SUB_TITLE =
  'CLP classification - Regulation(EC) No 1272/2008';
export const SIGNAL_NAME = 'Signal Word';
export const PRECAUTIONARY_STATEMENTS = 'Precautionary Statements';
export const SafetyAndHandlingImages = {
  flammable: "ghs02_flame",
  hazard: "ghs08_health_hazard",
  warning: "ghs07_exclamation_mark",
  biohazardous: "biohazardous_2",
  explodingBomb: "ghs01_exploding_bomb",
  flameOvercircle: "ghs03_flame_over_circle",
  gasCylinder: "ghs04_gas_cylinder",
  corrosion: "ghs05_corrosion",
  skullAndCrossbones: "ghs06_skull_and_crossbones",
  environment: "ghs09_environment"
};

export const BASE_NAME = '/store/v3/products';
export const STATIC_FOLDER_NAME = 'static';

export const CUSTOMIZE_PRODUCT = "customizeThisUrl";

export const STOCK_AVAILABLE_CODES = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE'
};

export const CHINESE_LANG_CODE = 'zh';

export const CHINA_DELIVERY_CHANNEL = 'SC';

export const SDS_BLOCK_ID = 'sds';
export const CERTIFICATES_BLOCK_ID = 'certificates';
export const VECTORS_BLOCK_ID = 'vectors';
export const SAFETY_HANDLING = 'safetyAndHandling'

export const SEARCH_DOCUMENTS_DIRECT_WEB_STRING = 'directwebviewer';

export const OLD_BASE_NAME = '/order/catalog/product';

export const BANNER_TYPES = {
  INFO: 'alert-info',
  ERROR: 'alert-error',
  WARNING: 'alert-warning',
  SUCCESS: 'alert-success',
  ALERT: 'alert'
};

export const HIDE_TITLE_BANNERS = ['alert', 'alert-warning'];

export const PRODUCT_ERROR_TYPES = {
  DISCONTINUED: 'DISCONTINUED',
  ENTITLEMENT: 'ENTITLEMENT',
  BLOCKED: 'BLOCKED',
  SKU_NOT_FOUND: 'SKU_NOT_FOUND'
};

export const PRODUCT_SIZE_ID = 'Product Size';
