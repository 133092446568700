import React, {
  useState,
  useEffect,
  Suspense,
  lazy
} from 'react';
import useClientApp from '../../../hooks/useClientApp';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { PDP_TABS_IDS, SCROLL_THRESHOLD, CHEMICAL_TEMPLATES } from '../../../constants/constants';
import { TabContent } from './TabContent/TabContent';
import getHeaderHeight from '../../../utils/getHeaderHeight';
import useActiveItem from '../../../hooks/useActiveItem';
import ProductDescription from './ProductOverview/ProductDescription/ProductDescription';
import Specifications from './ProductOverview/Specifications/Specifications';

const Documents = lazy(() => import('./Documents/Documents'));
const FAQ = lazy(() => import('./FAQ/FAQ'));
const SafetyAndHandling = lazy(() => import('./SafetyAndHandling/SafetyAndHandling'));
const Citations = lazy(() => import('./Citations/Citations'));

import './MobileNavContainers.scss';

const ProductInfo = () => {
  const selectedProduct = useActiveItem();

  const productTitle = selectedProduct?.productTitle;
  const productId = selectedProduct?.catalogNumber;
  return (
    <div className="pdp-product-info">
      <div className="pdp-product-info-title">
        <div
          className="pdp-product-info-title__text"
          dangerouslySetInnerHTML={{
            __html: productTitle
          }}
        />
        <span className="pdp-product-info__catalog">
          <FormattedMessage id="catalogNumber" />
        </span>
        <span>{productId}</span>
      </div>
    </div>
  );
};

const MobileNavContainers = () => {
  const { hydrated } = useClientApp();
  const activeItem = useActiveItem();
  const history = useHistory();

  const searchParams = hydrated === true ? new window.URLSearchParams(
    window?.location?.search?.substring(1)
  ) : null;

  const intl = useIntl();

  const selectedProduct = useActiveItem();
  const [activeTab, onChangeActiveTab] = useState(0);;
  const { count: citationCount, fetched: citationsFetched, visible: citationsVisible } = useSelector((state) => state.citations);
  const { count: faqCount } = useSelector((state) => state.faq);
  const showSafetyAndHandling = !!activeItem?.hazardAndSafety?.length;

  const [isEntering, setIsEntering] = useState(true);

  useEffect(() => {
    setIsEntering(false);
  }, []);

  let tabs = [];
  const defaultIndex = searchParams.get('defaultTab');
  const gotoSection = searchParams.get('searchDoc');

  useEffect(() => {
    onChangeActiveTab(Number(defaultIndex));
  }, [defaultIndex]);

  useEffect(() => {
    setTimeout(() => {
      if (gotoSection === PDP_TABS_IDS.SDS) {
        scrollToTabSection();
      }
    }, 500);
  }, [gotoSection, intl]);

  useEffect(() => {
    let element = document?.querySelector('.pdp-chm-mob-tab-view');
    if (element) {
      element.style.top = `${getHeaderHeight()}px`;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let tabHeaderElement = document?.querySelector('.c-item-header');
      if (tabHeaderElement) {
        let productInfoHeight = document
          ?.querySelector('.pdp-chm-mob-tab-view')
          ?.getBoundingClientRect()?.height;
        tabHeaderElement.style.top = `${getHeaderHeight() + productInfoHeight
          }px`;
        let tabContentElementList =
          document?.querySelectorAll('.c-item__tabpanel');
        for (let i = 0; i < tabContentElementList.length; i++) {
          tabContentElementList[i].style.marginTop = `${tabHeaderElement?.getBoundingClientRect()?.height +
            productInfoHeight +
            12 // added 12 to increase the margin top px
            }px`;
        }
      }
    }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      let el = document.getElementsByClassName(
        'c-item-tablist__tab c-item-tablist__tab--active'
      )[0];
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
    window.scrollTo(0, 0);
  }, []);

  const onTabChange = (index) => {
    onChangeActiveTab(index);
    window.scrollTo(0, 0);
  };

  const scrollToTabSection = () => {
    let divs = document.getElementsByClassName(
      'pdp-sds-link'
    );

    let tabViewHeaderHeight = document.getElementsByClassName(
      'pdp-chm-mob-tab-view'
    )[0]?.clientHeight;

    let tabItemsHeaderHeight =
      document.getElementsByClassName('c-item-header')[0]?.clientHeight;

    window.scrollTo({
      top:
        divs[0]?.getBoundingClientRect().top -
        divs[0]?.getBoundingClientRect().height -
        getHeaderHeight() -
        tabViewHeaderHeight -
        tabItemsHeaderHeight -
        SCROLL_THRESHOLD +
        (window.scrollY ? window.scrollY : window.pageYOffset || 0),
      behavior: 'smooth'
    });
  };

  if (selectedProduct) {
    tabs = [
      {
        id: PDP_TABS_IDS.PRODUCT_OVERVIEW,
        title: intl.formatMessage({ id: 'productOverview' }),
        content: (<ProductDescription />)
      }
    ];

    if(!CHEMICAL_TEMPLATES.includes(activeItem.productTemplate)) {
      tabs.push({
        id: PDP_TABS_IDS.SPECIFICATIONS,
        title: intl.formatMessage({ id: 'specificationsText' }),
        content: (
          <>
            <Suspense>
              <Specifications
                specifications={activeItem?.specifications}
                title={intl.formatMessage({ id: 'specifications' })}
              />
            </Suspense>
          </>
        )
      });
    }

    tabs.push({
      id: PDP_TABS_IDS.DOCUMENTS,
      title: intl.formatMessage({ id: 'documentsTitle' }),
      content: (
        <>
          {hydrated && (
            <Suspense>
              <Documents />
            </Suspense>
          )}
        </>
      )
    });

    if (faqCount > 0) {
      tabs.push({
        id: PDP_TABS_IDS.FAQ,
        title: intl.formatMessage({ id: 'faq' }),
        content: (
          <>
            {hydrated && (
              <Suspense>
                <FAQ />
              </Suspense>
            )}
          </>
        )
      });
    }

    if ((citationCount > 0 || citationsVisible === true) && citationsFetched) {
      tabs.push({
        id: PDP_TABS_IDS.CITATIONS,
        title: intl.formatMessage({ id: 'citationsTitle' }),
        content: (
          <>
            {hydrated && (
              <Suspense>
                <Citations />
              </Suspense>
            )}</>
        )
      });
    }

    if (showSafetyAndHandling) {
      tabs.push({
        id: PDP_TABS_IDS.SAFETY_AND_HANDLING,
        title: intl.formatMessage({ id: 'commonSafetyAndHandling' }),
        content: (
          <>
            {hydrated && (
              <Suspense>
                <SafetyAndHandling />
              </Suspense>
            )}
          </>
        )
      });
    }

    const slideData = () => {
      if (isEntering === true) {
        return 'slide-enter';
      } else if (isEntering === false) {
        return 'slide-exit';
      } else if (isEntering === 'goback') {
        return 'slide-go-back';
      }
      return '';
    };

    return (
      <div className="pdp-main">
        <div className="pdp-product-tab-wrapper">
          <div
            className={`child-route ${slideData()}`}
            onAnimationEnd={() => setIsEntering(false)}
          >
            <div
              className="pdp-chm-mob-tab-view"
              onClick={() => {
                document
                  .getElementsByTagName("footer")?.[0]
                  ?.style?.setProperty("display", "none");
                document
                  .getElementsByClassName("child-route")?.[0]
                  ?.style?.setProperty("position", "absolute");

                setTimeout(() => {
                  document
                    .getElementsByTagName("footer")?.[0]
                    ?.style?.setProperty("display", "block");
                }, 1000);

                setIsEntering("goback");
                setTimeout(() => {
                  history.goBack();
                }, 500);
              }}
            >
              <div className="pdp-chm-mob-tab-view-wrapper">
                <span className="pdp-chm-mob-tab-view__back-icon icon-24-arrow-left-mono"></span>
                <span className="pdp-chm-mob-tab-view__back-button">Back</span>
              </div>
              <ProductInfo />
            </div>
            <div className="container">
              <TabContent
                onTabChange={onTabChange}
                activeIndex={activeTab}
                tabs={tabs}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default MobileNavContainers;
