import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { CoreSegmentedbuttons, CoreSegmentedbuttonsItem } from "@komodo-design-system/komodo-components-react";

import analyticsService from '../../../../services/analyticsService';
import PdpButtonProductSelector from './PdpButtonProductSelector/PdpButtonProductSelector';
import PdpTableProductSelector from './PdpTableProductSelector/PdpTableProductSelector';
import DevicesTypes from '../../../../constants/deviceTypes';
import setView from '../../../../actions/productSelector/setView';
import ProductSelectorViews from '../../../../constants/productSelectorViews';
import { CHEMICAL_TEMPLATES } from '../../../../constants/constants';
import { analyticsProductSelectorView } from '../../../../services/analyticsEvents';
import { DEFAULT_VIEW } from '../../../../constants/constants';
import ButtonViewSelectedIcon from '../../../../styles/images/view-chips-selected.svg';
import ButtonViewIcon from '../../../../styles/images/view-chips.svg';
import TableViewSelectedIcon from '../../../../styles/images/view-table-selected.svg';
import TableViewIcon from '../../../../styles/images/view-table.svg';
import SafetyAndHandlingJumpLink from './SafetyAndHandlingJumpLink/SafetyAndHandlingJumpLink';
import Subscription from './Subscription/Subscription';
import HaveQuestions from './HaveQuestions';
import BundlesInformation from './Bundles/BundlesInformation/BundlesInformation';

import "./PdpProductSelector.scss";
import useDeviceType from '../../../../hooks/useDeviceType';
import useBundleSku from "../../../../hooks/useBundleSku";

const PdpProductSelector = () => {
  const dispatch = useDispatch();
  const { bundleListState } = useBundleSku();

  const view = useSelector((state) => state.productSelector.view);
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const isTablet = useDeviceType(DevicesTypes.Tablet);
  const isTabletOrMobile = isMobile || isTablet;

  const { product } = useSelector((state) => state.product);
  const productItems = product?.items;
  const productItem = product?.items?.[0];
  const defaultView = productItem.defaultView;
  const isNotSingleFamilySku = !!productItem?.skuDifferentiatorsV3?.length;
  const showViewSelectorOptions = productItems?.length > 1;
  const prices = useSelector((state) => state.prices);
  const productTemplate = product?.template;
  const isChemicalPage = !!CHEMICAL_TEMPLATES.includes(productItem.productTemplate);
  const isSubscriptionProduct = productItem?.isSubscriptionProduct;
  const isDefaultButtonview = productItems?.length === 1 && productItem?.skuDifferentiatorsV3?.length >= 1;

  useEffect(() => {
    if (productItems && prices.prices) {
      analyticsService(productTemplate, productItems, prices.prices);
    }
  }, [productTemplate, productItems, prices.prices, productItem]);

  useEffect(() => {
    if (defaultView === DEFAULT_VIEW.TABLE) {
      dispatch(setView(ProductSelectorViews.TableView));
    }
    if (isDefaultButtonview || defaultView === DEFAULT_VIEW.BUTTON) {
      dispatch(setView(ProductSelectorViews.ButtonView));
    }
  }, [defaultView, isDefaultButtonview])

  if (!productItem) {
    return null;
  }

  const onTabClick = (tab) => {
    dispatch(setView(tab));
    analyticsProductSelectorView(tab);
  }

  return (
    <div className="pdp-product-selector">
      <div className="pdp-product-selector__icons-options-container">
        <HaveQuestions />
        {isNotSingleFamilySku && showViewSelectorOptions && <div className="pdp-product-selector__icons">
          <span className="pdp-product-selector__text">
            <FormattedMessage id="changeView" />
          </span>
          <CoreSegmentedbuttons>
            <CoreSegmentedbuttonsItem
              nopadding="true"
              selected={view === ProductSelectorViews.ButtonView}
              onClick={() => onTabClick(ProductSelectorViews.ButtonView)}
              slot="segment"
            >
              <img
                slot="content"
                src={
                  view === ProductSelectorViews.ButtonView
                    ? ButtonViewSelectedIcon
                    : ButtonViewIcon
                }
                alt={ProductSelectorViews.ButtonView}
              ></img>
            </CoreSegmentedbuttonsItem>
            <CoreSegmentedbuttonsItem
              nopadding="true"
              selected={view === ProductSelectorViews.TableView}
              onClick={() => onTabClick(ProductSelectorViews.TableView)}
              slot="segment"
            >
              <img
                slot="content"
                src={
                  view === ProductSelectorViews.TableView
                    ? TableViewSelectedIcon
                    : TableViewIcon
                }
                alt={ProductSelectorViews.TableView}
              ></img>
            </CoreSegmentedbuttonsItem>
          </CoreSegmentedbuttons>
        </div>}
      </div>

      {isNotSingleFamilySku && view === ProductSelectorViews.ButtonView && <PdpButtonProductSelector />}
      {isNotSingleFamilySku && view === ProductSelectorViews.TableView && productItems?.length > 1 && <PdpTableProductSelector productItems={productItems} />}
      {!isTabletOrMobile && isSubscriptionProduct && <Subscription prices={prices} />}
      {productItem?.bundledSkuParent &&
        <BundlesInformation 
          isFetching={
            !!bundleListState?.[productItem.catalogNumber]
              ?.isFetching
          }
          isTabletOrMobile={isTabletOrMobile}
          productTemplate={productTemplate}
        />
      }
      {isChemicalPage && <SafetyAndHandlingJumpLink />}
    </div>
  );
};

export default PdpProductSelector;
