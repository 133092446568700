const getHeaderHeight = () => {
  const GLOBAL_OFFER_HEADER_BAR = '#header-offer-bar';
  const B2B_MESSAGE_BAR = '#messagingBar';
  const MAIN_HEADER_BAR = '.main-header-container';
  const mainHeaderBar = document?.querySelector(MAIN_HEADER_BAR);
  const globalHeaderBar = document?.querySelector(GLOBAL_OFFER_HEADER_BAR);
  const messageBar = document?.querySelector(B2B_MESSAGE_BAR);
  let headerHeight = 0;
  if (mainHeaderBar) {
    headerHeight += mainHeaderBar?.getBoundingClientRect()?.height;
  }
  if (globalHeaderBar) {
    headerHeight += globalHeaderBar?.getBoundingClientRect()?.height;
  }
  if (messageBar) {
    headerHeight += messageBar?.getBoundingClientRect()?.height;
  }
  return headerHeight;
};

export default getHeaderHeight;
