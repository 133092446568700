import { ProductSelectorActions } from "../actions/actionTypes";
import ProductSelectorViews from "../constants/productSelectorViews";

export const productSelectorInitialState = {
  view: ProductSelectorViews.TableView,
  differentiators: {},
  viewAll: {}
};

const productSelector = (state = productSelectorInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ProductSelectorActions.SetView:
      return {
        ...state,
        view: payload
      };
    case ProductSelectorActions.SetActiveValue:
      return setActiveValue(state, payload);
    case ProductSelectorActions.SetSelectedValue:
      return setSelectedValue(state, payload);
    case ProductSelectorActions.SetViewAll:
      return setViewAll(state, payload);
    case ProductSelectorActions.ResetViewAll:
      return resetViewAll(state, payload);
    default:
      return state;
  }
};

const setActiveValue = (state, payload) => {
  const { skuDifferentiatorId, specValue, active } = payload;

  const newDifferentiators = {
    ...state.differentiators
  };

  const newDifferentiatorValues = newDifferentiators[skuDifferentiatorId].map(value => {
    if (value.value === specValue) {
      return {
        ...value,
        active
      };
    }

    return value;
  });

  newDifferentiators[skuDifferentiatorId] = newDifferentiatorValues;

  return {
    ...state,
    differentiators: newDifferentiators
  };
}

const setSelectedValue = (state, payload) => {
  const { skuDifferentiatorId, specValue } = payload;

  const newDifferentiators = {
    ...state.differentiators
  };

  const newDifferentiatorValues = newDifferentiators[skuDifferentiatorId].map(value => {
    value.selected = value.value === specValue;
    return value;
  });

  newDifferentiators[skuDifferentiatorId] = newDifferentiatorValues;

  return {
    ...state,
    differentiators: newDifferentiators
  };
}

const setViewAll = (state, payload) => {
  const { skuDifferentiatorId, isViewAll } = payload;

  const newViewAll = {
    ...state.viewAll
  };

  newViewAll[skuDifferentiatorId] = isViewAll;

  return {
    ...state,
    viewAll: newViewAll
  };
}

const resetViewAll = (state, payload) => {
  const { exceptSkuDifferentiatorId } = payload;

  const newViewAll = {};

  if (exceptSkuDifferentiatorId) { 
    const exceptValue = state.viewAll[exceptSkuDifferentiatorId];
    newViewAll[exceptSkuDifferentiatorId] = exceptValue;
  }  

  return {
    ...state,
    viewAll: newViewAll
  };
}

export default productSelector;