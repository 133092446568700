import { combineReducers } from 'redux';
import { requestsReducer } from 'redux-requests';

import dictionary from './dictionary';
import product from './product';
import deviceType from './deviceType';
import ui from './ui';
import productSelector from './productSelector';
import user from './user';
import favorites from './favorites';
import prices from './prices';
import availability from './availability';
import quantity from './quantity';
import stockAlert from './stockAlert';
import bundleInformation from './bundleInformation';
import citations from './citations';
import certificates from './documents/certificates';
import sds from './documents/sds';
import otherDocuments from './documents/otherDocuments';
import faq from './faq';
import recommendations from './recommendations';
import relatedProducts from './relatedProducts';
import skuAlternatives from './skuAlternatives';
import figures from './figures';
import lulls from './documents/lulls';
import molecularProbes from './documents/molecularProbes';
import protocols from './documents/protocols';
import mediaFormulations from './mediaFormulations';
import videoContent from './videos';
import structureAndSpectra from './documents/structureAndSpectra';
import pods from './pods';

export default combineReducers({
  requests: requestsReducer,
  dictionary,
  deviceType,
  ui,
  productSelector,
  user,
  product,
  prices,
  favorites,
  availability,
  quantity,
  stockAlert,
  bundleInformation,
  citations,
  certificates,
  sds,
  otherDocuments,
  faq,
  recommendations,
  relatedProducts,
  skuAlternatives,
  lulls,
  molecularProbes,
  protocols,
  mediaFormulations,
  figures,
  videoContent,
  structureAndSpectra,
  pods
});
