import { BundleInformationActions } from '../actions/actionTypes';
import { PENDING, SUCCESS, ERROR } from '../actions/status';

export const initialState = {
  bundleList: {},
  isLegalChangeAppliedForWOP: false,
  isSpecialOfferEnabled: false
};

const bundleInformation = (state = initialState, action) => {
  const { type, response, error, payload } = action;

  switch (type) {
    case `${BundleInformationActions.GetBundleInformation}_${PENDING}`:
      return {
        ...state,
        bundleList: {
          ...state.bundleList,
          [payload?.sku]: {
            isFetching: true
          }
        }
      };
    case `${BundleInformationActions.GetBundleInformation}_${SUCCESS}`:
      return {
        ...state,
        bundleList: {
          ...state.bundleList,
          [payload?.sku]: {
            isFetching: false,
            fetched: true,
            error: null,
            data: response?.products?.[0]?.relatedProducts?.filter(
              (it) => it.isViewable
            )
          }
        },
        isLegalChangeAppliedForWOP: response?.isLegalChangeAppliedForWOP,
        isSpecialOfferEnabled: response?.isSpecialOfferEnabled
      };
    case `${BundleInformationActions.GetBundleInformation}_${ERROR}`:
      return {
        ...state,
        bundleList: {
          ...state.bundleList,
          [payload?.sku]: {
            isFetching: false,
            fetched: true,
            error,
            data: null
          }
        },
        isLegalChangeAppliedForWOP: false,
        isSpecialOfferEnabled: false
      };
    default: {
      return state;
    }
  }
};

export default bundleInformation;
