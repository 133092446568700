import React from 'react';
import PropTypes from 'prop-types';

import './PackageComponentsProduct.scss';
import { FormattedMessage } from 'react-intl';
import { OLD_BASE_NAME } from '../../../../../../constants/constants';

const PackageComponentsProduct = ({ product }) => { 
  const { shortDescription, catalogNumber, productSize, kitQuantity, displayProductLink } = product ?? {};

  const productTitle = () => { 
    if (displayProductLink) {
      const productUrl = `${OLD_BASE_NAME}/${catalogNumber}`;

      return (
        <a className="pdp-package-components-product-title"
          href={productUrl} 
          dangerouslySetInnerHTML={{ __html: shortDescription }}>
        </a>
      );
    }

    return (
      <div className="pdp-package-components-product-title"
        dangerouslySetInnerHTML={{ __html: shortDescription }}>        
      </div>
    );
  };

  return (
    <div className="pdp-package-components-product">
      {productTitle()}

      <div className="pdp-package-components-product-props">
        <div className="pdp-package-components-product-prop">
          <div className="prop-label">
            <FormattedMessage id='packageComponentsCatalogNumber' />
          </div>
          <div className="prop-value">
            {catalogNumber}
          </div>
        </div>

        <div className="pdp-package-components-product-prop">
          <div className="prop-label">
            <FormattedMessage id='packageComponentsUnitSize' />
          </div>
          <div className="prop-value" dangerouslySetInnerHTML={{ __html: productSize}}>
          </div>
        </div>

        <div className="pdp-package-components-product-prop">
          <div className="prop-label">
            <FormattedMessage id='packageComponentsQuantity' />
          </div>
          <div className="prop-value">
            {kitQuantity}
          </div>
        </div>
      </div>

    </div>
  );
};

PackageComponentsProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

export default PackageComponentsProduct;