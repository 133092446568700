import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';
import { subscribeEmail } from '../../../../actions/stockAlert/stockAlert';
import showAlert from '../../../../actions/ui/showAlert';
import { CoreModal, CoreTextfield, CoreButton, CoreLoader } from '@komodo-design-system/komodo-components-react';
import { analyticsOnCloseRSAModal, analyticsOnNotifyMeRSAModal } from '../../../../services/analyticsEvents';


const RequestStockModal = ({ onModalClose, catalogNumber }) => {
  const user = useSelector((state) => state.user.userData);
  const { isFetching } = useSelector((state) => state.stockAlert);
  const dispatch = useDispatch();
  const getEmailData = () => {
    if (!user?.isAnonymous) {
      return user?.userName;
    }
    return '';
  };
  const [email, setEmail] = useState(getEmailData());
  const [valid, setValid] = useState(true);
  const intl = useIntl();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailValidation = () => {
    const emailRegex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

    const data = !!emailRegex.test(email);
    setValid(data);
  }

  const onClose = () => {
    onModalClose();
    analyticsOnCloseRSAModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (valid && email) {
      const payload = {
        items: [
          {
            catalogNumber,
            quantity: 1
          }
        ],
        emailAddress: email
      };
      dispatch(subscribeEmail(payload)).then(
        () => {
          dispatch(showAlert(
            `${intl.formatMessage({ id: "commonSuccess" })}:`,
            intl.formatMessage({ id: 'subscribeEmailSuccess' }),
            "inline",
            "success"
          ));
          onModalClose();
          analyticsOnNotifyMeRSAModal();
        }
      )
        .catch(() => {
          dispatch(showAlert(
            `${intl.formatMessage({ id: "commonError" })}:`,
            intl.formatMessage({ id: 'subscribeEmailError' }),
            "inline",
            "error"
          ))
        }
        )
    }
  };
  
  return createPortal(
    <CoreModal
      isopen
      width="800"
      header="Thank you for your interest in this item."
      bgclose="true"
      nooverflow="true"
      closeicon="true"
      onModalClosed={onClose}>
      <div className="pdp-rsa__catalog">
        <div className="pdp-rsa__catalog-label">
          <FormattedMessage id="catalogNumberCapsColon"></FormattedMessage>
        </div>
        <div className="pdp-rsa__catalog-value">{catalogNumber}</div>
      </div>
      <div className="pdp-rsa__note">
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'requestStockAlertModalNote'
            })
          }}
        />
      </div>
      <CoreTextfield
        type="text"
        placeholder={intl.formatMessage({ id: 'emailAddress' })}
        fullWidth
        value={email}
        invalid={!valid}
        onTextfieldChange={handleEmailChange}
        onTextfieldFocusOut={handleEmailValidation}
      />
      <div className='pdp-rsa__submit'>
        {isFetching &&
          <div className='pdp-rsa__submit-loader'>
            <CoreLoader
              className="pdp-rsa__loader"
              variant="circular"
              functionality="indeterminate"
              size="small"
              information="false"
              indicator="false" />
          </div>
        }
        <CoreButton
          variant="primary"
          onClick={handleSubmit}
        >
          <span slot="content">
            <FormattedMessage id="notifyMe" />
          </span>
        </CoreButton>
      </div>
    </CoreModal>,
    document.getElementById('modal')
  );
};

RequestStockModal.propTypes = {
  onModalClose: PropTypes.func,
  catalogNumber: PropTypes.string
};

export default RequestStockModal;
