import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'react-redux';
import { PRICE_RULE_TYPES } from '../../constants/constants';
import AccountPrice from './AccountPrice';
import SpecialOfferPrice from './SpecialOfferPrice';
import OnlinePrice from './OnlinePrice';
import ListPrice from './ListPrice';
import { getNormalizeBilling } from '../../utils/utils';

const PriceOrderable = ({ selectedSubscription, itemPrice }) => {
    const priceRuleType = itemPrice?.priceRuleType;
    const user = useSelector((state) => state.user.userData);
    const isAnonymous = user?.isAnonymous;
    if(!selectedSubscription) {
      switch (priceRuleType) {
        case PRICE_RULE_TYPES.LIST:
          return <ListPrice itemPrice={itemPrice} isAnonymous={isAnonymous}/>;
        case PRICE_RULE_TYPES.ACCOUNT:
          return <AccountPrice itemPrice={itemPrice} isAnonymous={isAnonymous} />;
        case PRICE_RULE_TYPES.SPECIALOFFER:
          return <SpecialOfferPrice itemPrice={itemPrice} isAnonymous={isAnonymous}/>;
        case PRICE_RULE_TYPES.ONLINE:
          return <OnlinePrice itemPrice={itemPrice} isAnonymous={isAnonymous} />;
      }
    }
    else {
      return (
        <div className='pdp-subscription-price-container'>
          <span className="pdp-subscription-price-label">
            <FormattedMessage id={"yourPriceLabel"} />
          </span>
          <span className='pdp-subscription-price-value'>
            {Number(selectedSubscription?.price).toFixed(2)} / {getNormalizeBilling(selectedSubscription)}
          </span>
        </div>
      )
    }
};

PriceOrderable.propTypes = {
  selectedSubscription: PropTypes.object,
  itemPrice: PropTypes.object,
};

export default PriceOrderable;
