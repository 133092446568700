import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import clsx from "clsx";
import loyaltyIcon from "../../../../styles/images/icon-loyalty-gift.svg";

import './Loyalty.scss';

const Loyalty = ({ showText }) => {
  const intl = useIntl();

  return (
    <span className='pdp-loyalty'>
      <img
        className={clsx('pdp-loyalty__icon', !showText ? 'pdp-loyalty__icon-absolute' : 'pdp-loyalty__icon-text')}
        src={loyaltyIcon}
        alt="Loyalty Icon"
      />
      {showText && <span className='pdp-loyalty-text' dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "loyaltyText" }) }} />}
    </span>
  );
};

Loyalty.propTypes = {
  showText: PropTypes.bool
};

export default Loyalty;
