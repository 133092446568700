import { ProductSelectorActions } from '../actionTypes'

const setView  = (tabId) => {
    return {
      type: ProductSelectorActions.SetView,
      payload: tabId
    }
};
  
export default setView;
