import React from 'react';
import PropTypes from 'prop-types';

import { AVAILABILITY_CODES, PRICE_STATUS } from '../../../../../constants/constants';
import OutOfStock from '../OutofStock';
import InStock from '../InStock';

const AvailabilityMessage = ({ availability, priceAccessStatus, isScmsUser }) => {
  switch (priceAccessStatus) {
    case PRICE_STATUS.PVNO:
      if (!isScmsUser) {
        switch (availability?.availabilityCode) {
          case AVAILABILITY_CODES.OUT_OF_STOCK:
          case AVAILABILITY_CODES.IN_STOCK:
            return <OutOfStock availability={availability} />
        }
      }
      else {
        switch (availability?.availabilityCode) {
          case AVAILABILITY_CODES.OUT_OF_STOCK:
            return <OutOfStock availability={availability} />
          case AVAILABILITY_CODES.IN_STOCK:
            return <InStock availability={availability} />
        }
      }
      break;
    case PRICE_STATUS.ORDERABLE:
      switch (availability?.availabilityCode) {
        case AVAILABILITY_CODES.OUT_OF_STOCK:
          return <OutOfStock availability={availability} />
        case AVAILABILITY_CODES.IN_STOCK:
          return <InStock availability={availability} />
      }
      break;    
    default:
      return '-';
  }

  return '-';
};

AvailabilityMessage.propTypes = {
  availability: PropTypes.object,
  priceAccessStatus: PropTypes.string.isRequired,
  isScmsUser: PropTypes.bool.isRequired,
};

export default AvailabilityMessage;