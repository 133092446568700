import { DocumentActions } from "../../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../../actions/status";

const initialMolecularProbesState = {
  documents: [],
  documentsCount: 0,
  loading: false,
  fetched: false
};

const molecularProbes = (state = initialMolecularProbesState, action) => {
  switch (action.type) {
    case `${DocumentActions.getMolecularProbes}_${SUCCESS}`:
      return {
        ...state,
        documents: action.response.searchResults?.probesHandbookList ?? [],
        loading: false,
        fetched: true
      };
    case `${DocumentActions.getMolecularProbes}_${PENDING}`:
      return {
        ...state,
        loading: true,
        fetched: false
      };
    case `${DocumentActions.getMolecularProbes}_${ERROR}`:
      return {
        ...state,
        documents: [],
        loading: false,
        fetched: false
      };
    default:
      return state;
  }
};

export default molecularProbes;
