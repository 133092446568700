const imageUrlBuilder = (url) => {
  url = url ?? "";

  const urlParts = url.split("/");
  urlParts[urlParts.length - 1] = encodeURIComponent(urlParts[urlParts.length - 1]);

  url = urlParts.join("/");

  return `${process.env.IMAGE_DOMAIN}${url}`;
};

export default imageUrlBuilder;
