import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { UserActions } from '../actionTypes';

const getUser = () => {
  const url = endpoints.userTypeUrl();
  const options = httpOptions.get();
  
  return apiRequest(UserActions.GetRelatedProducts, url, options);
};

export default getUser;
