export const wait = async (time = 0) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const waitTillNextCycle = async () => {
  return wait(0);
};

export const waitTillElementIsPositioned = async (elementRef) => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (elementRef.current != null) {
        const position = elementRef.current.getBoundingClientRect();

        if (position.top !== 0 && position.left !== 0) {
          clearInterval(interval);
          resolve(position);
        }
      }
    }, 100);
  });
};