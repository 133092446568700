import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AVAILABILITY_CODES } from '../../../../constants/constants';
import getBranchPlantConditions from './getBranchPlantConditions';
import { FormattedMessage } from 'react-intl';
import RequestStockModal from './RequestStockModal';
import { analyticsOpenRSAModal } from '../../../../services/analyticsEvents';
import './RequestStockAlert.scss';
import useActiveItem from '../../../../hooks/useActiveItem';


const RequestStockAlertLink = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const availabilityState = useSelector((state) => state.availability?.availability);
    const activeItem = useActiveItem();
    const availability = availabilityState?.find((availability) => availability.catalogNumber === activeItem?.catalogNumber);

    const onModalClose = () => {
        setIsModalOpen(false);
    }

    const showRequestStockAlert = getBranchPlantConditions(availability, activeItem?.branchDetails);
    const handleClick = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
        analyticsOpenRSAModal();
    };

    return (
        <div>
            {availability?.availabilityCode === AVAILABILITY_CODES.OUT_OF_STOCK && showRequestStockAlert && !activeItem?.isHideStockAvailability && (
                <a className='pdp-rsa-link' href="#" onClick={handleClick}>
                    <FormattedMessage id={"requestStockAlert"} />
                </a>
            )}
            {isModalOpen && <RequestStockModal onModalClose={onModalClose} catalogNumber={activeItem?.catalogNumber} />}
        </div>

    );
};

export default RequestStockAlertLink;
