import { CitationActions } from '../actions/actionTypes';
import { SUCCESS, ERROR, PENDING } from "../actions/status";

export const citationsInitialState = {
  citationsList: null,
  isFetching: false,
  fetched: false,
  error: null,
  visible: false
};

const citations = (state = citationsInitialState, action) => {
  const { type, response, error } = action;

  switch (type) {
    case `${CitationActions.GetCitations}_${PENDING}`:
      return {
        ...state,
        isFetching: true
      };
    case `${CitationActions.GetCitations}_${SUCCESS}`:
      const visible = state.visible || response.assetTypes?.[0]?.documentTypes?.[0]?.assets?.length > 0;
      return {
        ...state,
        citationsList: response.assetTypes?.[0]?.documentTypes?.[0]?.assets ?? null,
        count: response.assetTypes?.[0]?.documentTypes?.[0]?.citationSubCount ?? 0,
        isFetching: false,
        fetched: true,
        error: null,
        visible
      };
    case `${CitationActions.GetCitations}_${ERROR}`:
      return {
        ...state,
        citationsList: null,
        count: 0,
        isFetching: false,
        fetched: true,
        error
      };
    case CitationActions.ClearCitations:
      return {
        ...citationsInitialState
      };
    default: {
      return state;
    }
  }
};

export default citations;
