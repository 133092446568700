import React from 'react';
import requestLogin from '../../utils/requestLogin';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import { FormattedMessage, useIntl } from 'react-intl';

const PriceDSVP = () => {
  const intl = useIntl();
  return (
    <>
        <a className="pdp-price__dsvp-link" onClick={() => requestLogin()}><FormattedMessage id="priceTooltipSignIn"/></a>
        <span className='pdp-price__dsvp-tooltip'>
            <CoreTooltip
            label={intl.formatMessage({ id: 'priceDSVPTooltip' })}
            alignment='start' 
            placement='right'
            >
                <span className='icon-16-info-mono'></span>
            </CoreTooltip>
        </span>
    </>
   
  );
};

export default PriceDSVP;
