import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { DocumentActions } from '../actionTypes';
import PropTypes from 'prop-types';

const getStructureAndSpectra = (productId, familyId) => {
  const url = endpoints.getStructureAndSpectra(productId, familyId);
  const options = httpOptions.get();

  return apiRequest(DocumentActions.GetStructureAndSpectra, url, options);
};

getStructureAndSpectra.propTypes = {
  productId: PropTypes.string,
  familyId: PropTypes.string
};

export default getStructureAndSpectra;
