import React from 'react';
import PropTypes from 'prop-types';

import { DIM_COMPONENTS_MEASURED } from '../../../../../constants/constants';

const TableSkuDifferentiators = React.memo(({ skuDifferentiators, specifications }) => {
  if (skuDifferentiators?.map && specifications.length) {
    return skuDifferentiators.map((diff) => {
      const specValueObj = specifications.find((spec) => spec.id === diff.id);
      let specValue;
      if (specValueObj?.id === DIM_COMPONENTS_MEASURED) {
        specValue = specValueObj?.values?.join(', ');
      } else {
        specValue = specValueObj ? specValueObj.value : '';
      }
      return (
        <td
          key={`diff-${diff.id}`}
          dangerouslySetInnerHTML={{
            __html: specValue
          }}
        />
      );
    });
  }
  return null;
});

TableSkuDifferentiators.propTypes = {
  skuDifferentiators: PropTypes.array,
  specifications: PropTypes.array,
};

export default TableSkuDifferentiators;
