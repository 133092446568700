import React, { useState } from "react";
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from "react-redux";
import { CoreContextmenu, CoreContextmenuItem, CoreLoader } from '@komodo-design-system/komodo-components-react';
import { addToFavorites } from '../../actions/favorites/favorites';
import showAlert from '../../actions/ui/showAlert';
import { FormattedMessage, useIntl } from "react-intl";
import { sharedListURL } from '../../constants/constants';
import { analyticsFormatDataFavorites, analyticsFavorites } from '../../services/analyticsEvents';
import { Log } from '../../utils/log';
import { isClientRuntime } from "../../utils/isClientRuntime";
import setQuantity from "../../actions/quantity/setQuantity";
import clsx from "clsx";
import { FAVORITES_CONTEXT_MENU_WIDTH } from "./Favorites";

const FavoritesContextMenu = ({
  right,
  user, 
  setFavoritesAdded, 
  setIsOpen, 
  item, 
  quantity, 
  setRelatedProductsQuantity,
  onClickAnalyticsRelatedProducts,
  fromRelatedProducts 
}) => {
  if (!isClientRuntime()) {
    return null;
  }
  const dispatch = useDispatch();
  const intl = useIntl();
  const [textValue, setTextValue] = useState('');
  const prices = useSelector((state) => state.prices.prices);
  const quantityCommerceBox = useSelector((state) => state.quantity.quantity);
  const { favoritesLists, isFetching } = useSelector((state) => state.favorites);

  const handleInput = (event) => {
    setTextValue(event.target.value);
  }

  const handleClick = (event,listId, opcode) => {
    event.stopPropagation();

    const payload = {
      userkey: user?.userId,
      opcode, //for new list 20
      lineItems: [{
        src: 'CMGT-PDP',
        itemId: item.catalogNumber,
        qty: fromRelatedProducts ? quantity : quantityCommerceBox
      }]
    }

    if (listId) {
      payload.listId = listId;
    }

    if (textValue) {
      payload.listName = textValue;
    }
    
    if (textValue?.trim() || listId) {
      dispatch(addToFavorites(payload))
        .then(response => {
          const relatedProductFormat = fromRelatedProducts ? onClickAnalyticsRelatedProducts?.('savetolist', true) : '';
          const analyticsItem = analyticsFormatDataFavorites(item, payload?.lineItems[0], prices, relatedProductFormat);
          analyticsFavorites(analyticsItem);
          dispatch(showAlert(
            `${intl.formatMessage({ id: "commonSuccess" })}:`,
            intl.formatMessage({ id: 'favoritesSuccess' }),
            "inline",
            "success",
            "3000",
            <a target slot='link' href={sharedListURL(response.listId)}>&nbsp;{response.listName}</a>,
          )
          );
          setIsOpen(false);
          setFavoritesAdded(true);
        })
        .catch((error) => {
          dispatch(showAlert(
            `${intl.formatMessage({ id: "commonError" })}:`,
            intl.formatMessage({ id: 'favoritesError' }),
            "inline",
            "error",
            "3000"
          )
          );
          setIsOpen(false);
          Log("Error", error);
        });
      
      setTextValue('');

      if(fromRelatedProducts){
        setRelatedProductsQuantity(1);
      }
      else {
        dispatch(setQuantity(1));
      }
    }
    else {
      dispatch(showAlert(
        `${intl.formatMessage({ id: "commonInformational" })}:`,
        intl.formatMessage({ id: 'enterListName' }),
        "inline",
        "information",
        "3000"
      ));
    }    
  }

  return (
    <div className={clsx('pdp-favorites-menu', fromRelatedProducts ? 'pdp-favorites-menu--related-products' : '')} style={{ right: `${right}px`, width: `${FAVORITES_CONTEXT_MENU_WIDTH}px` }}>
      <CoreContextmenu open={true}>
        <div className="pdp-favorites-menu__list">
          {
            favoritesLists?.map((list) => {
              return (
                <CoreContextmenuItem
                  className="pdp-favorites-menu__list"
                  onClick={(event) => handleClick(event,list.listId, 10)}
                  key={list.listId}
                  label={list.listName}
                  disabled={isFetching} />
              );
            })
          }
        </div>
        <div className="pdp-favorites-menu__action">
          <input
            value={textValue}
            className="pdp-favorites-menu__action-input"
            type="text"
            onChange={handleInput}
            disabled={isFetching}
            placeholder={intl.formatMessage({ id: 'placeHolderText' })}
          />
          <button
            className="pdp-favorites-menu__action-button"
            label="Create"
            type="button"
            variant="outline"
            size="small"
            disabled={isFetching}
            onClick={(event) => handleClick(event, '', 20)}
          >
            <FormattedMessage id="create"></FormattedMessage>
          </button>
        </div>
        {isFetching && <CoreLoader
          className="pdp-favorites-menu__loader"
          variant="circular"
          functionality="indeterminate"
          size="small"
          information="false"
          indicator="false" />}

      </CoreContextmenu>
    </div>
  );
}

FavoritesContextMenu.propTypes = {
  right: PropTypes.number,
  user: PropTypes.object,
  setFavoritesAdded: PropTypes.func,
  setIsOpen: PropTypes.func,
  item: PropTypes.object,
  quantity: PropTypes.number,
  setRelatedProductsQuantity: PropTypes.func,
  onClickAnalyticsRelatedProducts: PropTypes.func,
  fromRelatedProducts: PropTypes.bool
};


export default FavoritesContextMenu;
