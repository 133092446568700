import apiRequest from "../../http/apiRequest";
import endpoints from "../../http/endpoints";
import httpOptions from "../../server/http/httpOptions";
import { SubscribeActions } from "../actionTypes";

export const subscribeEmail = (payload) => {
    const url = endpoints.subscribeEmail();
    const options = httpOptions.post({ ...payload });
    return apiRequest(SubscribeActions.subscribeAlert, url, options);
};
