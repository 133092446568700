import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { getAvailability } from '../../../../actions/availability/getAvailability';
import { AVAILABILITY_CODES, STOCK_AVAILABLE_CODES, CHINA_DELIVERY_CHANNEL, CHINESE_LANG_CODE, PRICE_STATUS } from '../../../../constants/constants';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import { FormattedMessage } from 'react-intl';
import { userProfileUrl } from '../../../../utils/utils';
import { getLanguageCode } from '../../../../utils/cookies';
import AvailabilityMessage from './AvailabilityMessage/AvailabilityMessage';

import './Availability.scss';


const Availability = ({ item, priceAccessStatus }) => {
  const dispatch = useDispatch();

  const availabilityState = useSelector((state) => state.availability);
  const prices = useSelector((state) => state.prices?.prices);
  const { product } = useSelector((state) => state.product);
  const { isHideStockAvailability, anonymousAvailabilityEnabled, isChinaSupplyCenterApiEnabled } = product?.presentationAttributes ?? {};
  const { isAnonymousUser, isScmsUser } = product;
  const itemLevelHideStock = item?.isHideStockAvailability;

  const availability = availabilityState?.availability?.find((availability) => availability.catalogNumber === item?.catalogNumber);
  const stockItem = availability?.scAvailability;
  const stockItemCode = stockItem?.availabilityCode?.toUpperCase();
  let scmsCheckYourStock;
  let availabilityContent = "";

  if (isHideStockAvailability || itemLevelHideStock) {
    availabilityContent = "";
  }
  else if (isAnonymousUser && !anonymousAvailabilityEnabled) {
    availabilityContent = "***";
  }
  else {
    availabilityContent = '-';
  }

  let chinaSCLOGO = "static/images/" + (CHINESE_LANG_CODE === getLanguageCode().toLowerCase() ? "supplycenterchinese" : "supplycenterenglish") + ".svg";

  useEffect(() => { 
    if(!availabilityState.fetched) {
      if ((!isHideStockAvailability || isChinaSupplyCenterApiEnabled) && (anonymousAvailabilityEnabled || !isAnonymousUser)) {
        dispatch(getAvailability(          
          prices?.filter(
            (price) =>
              price.priceAccess?.status === PRICE_STATUS.ORDERABLE ||
              price.priceAccess?.status === PRICE_STATUS.PVNO
          )
          ?.map((item, lineNumber) => {
            return {
              catalogNumber: item.catalogNumber,
              quantity: 1,
              lineNumber
            };
          })));
      }
    }
  }, [isHideStockAvailability, isChinaSupplyCenterApiEnabled, anonymousAvailabilityEnabled, isAnonymousUser]);

  if (availabilityState?.fetched && (!itemLevelHideStock || isChinaSupplyCenterApiEnabled) && (anonymousAvailabilityEnabled || !isAnonymousUser)) {
    availabilityContent = <AvailabilityMessage availability={availability} priceAccessStatus={priceAccessStatus} isScmsUser={!!isScmsUser} />;
  }

  /* scms tooltip content */
  if (isScmsUser && (stockItemCode === STOCK_AVAILABLE_CODES.AVAILABLE ||
    stockItemCode === STOCK_AVAILABLE_CODES.UNAVAILABLE)) {
    let stockQty = [];
    stockQty.push(stockItem?.availableQuantity); 
    if (stockItemCode === STOCK_AVAILABLE_CODES.UNAVAILABLE) {
      stockQty.push(AVAILABILITY_CODES.outOfStock);
    } else {
      stockQty.push(stockItem?.availabilityMessage);
    }
    if (stockQty.length) {
      stockQty = `(${stockQty.join(' ')})`;
    }

    const tooltipContent = (
      <div className="pdp-availability__scms-tooltip">
        <div className="pdp-availability__scms-tooltip--title">
          <h3>
            <FormattedMessage id="yourOnSiteStock" />
          </h3>
        </div>
        <div>{stockItem?.scName} {stockQty} </div>
        <div className="pdp-availability__scms-tooltip--footer">
          <a href={userProfileUrl()}>
            <FormattedMessage id="changeSelectedLocation" />
            &nbsp;&#8250;
          </a>
        </div>
      </div>
    );

    scmsCheckYourStock = <CoreTooltip alignment='center' placement='bottom' >
      <FormattedMessage id="checkYourOnsiteStock" />
      <span slot="tooltip-content">{tooltipContent}</span>
    </CoreTooltip>
  }

  let chinaSupplyCenterLOGO = availability?.deliveryChannel ===
    CHINA_DELIVERY_CHANNEL && (
      <span className='pdp-availability__cn-sc-logo'>
        <CoreTooltip alignment='center' placement='bottom' >
          <div style={{ display: 'none' }}>{chinaSCLOGO}</div>
          <img src={chinaSCLOGO} alt={'China Supply center Logo'}></img>
          <span slot="tooltip-content">
            <FormattedMessage id="chinaSupplyCenterTooltip" />
          </span>
        </CoreTooltip>
      </span>
    );
  

  
  return (
    <div className='pdp-availability'>
      {chinaSupplyCenterLOGO}
      {availabilityContent}
      {scmsCheckYourStock}
    </div>
  );
};

Availability.propTypes = {
  item: PropTypes.object,
  priceAccessStatus: PropTypes.string
};

export default Availability;
