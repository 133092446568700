import { isClientRuntime } from "./isClientRuntime";

export const LogExecTime = (name = "PROFILE", data = {}, f) => {
  IsDev && console.group(name);
  IsDev && Log("PARAMS: ", data);

  const dateBefore = new Date();
  f();
  const dateAfter = new Date();

  IsDev && Log(`TIME: ${dateAfter - dateBefore} ms`);
  IsDev && console.groupEnd();
};

export const LogExecTimeAsync = async (name = "PROFILE", data = {}, f) => {
  IsDev && console.group(name);

  IsDev && Log("PARAMS: ", data);

  const dateBefore = new Date();
  await f();
  const dateAfter = new Date();

  IsDev && Log(`TIME: ${dateAfter - dateBefore} ms`);
  IsDev && console.groupEnd();
};

export const Log = (name, data, isError = false) => {
  if (isError === true && (IsTest || IsDev)) {
      console.warn(name, data);
      return;
  }

  if (!IsDev) {
      return;
  }

  const dataStr = Object.entries(data || {}).reduce((acc, [key, value]) => {
      if (value != null) {
          if (Array.isArray(value)) {
              value = value.join(",");
          }
          else if (typeof value === "object") {
              value = JSON.stringify(value, null, "\t");
          }

          if (typeof value !== "function" && typeof value !== "object") {
              return `${acc}\n\t${key}=${value}; `;
          }
      }

      return acc;
  }, "");

   
  console.log(`%c ${name}${dataStr}`, "background: #eee; color: #444");
};

let localStorageDebugFlag = false;
if (isClientRuntime() && window?.localStorage != null) {
  localStorageDebugFlag = window.localStorage.getItem("pdpDebug") === "true";
}

const DebugOverride = localStorageDebugFlag || (isClientRuntime() && window?.pdpDebug === true);

export const IsDev = DebugOverride || (typeof process !== "undefined" && process.env?.NODE_ENV === "development");
export const IsTest = typeof process !== "undefined" && process.env?.NODE_ENV === "test";

export const Logger = {
  LogExecTime,
  LogExecTimeAsync,
  Log
};