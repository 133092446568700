import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode } from '../../utils/cookies';
import { DocumentActions } from '../actionTypes';

const getCertificates = (skus, pageNo, searchValue) => {
  const countryCode = getCountryCode();
  const optionalParams = [];

  if (pageNo) {
    optionalParams.push(`pageNo=${pageNo}`);
  }

  if (searchValue) {
    const keyword = window?.encodeURIComponent(searchValue) ?? searchValue;
    optionalParams.push(`lotNumbers=${keyword}`);
  }

  const url = endpoints.getCertificates(skus, countryCode, optionalParams);
  const options = httpOptions.get();

  options.payload = {
    searchValue
  };

  return apiRequest(DocumentActions.GetCertificates, url, options);
};

export default getCertificates;
