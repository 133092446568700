import { DictionaryActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";


export const dictionaryInitialState = {
  locale: "",
  messages: {}
};

const dictionary = (state = dictionaryInitialState, action) => {
  const { type, response, error } = action;

  switch (type) {
    case `${DictionaryActions.GetDictionary}_${PENDING}`:
      return {
        ...state,
        isFetching: true
      };
    case `${DictionaryActions.GetDictionary}_${SUCCESS}`:
      return {
        messages: response.messages,
        locale: response.locale,
        isFetching: false,
        fetched: true,
        error: null
      };
    case `${DictionaryActions.GetDictionary}_${ERROR}`:
      return {
        ...state,
        isFetching: false,
        fetched: true,
        error
      };
    default: {
      return state;
    }
  }
};

export default dictionary;