import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ellipsedText } from '../../../utils/utils';
import { decodeHtmlEntities } from '../../../utils/decodeHtmlEntities';
import { useDispatch } from 'react-redux';
import scrollToTab from '../../../actions/ui/scrollToTab';
import ProductDetailTabs from '../../../constants/productDetailTabs';
import { removeHtmlTags } from '../../../utils/removeHtmlTags';

const ShortenDescription = ({ description }) => {
  const dispatch = useDispatch();

  const decodedDescription = decodeHtmlEntities(description);
  const htmlSanitizedDescription = removeHtmlTags(decodedDescription);
  let processedDescription = ellipsedText(htmlSanitizedDescription, 20);

  // Take into account not only spaces but also other chars like 。、（）to account for Japanese text.
  // TODO: Combine this logic in one place - preferably in ellipsedText function
  const lastWordStartsAt = /(?: |\s|\n|。|、|（|）)(?!.*(?: |\s|\n|。|、|（|）))/.exec(processedDescription).index;
  processedDescription = processedDescription?.substring(0, lastWordStartsAt) + `<span class="last-description">${processedDescription?.substring(lastWordStartsAt)}</span>`;
  
  const handleReadMoreClick = () => { 
    dispatch(scrollToTab(ProductDetailTabs.ProductOverview));
  };

  return (
    <div className="short-description">
      <span dangerouslySetInnerHTML={{ __html: processedDescription }}></span>
      <span className="read-more" onClick={handleReadMoreClick}><FormattedMessage id="readMore" /></span>
    </div>
  );
}

ShortenDescription.propTypes = {
  description: PropTypes.string,
};

export default ShortenDescription;
