import { isClientRuntime } from "./isClientRuntime";

export const appCookieCache = {};

export const getCookie = (cookieName) => {
  if (!isClientRuntime()) {
    return null;
  }

  if (!appCookieCache.ready) {
    const cookiesString = document?.cookie;
    
    try {
      (cookiesString ?? "").split(';').forEach((cookieString) => {
        const [cookieName, value] = (cookieString ?? "").split('=');
        const cookieNameTrimmed = cookieName?.trim();

        if (cookieNameTrimmed.length > 0) {
          appCookieCache[cookieNameTrimmed] = value;
        }
      });

      appCookieCache.ready = true;
    } catch {
      // DO NOTHING
    }
  }

  return appCookieCache[cookieName];
};

export const isChina = () => {
  return getCountryCode() === 'cn';
};

export const isJapan = () => {
  return getCountryCode() === 'jp';
};

export const getCountryCode = () => {
  return getCookie('CK_ISO_CODE') || 'us';
};

export const getLanguageCode = () => {
  return getCookie('CK_LANG_CODE') || 'en';
};

export const getPersistentUser = () => {
  return getCookie('store_jwt_persistence');
};
