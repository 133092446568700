import setSelectedValue from "./setSelectedValue";
import setActiveValue from "./setActiveValue";
import { calculateProductSelector } from "../../shared/calculateProductSelector";
import setActiveItem from "../product/setActiveItem";

const selectDifferentiator = (skuDifferentiatorId, specValue, popState) => {
  return (dispatch, getState) => {
    let state = getState();
    const differentiators = state.productSelector.differentiators;

    const { differentiators: differentiatorsClone, firstMatchingItem } = calculateProductSelector(skuDifferentiatorId, specValue, state.product?.product?.items, differentiators);

    if (!popState) {
      dispatch(setActiveItem(firstMatchingItem.catalogNumber, popState));
    }

    Object.keys(differentiators).forEach((id) => {
      const options = differentiators[id];

      options.forEach((option) => {
        const modifiedOption = differentiatorsClone[id].find((o) => o.value === option.value);

        if (modifiedOption != null) {
          if (option.active !== modifiedOption.active) {
            dispatch(setActiveValue(id, option.value, modifiedOption.active));
          }

          if (option.selected !== modifiedOption.selected && modifiedOption.selected === true) {
            dispatch(setSelectedValue(id, option.value));
          }
        }
      });
    });
  };
};

export default selectDifferentiator;