import apiRequest from "../../http/apiRequest";
import endpoints from "../../http/endpoints";
import httpOptions from "../../server/http/httpOptions";
import { PodActions } from "../actionTypes";
import PropTypes from 'prop-types';

export const fetchSkuLogoPods = (contentPath, name, index) => {
    const options = httpOptions.get({
        'Content-Type': 'text/plain'
    }
    );
    options.payload = {
        name,
        index: index.toString()
    };
    return apiRequest(PodActions.GetSkuLogoPods, endpoints.getContentPods(contentPath), options);
};

fetchSkuLogoPods.propTypes = {
    contentPath: PropTypes.string.isRequired
};

export default fetchSkuLogoPods;