import React from 'react';
import PropTypes from 'prop-types';

import { DIM_COMPONENTS_MEASURED } from '../../../constants/constants';

const OrderingAttributes = React.memo(({ skuDifferentiators, specifications }) => {
  if (skuDifferentiators?.map && specifications.length) {
    return skuDifferentiators.map((diff) => {
      const specValueObj = specifications.find((spec) => spec.id === diff.id);
      let specValue;
      if (specValueObj?.id === DIM_COMPONENTS_MEASURED) {
        specValue = specValueObj?.values?.join(', ');
      } else {
        specValue = specValueObj ? specValueObj.value : '';
      }
      return (
        <div
          className="pdp-ordering-attribute"
          key={`${specValueObj?.name}-${specValue}`}
        >
          <div className="pdp-ordering-attribute__label">
            {specValueObj?.name && `${specValueObj.name}:`}
          </div>
          <div
            className="pdp-ordering-attribute__value"
            dangerouslySetInnerHTML={{ __html: specValue }}
          ></div>
        </div>
      );
    });
  }
  return null;
});

OrderingAttributes.propTypes = {
  skuDifferentiators: PropTypes.array,
  specifications: PropTypes.array,
};

export default OrderingAttributes;
