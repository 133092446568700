import React from 'react';
import { useIntl } from 'react-intl';

import { CoreNotification } from '@komodo-design-system/komodo-components-react';

import "./UnknownProductTypeBanner.scss";

const UnknownProductTypeBanner = () => { 
  const intl = useIntl();

  const label = intl.formatMessage({ id: "commonError" }) + ": ";
  const message = intl.formatMessage({ id: "commonUnknownProductType" });

  return (
    <div className="unknown-product-type-banner">
      <CoreNotification label={label} message={message} variant="inline" state="error" nocloseicon />
    </div>
  );
};

export default UnknownProductTypeBanner;