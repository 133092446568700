import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./BundleListCards.scss";

const BundleListCards = ({
  rows,
  columns,
  catalogNumber,
  className,
  isMobileView,
}) => {
  const { data, isFetching } = rows;
  let image, quantity;

  const getBundleListCellValue = (column, row) => {
    return typeof column.value === "function" ? (
      column.value(row)
    ) : (
      <span>{column.value}</span>
    );
  };

  if (isMobileView) {
    image = columns.find((col) => col.id === "image");
    quantity = columns.find((col) => col.id === "quantity");
    columns = columns.filter(
      (col) => col.id !== "image" && col.id !== "quantity"
    );
  }
  if (data?.length) {
    return data.map((row, index) => {
      return (
        <div
          key={`bundle-item-${index}-${catalogNumber}`}
          className={classNames({
            "pdp-bundle-table__item-details": true,
            "pdp-bundle-table__item-details--mobile": isMobileView,
            "pdp-bundle-table__item-details--separated":
              data.length - 1 === index,
            "pdp-bundle-table__item-details--shadow": true,
            [className]: className,
          })}
        >
          {isMobileView && (
            <div className="pdp-bundle-table__item-details--image">
              {getBundleListCellValue(image, row)}
            </div>
          )}
          <div
            className={classNames({
              "pdp-bundle-table__item-details--artifacts": isMobileView,
            })}
          >
            {columns.map((col, index2) => {
              if (col.id !== "price") {
                return (
                  <div
                    key={`bundle-item-cell-${index2}`}
                    className={"pdp-bundle-table__item-detail"}
                  >
                    {getBundleListCellValue(col, row)}
                  </div>
                );
              }
              return (
                <React.Fragment key={`bundle-item-cell-${index2}`}>
                  {getBundleListCellValue(col, row)}
                </React.Fragment>
              );
            })}
          </div>
          {isMobileView && (
            <div className="pdp-bundle-table__item-details--quantity">
              {getBundleListCellValue(quantity, row)}
            </div>
          )}
        </div>
      );
    });
  } else if (!isFetching) {
    return (
      <div
        className={classNames({
          "pdp-bundle-table__item-detail": true,
          "pdp-bundle-table__item-details--separated": true,
          "pdp-bundle-table__item-details--shadow": true,
          "pdp-bundle-table__item-details--bordered": true,
        })}
      >
        {!isMobileView && (
          <div className="pdp-bundle-table__label pdp-bundle-table__label--fail-response"></div>
        )}
        <div className="pdp-bundle-table__value pdp-bundle-table__value--fail-response">
          <FormattedMessage id="bundleFailedResponse" />
        </div>
      </div>
    );
  }
  return null;
};

BundleListCards.propTypes = {
  rows: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
  }),
  catalogNumber: PropTypes.string,
  columns: PropTypes.array,
  className: PropTypes.string,
  isMobileView: PropTypes.bool,
};
export default BundleListCards;
