import { DocumentActions } from "../../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../../actions/status";

const initialProtocolsState = {
  protocolsList: [],
  protocolsListCount: 0,
  loading: false,
  fetched: false
};

const protocols = (state = initialProtocolsState, action) => {
  switch (action.type) {
    case `${DocumentActions.getProtocols}_${SUCCESS}`:
      return {
        ...state,
        protocolsList: action.response.searchResults?.protocolList ?? [],
        loading: false,
        fetched: true
      };
    case `${DocumentActions.getProtocols}_${PENDING}`:
      return {
        ...state,
        loading: true,
        fetched: false
      };
    case `${DocumentActions.getProtocols}_${ERROR}`:
      return {
        ...state,
        protocolsList: [],
        loading: false,
        fetched: false
      };
    default:
      return state;
  }
};

export default protocols;
