import apiRequest from "../../http/apiRequest";
import endpoints from "../../http/endpoints";
import httpOptions from "../../server/http/httpOptions";
import { FavoritesActions } from "../actionTypes";

export const addToFavorites = (payload) => {
    const url = endpoints.addToFavorites();
    const options = httpOptions.post({ ...payload });
    return apiRequest(FavoritesActions.AddToFavorites, url, options);
};

export const getFavoritesLists = (userId) => {
    const url = endpoints.getFavoritesLists(userId);
    const options = httpOptions.get();
    return (dispatch) => {
        return dispatch(apiRequest(FavoritesActions.GetFavoritesLists, url, options))
    }
}
