import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AlsoKnownAs from '../../AlsoKnownAs/AlsoKnownAs.js';
import { FormattedMessage } from 'react-intl';
import { useDispatch, shallowEqual } from 'react-redux';
import TableSkuDifferentiators from './TableSkuDifferentiators.js';
import clsx from 'clsx';
import setActiveItem from "../../../../../actions/product/setActiveItem.js";
import { analyticsProductRowClicks } from "../../../../../services/analyticsEvents.js";
import "./PdpTableProductSelector.scss";
import useActiveItem from '../../../../../hooks/useActiveItem.js';
import { PRODUCT_SIZE_ID } from '../../../../../constants/constants.js';
import selectDifferentiator from '../../../../../actions/productSelector/selectDifferentiator.js';
import { getScrollbarWidth } from '../../../../../utils/utils.js';

const PdpTableProductSelector = ({ productItems }) => {
  const dispatch = useDispatch();
  const prices = useSelector((state) => state.prices?.prices);
  const activeItem = useActiveItem();
  const itemSkuDiffertiators = activeItem?.skuDifferentiatorsV3;
  const [showLength, setShowLength] = useState(false);
  const [isCertificatesScrolled, setIsCerificatesScrolled] = useState(false);
  const [scrollbarThickness, setScrollbarThickness] = useState(0);


  const isMatches = productItems?.some((item, i, arr) => {
    return arr?.some((itemArr, index) => {
          return item?.catalogNumber === itemArr?.formattedCatalogNumber && i != index
    });
  });

  const activeSkuDifferentiators = itemSkuDiffertiators?.filter(skuDifferentiator => {
    const differentiatorValues = useSelector(state => state.productSelector.differentiators[skuDifferentiator?.id], shallowEqual) ?? [];
    const isUnitSizeAndValueOne = skuDifferentiator.id === PRODUCT_SIZE_ID && (differentiatorValues.length <= 1 || !isMatches);
    return !isUnitSizeAndValueOne ? true : false;
  });

  const onClickCatalogNumberRow = (event, catalogNumber) => {
    event.preventDefault();
    analyticsProductRowClicks(catalogNumber, productItems, prices);
    dispatch(setActiveItem(catalogNumber));

    const item = productItems.find((obj) => catalogNumber === obj.catalogNumber)

    const specifications = item.specifications.reduce((acc, obj) => {
      acc[obj.id] = obj.value
      return acc
    }, {});

    

    item.skuDifferentiatorsV3.forEach((skuDifferentiator) => {
      if (specifications[skuDifferentiator.id] === undefined) return

      dispatch(selectDifferentiator(skuDifferentiator.id, specifications[skuDifferentiator.id], true));
    })
  };

  const handleScroll = (event) => {
    setIsCerificatesScrolled(!!event.target.scrollLeft);
  }

  const handleOnMouseOver = () => {
    const tableElement = document.querySelector('.pdp-table-product-selector');
    tableElement.scrollHeight > (tableElement.clientHeight + 30) && setShowLength(true);
  }

  const onRowMouseOver = (e, index, productItemsLength) => {
    if (index === productItemsLength - 1) {
      setShowLength(false);
      e.stopPropagation();
    } else {
      setShowLength(true);
    }
  }

  useEffect(() => {
    setScrollbarThickness(getScrollbarWidth())
  }, [])

  return (
    <>
      <div className='pdp-table-wrapper' onMouseLeave={() => setShowLength(false)} onMouseOver={() => handleOnMouseOver()} onScroll={(event) => handleScroll(event)}>
        <div className="pdp-table-product-selector">
          <table>
            <thead>
              <tr>
                <th className={classNames({
                  'pdp-table-product-selector__show-scroll-border': isCertificatesScrolled,
                })}>
                  <FormattedMessage id="catalogNumberCaps"></FormattedMessage>
                </th>
                {activeSkuDifferentiators?.map((skuDifferentiator, index) => <th key={index}>{skuDifferentiator?.name}</th>)}
              </tr>
            </thead>
            <tbody>
              {productItems?.map((item, index) => {
                const isActive = activeItem.catalogNumber === item.catalogNumber;
                const showGoDirectCatalogNumber = item?.goDirectCatalogNumber && item?.goDirectCatalogNumber !== item?.catalogNumber;
                return (
                  <tr key={index} onMouseOver={e => onRowMouseOver(e, index, productItems.length)} className={clsx({ 'pdp-table-product-selector__active-row': isActive })} onClick={(event) => onClickCatalogNumberRow(event, item.catalogNumber)}>
                    <td className={classNames({
                      'pdp-table-product-selector__show-scroll-border': isCertificatesScrolled,
                    })}>
                      <span className="pdp-table-product-selector__catalog-number">
                        {item.catalogNumber}
                      </span>
                      <AlsoKnownAs
                        isActive={isActive}
                        catalogNumber={item.catalogNumber}
                        formattedCatalogNumber={item.formattedCatalogNumber}
                      />
                      {showGoDirectCatalogNumber && (
                        <div className={clsx(!isActive ? "pdp-table-product-selector__go-direct": "")}>
                          <FormattedMessage
                            id="goDirectCatalogNumber"
                            values={{
                              b: (chunks) => <b>{chunks}</b>,
                              goDirectCatalogNumber: item.goDirectCatalogNumber
                            }}
                          />
                        </div>
                      )}
                    </td>
                    <TableSkuDifferentiators skuDifferentiators={activeSkuDifferentiators} specifications={item?.specifications} />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {showLength && <div className='pdp-options' style={{ bottom: `${scrollbarThickness}px`, width: `calc(100% - ${scrollbarThickness}px)` }}> {productItems?.length} <FormattedMessage id="optionsText"/> </div>}
      </div>
    </>
  );
};

PdpTableProductSelector.propTypes = {
  productItems: PropTypes.array,
};

export default PdpTableProductSelector;
