import React, { useEffect, useRef, useState } from 'react';
import { CoreNavigationbar, CoreNavigationbarItem } from '@komodo-design-system/komodo-components-react';
import { useIntl } from 'react-intl';
import ProductDetailTabs from '../../../constants/productDetailTabs';
import { useDispatch, useSelector } from 'react-redux';
import setActiveTab from '../../../actions/ui/setActiveTab';
import scrollToTab from '../../../actions/ui/scrollToTab';
import useActiveTab from '../../../hooks/useActiveTab';
import useRelatedProducts from '../../../hooks/useRelatedProducts';
import useScrollToTab from '../../../hooks/useScrollToTab';
import { isClientRuntime } from '../../../utils/isClientRuntime';
import useActiveItem from '../../../hooks/useActiveItem';
import useVideos from '../../../hooks/useVideos';

import "./Nav.scss";

const AllowVerticalOffset = 30;

const Nav = () => {
  const intl = useIntl();
  const navRef = useRef();
  const dispatch = useDispatch();
  const activeItem = useActiveItem();
  const activeTab = useActiveTab();
  const scrollToTabId = useScrollToTab();

  const { count: citationCount, fetched: citationsFetched, visible: citationsVisible } = useSelector((state) => state.citations);
  const { count: faqCount } = useSelector((state) => state.faq);
  const figuresData = useSelector((state) => state.figures?.figuresData);
  const { isDataAvailable: recommendationsAvailable } = useSelector((state) => state.recommendations);
  const { fetched: relatedProductsFetched, count: relatedProductCount } = useRelatedProducts();
  const showRecommendations = recommendationsAvailable === true || (relatedProductsFetched === true && relatedProductCount > 0);
  const showSafetyAndHandling = !!activeItem?.hazardAndSafety?.length;
  const [headerHeight, setHeaderHeight] = useState(0);
  const addToCartSticky = useSelector((state) => state.ui.addToCartSticky);

  const { hasVideos } = useVideos();

  const setNavTop = () => {
    const headerCheckInterval = setInterval(() => {
      const header = document.querySelector('header');
      const b2borSCMSheader = document.getElementById('messagingBar');
      const stickyCart = document.querySelector('.pdp-add-to-cart-desktop');
      if (header != null) {
        clearInterval(headerCheckInterval);
        const rect = header.getBoundingClientRect();
        const stickyCartRect = stickyCart?.getBoundingClientRect();
        const b2borSCMSheaderRect = b2borSCMSheader?.getBoundingClientRect();
        setHeaderHeight(rect.height + (b2borSCMSheaderRect?.height ?? 0) + (stickyCartRect?.height ?? 0));
      }
    }, 50);
  };

  useEffect(() => {
    if (!isClientRuntime()) {
      return;
    }

    setNavTop();
    window.addEventListener('resize', setNavTop);

    return () => {
      window.removeEventListener('resize', setNavTop);
    };
  }, [addToCartSticky, activeItem]);

  useEffect(() => {
    if (!isClientRuntime()) {
      return;
    }

    window.addEventListener('scroll', updateActiveTab);

    return () => {
      window.removeEventListener('scroll', updateActiveTab);
    };
  }, [activeTab, headerHeight]);

  useEffect(() => {
    if (scrollToTabId != null) {
      handleNavClick(scrollToTabId);
    }
  }, [scrollToTabId]);

  const updateActiveTab = () => {
    const sections = document.querySelectorAll('.pdp-nav-container');

    const navHeight = navRef.current.getBoundingClientRect().height;
    const offset = headerHeight + navHeight + AllowVerticalOffset;

    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top;
      const sectionBottom = sectionTop + section.getBoundingClientRect().height;

      if (sectionTop < offset && sectionBottom > offset) {
        if (activeTab !== section.id) {
          dispatch(setActiveTab(section.id));
        }
      }
    });
  };

  const handleNavClick = (tabId) => {
    const contentSection = document.getElementById(tabId);
    if (contentSection) {
      const navHeight = navRef.current.getBoundingClientRect().height;

      const y = contentSection.getBoundingClientRect().top + window.scrollY - headerHeight - navHeight - AllowVerticalOffset + 1;
      window.scrollTo({ top: y, behavior: 'smooth' });

      dispatch(scrollToTab(null));
    }
  };

  return (
    <div ref={navRef} className="pdp-nav" style={{ top: `${headerHeight}px` }}>
      <CoreNavigationbar variant="horizontal">
        <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.ProductOverview} variant="horizontal" label={intl.formatMessage({ id: "productOverview" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.ProductOverview)} />

        {hasVideos && (
          <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.Videos} variant="horizontal" label={intl.formatMessage({ id: "videosTitle" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.Videos)} />)}

        {figuresData?.length > 0 && (
          <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.Figures} variant="horizontal" label={intl.formatMessage({ id: "figures" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.Figures)} />
        )}
        {showRecommendations === true && (
          <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.Recommendations} variant="horizontal" label={intl.formatMessage({ id: "recommendations" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.Recommendations)} />
        )}

        <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.Documents} variant="horizontal" label={intl.formatMessage({ id: "documents" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.Documents)} />

        {(faqCount > 0 && (
          <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.Faq} variant="horizontal" label={intl.formatMessage({ id: "faq" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.Faq)} />
        ))}

        {(citationCount > 0 || citationsVisible === true) && citationsFetched && (
          <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.CitationsReference} variant="horizontal" label={intl.formatMessage({ id: "citationsReference" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.CitationsReference)} />
        )}

        {showSafetyAndHandling === true && (
          <CoreNavigationbarItem slot="nav-item" selected={activeTab === ProductDetailTabs.SafetyAndHandling} variant="horizontal" label={intl.formatMessage({ id: "commonSafetyAndHandling" })} href="/" target="_top" onClick={() => handleNavClick(ProductDetailTabs.SafetyAndHandling)} />
        )}

      </CoreNavigationbar>
    </div>
  );
};

export default Nav;
