import { MediaFormulationsActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";

const initialMediaFormulations = {
  mediaFormulationsList: [],
  loading: false,
  fetched: false
};

const mediaFormulations = (state = initialMediaFormulations, action) => {
  switch (action.type) {
    case `${MediaFormulationsActions.GetMediaFormulations}_${SUCCESS}`:
      return {
        ...state,
        mediaFormulationsList: action.response.searchResults?.mediaFormulationList ?? [],
        loading: false,
        fetched: true
      };
    case `${MediaFormulationsActions.GetMediaFormulations}_${PENDING}`:
      return {
        ...state,
        loading: true,
        fetched: false
      };
    case `${MediaFormulationsActions.GetMediaFormulations}_${ERROR}`:
      return {
        ...state,
        mediaFormulationsList: [],
        loading: false,
        fetched: false
      };
    default:
      return state;
  }
};

export default mediaFormulations;
