import { DocumentActions } from '../../actions/actionTypes';
import { ERROR, SUCCESS } from '../../actions/status';

export const initialState = {
    data: null,
    error: null
};

const structureAndSpectra = (state = initialState, action) => {
    const { type, response, error } = action;

    switch (type) {
        case `${DocumentActions.GetStructureAndSpectra}_${SUCCESS}`:
            return {
                data: response.searchResults,
                error: null
            };
        case `${DocumentActions.GetStructureAndSpectra}_${ERROR}`:
            return {
                data: null,
                error
            };
        default: {
            return state;
        }
    }
};

export default structureAndSpectra;
