import React from 'react';
import { useIntl } from 'react-intl';

import { CoreNotification } from '@komodo-design-system/komodo-components-react';

import "./FetchErrorBanner.scss";

const FetchErrorBanner = () => { 
  const intl = useIntl();

  const label = intl.formatMessage({ id: "commonError" }) + ": ";
  const message = intl.formatMessage({ id: "commonFailedToFetch" });

  return (
    <div className="fetch-error-banner">
      <CoreNotification label={label} variant="inline" state="error" nocloseicon>
        <div className="fetch-error-banner-content" slot="content">
          {message}
        </div>
      </CoreNotification>
    </div>
  );
};

export default FetchErrorBanner;