import React from 'react';
import { useSelector } from 'react-redux';
import Chemicals from './Chemicals/Chemicals';
import Nav from './Nav/Nav';
import NavContainers from './NavContainers/NavContainers';
import MobileNav from './MobileNav/MobileNav';
import { Log } from '../../utils/log';
import { CHEMICAL_TEMPLATES } from '../../constants/constants';
import useDeviceType from '../../hooks/useDeviceType';
import DevicesTypes from '../../constants/deviceTypes';

import "./PdpDetails.scss";

const PdpDetails = () => {
  const { product } = useSelector((state) => state.product);
  const view = useSelector(state => state.productSelector.view);
  const productItems = product?.items;
  const isDesktop = useDeviceType(DevicesTypes.Desktop);

  Log("PdpDetails", { productItems, view });

  return (
    <div className="pdp-details">
      {CHEMICAL_TEMPLATES.includes(productItems?.[0].productTemplate) && <Chemicals />}

      {isDesktop && <>
        <Nav />
        <NavContainers />
      </>}
      {!isDesktop && <>
        <MobileNav />
      </>}
    </div>
  );
};

export default PdpDetails;