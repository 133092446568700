import React, { Suspense, lazy, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import ImageSlider from './ImageSlider/ImageSlider';
import ItemImage from './ItemImage/ItemImage';
import useClientApp from '../../../../hooks/useClientApp';

import "./ImagesPanel.scss";

const DesktopImagesPanel = ({ isFamily, images, productTitle }) => {
  const { hydrated } = useClientApp();
  const [modalStartImageIdx, setModalStartImageIdx] = useState(null);
  const [activeImageIdx, setActiveImageIdx] = useState(0);

  const DesktopImageModal = hydrated ? lazy(() => import('./DesktopImageModal/DesktopImageModal')) : null;

  const moreThanOneImage = images.length > 1;

  const handleImageClick = useCallback(() => {
    setModalStartImageIdx(activeImageIdx);
  }, [activeImageIdx]);

  const handleMouseEnter = (idx) => {
    setActiveImageIdx(idx);
  };

  const handleModalClose = () => {
    setModalStartImageIdx(null);
  };

  return (
    <div className={clsx({ "pdp-images-panel": true, "has-slider": moreThanOneImage })}>
      <ItemImage isFamily={isFamily} imagePath={images[activeImageIdx]?.path} onImageClick={handleImageClick} />
      {moreThanOneImage && <ImageSlider images={images} onImageClick={handleImageClick} onMouseEnter={handleMouseEnter} />}

      {hydrated && (
        <Suspense>
          {modalStartImageIdx != null && <DesktopImageModal isFamily={isFamily} startImageIdx={modalStartImageIdx} images={images} productTitle={productTitle} onModalClose={handleModalClose} />}
        </Suspense>
      )}

    </div>
  )
};

DesktopImagesPanel.propTypes = {
  isFamily: PropTypes.bool,
  images: PropTypes.array.isRequired,
  productTitle: PropTypes.string.isRequired
};

export default DesktopImagesPanel;
