import React, { useEffect, useState } from 'react';
import { CoreNotification } from '@komodo-design-system/komodo-components-react';
import { useSelector } from 'react-redux';
import './Alert.scss';

const Alert = () => {
  const alert = useSelector((state) => state.ui.alert);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  
  useEffect(() => {
    if(alert) {
      setIsAlertVisible(true);
      if(alert.timer) {
        setTimeout(() => {
          setIsAlertVisible(false);
        }, alert.timer);
      }
    }
    
  }, [alert]);

  const handleClose = () => {
    setIsAlertVisible(false);
  };
  
  if(alert) {
    return (
      <div className='pdp-alert-wrapper'>
        <div className="pdp-alert">
          {alert && isAlertVisible && (
            <CoreNotification label={alert.label} message={alert.message} variant={alert.variant} state={alert.state} timer={alert.timer} onNotificationClosed={handleClose}>
              {alert.description}
            </CoreNotification>
          )}
        </div>
      </div>
      );
  }
return <></>;

};

export default Alert;
