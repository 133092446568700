import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BundleListRows = ({ catalogNumber, rows, columns, className }) => {
  const { data, isFetching } = rows;

  const getBundleListCellValue = (column, row) => {
    return typeof column.value === 'function' ? (
      column.value(row)
    ) : (
      <span>{column.value}</span>
    );
  };

  const getFailResponse = () => {
    return columns.map((col, idx) =>
      col.id === 'price' ? (
        <td key={`no-response-${idx}`} colSpan={col.colSpan ?? 1}>
          {' '}
          <FormattedMessage id={'bundleFailedResponse'} />
        </td>
      ) : (
        <td key={`no-response-${idx}`} colSpan={col.colSpan ?? 1}></td>
      )
    );
  };

  if (data?.length) {
    return data.map((row, index) => (
      <tr
        className={classNames({
          'pdp-bundle-row': true,
          [className]: className
        })}
        key={`bundle-item-${index}-${catalogNumber}`}
      >
        {columns.map((col, index2) => {
          return (
            <td
              key={`bundle-item-cell-${index2}`}
              className={classNames({
                'pdp-bundle-cell': true,
                'pdp-bundle-cell--border-no': data.length - 1 !== index
              })}
              colSpan={col.colSpan ?? 1}
            >
              {getBundleListCellValue(col, row)}
            </td>
          );
        })}
      </tr>
    ));
  } else if (!isFetching) {
    return <tr>{getFailResponse()}</tr>;
  }

  return null;
};

BundleListRows.propTypes = {
  catalogNumber: PropTypes.string.isRequired,
  rows: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array
  }),
  columns: PropTypes.array,
  className: PropTypes.string
};

export default BundleListRows;
