import React from 'react';
import staticImage from '../../../../utils/staticImage';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import "./NoImage.scss";

const NoImage = ({ smallSize }) => {
  const naImagePath = staticImage("image-na.png");

  return (
    <img
      className={clsx("pdp-no-image", smallSize ? 'pdp-no-image--small-size' : '')}
      alt={"Product Images"}
      src={naImagePath}
      decoding="async"
    />
  );
};

NoImage.propTypes = {
  smallSize: PropTypes.bool,
};

export default NoImage;