import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";
import { getLanguageCode } from './utils/cookies';
import { Route, Switch } from 'react-router';

import Main from "./components/Main/Main";
import { useSelector } from "react-redux";
import DeviceTypeProvider from "./providers/DeviceTypeProvider/DeviceTypeProvider";
import getDictionary from "./actions/dictionary/getDictionary";
import useLoggedInUser from "./hooks/useLoggedInUser";
import getUser from "./actions/user/getUser";
import useClientApp from "./hooks/useClientApp";
import setHydrated from "./actions/ui/setHydrated";
import MobileNavContainers from "./components/PdpDetails/NavContainers/MobileNavContainers";

const App = () => {
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state.dictionary);
  const user = useLoggedInUser();
  const { hasWindow, hydrated } = useClientApp();

  useEffect(() => {
    if (dictionary.locale === '' || !dictionary.fetched) {
      const langCode = getLanguageCode();
      dispatch(getDictionary(langCode));
    }
  }, [dictionary.locale, dictionary.fetched, dispatch]);

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (hasWindow && hydrated === false) {
      dispatch(setHydrated());
    }
  }, [hasWindow, hydrated]);

  return (
    <IntlProvider
      key={dictionary.locale}
      locale={dictionary.locale}
      messages={dictionary.messages}
    >
      <Switch>
        <Route exact path="/:productId/tabs">
        <DeviceTypeProvider>
          <MobileNavContainers />
        </DeviceTypeProvider>
        </Route>
        <Route path="/">
          <DeviceTypeProvider>
            <Main />
          </DeviceTypeProvider>
        </Route>
      </Switch>
    </IntlProvider>
  );
};

export default App;