import { FavoritesActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";
const initialState = {
  favoritesLists: [],
  favoritesError: null,
  isFetching: false
};

const favorites = (state = initialState, action) => {
  switch (action.type) {
    case `${FavoritesActions.GetFavoritesLists}_${SUCCESS}`:
        return {
            ...state,
            favoritesLists: action.response.sharedLists || [],
            favoritesError: null
        };
    case `${FavoritesActions.GetFavoritesLists}_${ERROR}`:
      return {
          ...state,
          favorites: [],
          favoritesError: action.error
      };
    case `${FavoritesActions.AddToFavorites}_${PENDING}`:
      return {
          ...state,
          isFetching: true
      };
    case `${FavoritesActions.AddToFavorites}_${SUCCESS}`:
      return {
          ...state,
          isFetching: false
      };
    default:
      return state;
  }
};

export default favorites;
