import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { CoreButton } from '@komodo-design-system/komodo-components-react';
import { getCountryCode, getLanguageCode } from '../../utils/cookies';
import ContactUs from '../ContactUs/ContactUs';
import { PRICE_STATUS } from '../../constants/constants';

const PriceLearnOrContactUs = ({item, priceAccess}) => {
  const { contactUsPopUpEnabled } = useSelector((state) => state.product?.product?.presentationAttributes) ?? false;

  let buttonOrPopUp = '';
  if(contactUsPopUpEnabled && priceAccess?.status === PRICE_STATUS.CONTACT_US) {
    buttonOrPopUp = (
      <div className='contact-us-link'>
        <ContactUs sku={item?.catalogNumber} isButton={true} customClassName="pdp-commerce-box__contact_us"/>
      </div>
    )
  } else {
    buttonOrPopUp = (
      <div className='contact-us-button'>
        <CoreButton
          type="button"
          variant="primary"
          size="medium"
          fullspace
          onClick={() => handleClickContactUs(item)}
        >
        <span slot="content" dangerouslySetInnerHTML={{ __html: priceAccess?.pricingAccessMessage}}></span>
      </CoreButton>
      </div>
    )
  }
  const handleClickContactUs = (item) => {
    return window.open(`/${getCountryCode()}/${getLanguageCode()}/home/technical-resources/contact-us.${item?.catalogNumber}.html?supportType=SL`,'_self');
  }

  return (
    <>{buttonOrPopUp}</>
  );
};

PriceLearnOrContactUs.propTypes = {
  item: PropTypes.object,
  priceAccess: PropTypes.object
};

export default PriceLearnOrContactUs;
