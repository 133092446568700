import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import SkuDifferentiator from './SkuDifferentiator/SkuDifferentiator';
import useDeviceType from '../../../../../hooks/useDeviceType';
import useProduct from '../../../../../hooks/useProduct';
import DevicesTypes from '../../../../../constants/deviceTypes';
import MobileSkuDifferentiator from './MobileSkuDifferentiator/MobileSkuDifferentiator';

import "./PdpButtonProductSelector.scss";

const PdpButtonProductSelector = () => {
  const isMobile = useDeviceType(DevicesTypes.Mobile);

  const product = useProduct();
  const differentiators = useSelector(state => state.productSelector.differentiators, shallowEqual);

  if (!differentiators || differentiators.length === 0){
    return null;
  }

  return (
    <div className="pdp-button-product-selector">
      {
        product.skuDifferentiatorsV3.map((differentiator, index) => {
          return isMobile === true ? (
              <MobileSkuDifferentiator key={index} skuDifferentiator={differentiator} isFirst={index === 0} />
            ): (
              <SkuDifferentiator key={index} skuDifferentiator={differentiator} isFirst={index === 0} />
            );
        })
      }
    </div>
  );
};

export default PdpButtonProductSelector;
