export const SCROLL_TO_ELEMENT_OFFSET = 6; // buffer to avoid landing on exact text

const scrollToElement = (elementId) => {
  const header = document.querySelector('.pdp-nav');
  const stickyCart = document.querySelector('.pdp-add-to-cart-desktop');
  const rect = header.getBoundingClientRect();
  const stickyCartRect = stickyCart?.getBoundingClientRect() ;

  const navHeight = rect.height + SCROLL_TO_ELEMENT_OFFSET;

  const element = document.getElementById(elementId);
  
  if (element) {
    window.scrollTo({
      top: element.offsetTop - navHeight - (stickyCartRect?.height ?? 0),
      behavior: "smooth"
    });
  }
};

export default scrollToElement;
