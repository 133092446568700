import React from 'react';
import PropTypes from 'prop-types';

import { AVAILABILITY_DATE, LATAM_COUNTRIES } from '../../../../constants/constants';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isChina, getCountryCode } from '../../../../utils/cookies';
import useAvailabilityTooltipPosition from './useAvailabilityTooltipPosition';


const OutOfStock = ({ availability }) => {
  const intl = useIntl();
  const tooltipPosition = useAvailabilityTooltipPosition();

  let estimatedMsg = LATAM_COUNTRIES.includes?.(getCountryCode())
    ? 'estimatedDeliveryDate'
    : 'estimatedAvailabilityDate';
  return (
    <span className='pdp-availability__out-of-stock'>
      <FormattedMessage id={estimatedMsg} ></FormattedMessage>&nbsp;
      {availability.availabilityDate === AVAILABILITY_DATE.PENDING ? (
        <span className='pdp-availability__out-of-stock--pending'>
          <span> <FormattedMessage id="pending"></FormattedMessage> &nbsp;</span>
          {!isChina() && (
            <span>
              <CoreTooltip
                label={intl.formatMessage({ id: "pendingTooltip" })}
                alignment={tooltipPosition.alignment}
                placement={tooltipPosition.placement}
              >
                <span className="icon-16-info-mono pdp-availability__out-of-stock--pending-tooltip"></span>
              </CoreTooltip>
            </span>
          )
          }

        </span>
      ) : (
        <>
          {availability.availabilityDate && <span>{availability.availabilityDate}</span>}
          {availability?.displayAvailabilityMsg && availability?.availabilityMsg ? <div className='pdp-availability__out-of-stock--msg'>{availability?.availabilityMsg}</div> : ''}
        </>
      )
      }

    </span>
  );
};

OutOfStock.propTypes = {
  availability: PropTypes.object
};

export default OutOfStock;
