import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import imageUrlBuilder from '../../../../../../utils/imageUrlBuilder';
import staticImage from '../../../../../../utils/staticImage';

import "./ItemImageThumbnail.scss";
import { wait } from '../../../../../../utils/wait';
import clsx from 'clsx';

const imageNa = staticImage("image-na.png");

const ItemImageThumbnail = ({ className, image, imageIdx, onClick, onMouseEnter }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    (async () => {
      await wait(imageIdx > 3 ? 3000 + imageIdx * 50 : 0);
      setImageUrl(imageUrlBuilder(image.path) + "-150.jpg");
    })();
  }, [imageIdx]);

  const handleImageLoadError = (e) => {
    e.target.src = imageNa;
  };

  return (
    <div className={clsx("pdp-item-image-thumbnail", className)} onClick={() => !!onClick && onClick()}>
      {!!imageUrl && <img src={imageUrl} alt="Product" onError={handleImageLoadError} decoding={imageIdx > 3 ? "async" : "auto"} onMouseEnter={() => {
        onMouseEnter?.(imageIdx);
      }} />}
    </div>
  );
};

ItemImageThumbnail.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object.isRequired,
  imageIdx: PropTypes.number,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func
};

export default ItemImageThumbnail;