import { VideoActions } from '../actions/actionTypes';
import { ERROR, SUCCESS } from '../actions/status';

export const initialState = {
    videos: null,
    videosError: null
};

const videoContent = (state = initialState, action) => {
    const { type, response, error } = action;

    switch (type) {
        case `${VideoActions.GetVideos}_${SUCCESS}`:
            return {
                ...state,
                videos: response,
                videosError: null
            };
        case `${VideoActions.GetVideos}_${ERROR}`:
            return {
                ...state,
                videos: null,
                videosError: error
            };
        default: {
            return state;
        }
    }
};

export default videoContent;
