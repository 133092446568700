import React from 'react';
import PropTypes from 'prop-types';

import { MAX_ADD_TO_CART_QTY, PRICE_STATUS } from '../../../../constants/constants';
import setQuantity from '../../../../actions/quantity/setQuantity';
import { useDispatch } from 'react-redux';
import './Quantity.scss';
import { CoreQuantityselector } from '@komodo-design-system/komodo-components-react';

const Quantity = ({ priceAccessStatus, quantity }) => {
  const dispatch = useDispatch();
  const isOrderableorNoPrice = priceAccessStatus === PRICE_STATUS.ORDERABLE || priceAccessStatus === PRICE_STATUS.NO_PRICE;

  const handleQuantityChange = (e) => {
    const value = e.detail;
    dispatch(setQuantity(value));
  };

  if (isOrderableorNoPrice) {
    return (
      <div className='pdp-quantity'>
        <CoreQuantityselector inputwidth={"52px"} value={quantity} max={MAX_ADD_TO_CART_QTY} onValueChange={handleQuantityChange} />
      </div>
    );
  }

  return;
};

Quantity.propTypes = {
  priceAccessStatus: PropTypes.string,
  quantity: PropTypes.number
};

export default Quantity;
