import { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useIntl } from 'react-intl';
import getProductSchema, { PRODUCT_TYPE } from './getProductSchema';
import getFAQSchema, { FAQPAGE_TYPE } from './getFAQSchema';
import getBreadCrumbsSchema, {
  BREADCRUMBLIST_TYPE
} from './getBreadCrumbsSchema';
import useActiveItem from '../../hooks/useActiveItem';

const clearPreviousSchemaElement = (type) => {
  if (document?.head && document?.querySelectorAll) {
    const domScripts = document.querySelectorAll(
      'script[type="application/ld+json"]'
    );
    if (domScripts) {
      for (let i = 0; i < domScripts.length; i++) {
        const content = domScripts[i].textContent;
        if (
          content &&
          content.length &&
          content.includes(`"@type":"${type}"`)
        ) {
          domScripts[i].parentNode.removeChild(domScripts[i]);
        }
      }
    }
  }
};

const createStructuredDataDOM = (schema, type) => {
  if (document?.createElement && document?.head && document?.querySelectorAll) {
    clearPreviousSchemaElement(type);
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    script.textContent = JSON.stringify(schema);
    document.head.appendChild(script);
  }
};

const SEO = () => {
  const intl = useIntl();
  const activeItem = useActiveItem();

  const data = useSelector((state) => {
    return {
      availability: state.availability,
      product: state.product,
      prices: state.prices,
      faq: state.faq
    };
  }, shallowEqual);

  useEffect(() => {
    if (!activeItem.error && data.faq.fetched && activeItem) {
      const faqSchema = getFAQSchema({ faq: data.faq });

      if (faqSchema) {
        createStructuredDataDOM(faqSchema, FAQPAGE_TYPE);
      }
    }
  }, [data, activeItem]);

  useEffect(() => {
    if (
      !activeItem.error &&
      data.availability.fetched &&
      data.product.fetched &&
      data.prices.fetched &&
      activeItem
    ) {
      const productSchema = getProductSchema({
        prices: data.prices,
        availability: data.availability,
        selectedProduct: activeItem,
        xHostHeader: window?.location?.host
      });
      createStructuredDataDOM(productSchema, PRODUCT_TYPE);
    }
  }, [data, activeItem]);

  useEffect(() => {
    if (
      !activeItem.error &&
      data.product.fetched &&
      activeItem
    ) {
      const breadCrumbsSchema = getBreadCrumbsSchema({ selectedProduct: activeItem, intl, xHostHeader: window?.location?.host });
      createStructuredDataDOM(breadCrumbsSchema, BREADCRUMBLIST_TYPE);
    }
  }, [data, activeItem, intl]);

  return null;
};

export default SEO;
