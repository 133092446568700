import React, { memo } from 'react';
import './Divider.scss';

const Divider = memo(() => {
    return (
        <div className='pdp-divider'></div>
    )
});

export default Divider;

