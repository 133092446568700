const Breakpoints = Object.freeze({
  mobileMin: 0,
  mobileMax: 767,
  tabletMin: 768,
  tabletMax: 991,
  desktopMin: 992,
  desktopMax: 1199,
  largeDesktopMin: 1200,
  largeDesktopMax: 99999
});

export default Breakpoints;