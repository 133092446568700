import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import DevicesTypes from '../../constants/deviceTypes';
import useDeviceType from '../../hooks/useDeviceType';
import { useEffect } from 'react';
import './LoadOffers.scss';

const TIMEOUT = 3000;

let offersLoadCalled = false;

const LoadOffers = ({ skus, setOffersLoaded }) => {
    const ref = useRef(null);
    const isMobile = useDeviceType(DevicesTypes.Mobile);

    useEffect(() => {
        let timeoutId = null;

        if (isMobile) {
            offersLoadCalled = false;
        }

        if (skus?.length && !offersLoadCalled && !!ref && !!ref.current) {
            const divId = 'tf-pdp-1';

            if (!document.querySelector(`#${divId}`)) {
                const div = document.createElement('div');
                div.setAttribute('class', 'dynamic-offer');
                div.setAttribute('id', divId);
                ref.current.appendChild(div);
            }

            timeoutId = window.setTimeout(() => {
                if (offersLoadCalled) {
                    window.clearTimeout(timeoutId);
                } else if (window?.Offers?.loadPdpOffers) {
                    if (ref.current) {
                        offersLoadCalled = true;
                        setOffersLoaded(true);
                        window.Offers?.loadPdpOffers?.({ skus, placementType: 'pdp' });
                    }
                }
            }, TIMEOUT);
        }

        return () => {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
        }
    }, [isMobile, skus])

    return <div className='offers-wrapper' ref={ref} />
}

LoadOffers.propTypes = {
    skus: PropTypes.arrayOf(PropTypes.string).isRequired,
    setOffersLoaded: PropTypes.func
};

export default LoadOffers;