import React from 'react';
import { useIntl } from 'react-intl';
import ChemicalsIdentifiers from './ChemicalsIdentifier/ChemicalsIdentifier';
import Specifications from '../NavContainers/ProductOverview/Specifications/Specifications';
import { MAX_SPECIFICATIONS_CHEMICALS_ROW, specificationsUrl } from '../../../constants/constants';
import './Chemicals.scss';
import useActiveItem from '../../../hooks/useActiveItem';

const Chemicals = () => {
  const intl = useIntl();

  const activeItem = useActiveItem();

  const rootSku = activeItem?.rootSku;
  const catalogNumber = activeItem?.catalogNumber;

  return (
    <div className='pdp-chemicals'>
      <ChemicalsIdentifiers identifiers={activeItem?.identifiers} />

      <Specifications specifications={activeItem?.chemicalSpecifications}
        title={intl.formatMessage({ id: 'specifications' })}
        maxRow={MAX_SPECIFICATIONS_CHEMICALS_ROW}
        link={specificationsUrl(rootSku, catalogNumber)}
        addOffset
        showViewMore
        className='col-lg-5 col-md-12'
      />
    </div>
  );
};

export default Chemicals;