import React from "react";
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import "./SafetyAndHandlingJumpLink.scss";
import {
  SafetyAndHandlingImages,
  PICTOGRAMS_DISPLAY_NAME,
  PICTOGRAMS_PREFIX,
  LABEL_ELEMENTS_CLASS,
  SAFETY_HANDLING
} from "../../../../../constants/constants";

import scrollToElement from "../../../../../utils/scrollToElement";
import useDeviceType from '../../../../../hooks/useDeviceType';
import DevicesTypes from '../../../../../constants/deviceTypes';
import { calcTabindex, getTabsList } from '../../../../PdpDetails/MobileNav/MobileNav';
import useActiveItem from "../../../../../hooks/useActiveItem";

const FlammableIcon = "static/images/hazard-flammable-icon.svg";
const HazardIcon = "static/images/icon-hazard.svg";
const WarningIcon = "static/images/hazard-harmful-icon.svg";
const BioHazardousIcon = "static/images/biohazardous_2.svg";
const ExplodingBombIcon = "static/images/ghs01_exploding_bomb.svg";
const FlameOverCircleIcon = "static/images/ghs03_flame_over_circle.svg";
const GasCylinderIcon = "static/images/ghs04_gas_cylinder.svg";
const CorrosionIcon = "static/images/ghs05_corrosion.svg";
const SkullAndCrossbonesIcon = "static/images/ghs06_skull_and_crossbones.svg";
const EnvironmentIcon = "static/images/ghs09_environment.svg";

const SafetyAndHandlingJumpLink = ({ hasLink = true }) => {
  const history = useHistory();
  const activeItem = useActiveItem();
  const isDesktop = useDeviceType(DevicesTypes.Desktop);
  const { product } = useSelector((state) => state.product);
  const productItem = product?.items?.[0];
  const tabsList = getTabsList();

  const onMobileClick = (id) => {
    const defIndex = calcTabindex(tabsList, id);
    document
      .getElementsByTagName('footer')?.[0]
      ?.style?.setProperty('display', 'none');
    setTimeout(() => {
      document
        .getElementsByClassName('child-route')?.[0]
        ?.style?.setProperty('position', 'static');
      document
        .getElementsByTagName('footer')?.[0]
        ?.style?.setProperty('display', 'block');
    }, 500);
    history.push(`/${activeItem.catalogNumber}/tabs?defaultTab=${defIndex}`);
  };

  const handleHazardAndSafetyClick = () => {
    isDesktop ? scrollToElement(SAFETY_HANDLING) : onMobileClick(SAFETY_HANDLING);
  };

  const extractPictograms = (selectedProduct) => {
    const pictograms = [];
    if (
      selectedProduct?.hazardAndSafety &&
      selectedProduct?.hazardAndSafety.map
    ) {
      const labelElementsItem = selectedProduct?.hazardAndSafety.find(
        (item) => item?.class === LABEL_ELEMENTS_CLASS
      );
      if (labelElementsItem) {
        labelElementsItem.values?.reduce?.((accumulator, current) => {
          if (
            current?.displayName === PICTOGRAMS_DISPLAY_NAME &&
            !!current?.value &&
            !current.value.toLowerCase().includes("blank")
          ) {
            const pictogramClassName = current.value
              .replace(PICTOGRAMS_PREFIX, "")
              .replace(/\s/g, "_")
              .toLowerCase();
            accumulator.push(pictogramClassName.split(".")[0]);
          }
          return accumulator;
        }, pictograms);
      }
    }
    return pictograms.length ? pictograms : null;
  };

  const pictograms = extractPictograms(productItem);
  
  const getJumplinkIcon = (pictogram) => {
    switch (pictogram) {
      case SafetyAndHandlingImages.flammable:
        return FlammableIcon;
      case SafetyAndHandlingImages.hazard:
        return HazardIcon;
      case SafetyAndHandlingImages.warning:
        return WarningIcon;
      case SafetyAndHandlingImages.biohazardous:
        return BioHazardousIcon;
      case SafetyAndHandlingImages.explodingBomb:
        return ExplodingBombIcon;
      case SafetyAndHandlingImages.flameOvercircle:
        return FlameOverCircleIcon;
      case SafetyAndHandlingImages.gasCylinder:
        return GasCylinderIcon;
      case SafetyAndHandlingImages.corrosion:
        return CorrosionIcon;
      case SafetyAndHandlingImages.skullAndCrossbones:
        return SkullAndCrossbonesIcon;
      case SafetyAndHandlingImages.environment:
        return EnvironmentIcon;      
      default:
        return null;
    }
  };

  if (pictograms) {
    return (
      <div className="safety-and-handling-jumplinks-container">
        <div
          className="safety-and-handling-jumplink"
          onClick={handleHazardAndSafetyClick}
        >
          {pictograms.map((pictogram) => (
            <div
              className={classNames({
                "safety-and-handling-icon": true,
                "safety-and-handling-icon--nopointer": !hasLink,
              })}
            >
              <img className="safety-and-handling-image" src={getJumplinkIcon(pictogram)}></img>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

SafetyAndHandlingJumpLink.propTypes = {
  hasLink: PropTypes.bool
};

export default SafetyAndHandlingJumpLink;
