import React from "react";
import { hydrateRoot } from 'react-dom/client';
import App from "./App";

import "./index.scss";
import getMiddlewares from "./middlewares";
import configureStore from "./store/configureStore";
import { BrowserRouter as Router } from 'react-router-dom';
import { getUrlReplacementString } from "./utils/utils";
  
window.process = {
  env: window._ENV_
};
delete window._ENV_;

const preloadedState = window._PRELOADED_STATE_;
delete window._PRELOADED_STATE_;

const storeConfig = configureStore(getMiddlewares(), preloadedState);
const rootNode = document.getElementById('root');

hydrateRoot(rootNode,
  <storeConfig.Provider store={storeConfig.store}>
    <Router basename={getUrlReplacementString()}>
      <App />
    </Router>
  </storeConfig.Provider>
);

const webComponentPromises = [
  window.customElements.whenDefined('core-breadcrumb'),
  window.customElements.whenDefined('core-card'),
  window.customElements.whenDefined('core-chip'),
  window.customElements.whenDefined('core-navigationbar'),
  window.customElements.whenDefined('core-tabs'),
];

Promise.all(webComponentPromises).then(() => {
  document.getElementById('root').style.visibility = 'visible';
  !!window.showGlobalFooter && window.showGlobalFooter();  
});