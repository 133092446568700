import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { CoreContextmenu, CoreContextmenuItem } from '@komodo-design-system/komodo-components-react';
import { CopyLinksCloseTimeoutInMs, CopyLinksTypes } from './CopyLinks.Constants';

import './CopyLinksContextMenu.scss';
import { isClientRuntime } from '../../../../utils/isClientRuntime';

let CopyLinksCloseTimeout = null;

const CopyLinksContextMenu = ({ open, top, left, onClick, onClose }) => {
  if (!isClientRuntime()) {
    return null;
  }

  const intl = useIntl();

  const [active, setActive] = useState(null);

  useEffect(() => {
    if (!open) {
      setActive(null);
      document.removeEventListener('click', onOutsideClick);
    }
    else {
      document.addEventListener('click', onOutsideClick);
    }
  }, [open]);

  const handleClick = (copyLinksType) => {
    if (CopyLinksCloseTimeout) {
      clearTimeout(CopyLinksCloseTimeout);
      CopyLinksCloseTimeout = null;
    }

    setActive(copyLinksType);
    onClick?.(copyLinksType);

    CopyLinksCloseTimeout = setTimeout(() => {
      CopyLinksCloseTimeout = null;
      onClose?.();
    }, CopyLinksCloseTimeoutInMs);
  };

  const handleOpenChange = (e) => {
    if (e.detail === false) {
      CopyLinksCloseTimeout = null;
      onClose?.();
    }
  };

  const onOutsideClick = (e) => {
    const isInside = !!e.target.closest(".pdp-copy-links-context-menu");
    if (isInside === false) {
      onClose?.();
    }
  };

  const options = [
    { id: CopyLinksTypes.ProductName, label: intl.formatMessage({ id: "copyProductName" }), copiedLabel: intl.formatMessage({ id: "copiedProductName" }) },
    { id: CopyLinksTypes.CatalogNumber, label: intl.formatMessage({ id: "copyCatalogNumber" }), copiedLabel: intl.formatMessage({ id: "copiedCatalogNumber" }) },
    { id: CopyLinksTypes.CatalogNumberAndProductName, label: intl.formatMessage({ id: "copyCatalogNumberAndProductName" }), copiedLabel: intl.formatMessage({ id: "copiedCatalogNumberAndProductName" }) },
    { id: CopyLinksTypes.Link, label: intl.formatMessage({ id: "copyLink" }), defaultIcon: "icon-16-link-mono", copiedLabel: intl.formatMessage({ id: "copiedLink" }) }
  ];

  const hideContextMenu = open === false ? { display: "none" } : {};

  return (
    <div className="pdp-copy-links-context-menu" style={{ "top": (top ?? 0) + "px", "left": (left ?? 0) + "px", ...hideContextMenu }}>

      <CoreContextmenu open={open} onOpenChange={handleOpenChange}>
        {options.map((option) => {
          const copied = active === option.id;

          return (
            <CoreContextmenuItem
              className='no-active'
              key={option.id}
              onClick={() => handleClick(option.id)}>
              <div slot="content" className={clsx({ "context-menu-item-content": true, "active": copied })}>
                <div className="item-icon">
                  {copied && <span className="icon-16-success-bold" />}
                  {!copied && option.defaultIcon && <span className={option.defaultIcon} />}
                </div>
                <div className="item-label">{copied ? option.copiedLabel : option.label}</div>
              </div>
            </CoreContextmenuItem>
          );
        })}

      </CoreContextmenu>

    </div>
  );
};

CopyLinksContextMenu.propTypes = {
  open: PropTypes.bool,
  top: PropTypes.number,
  left: PropTypes.number,
  onClick: PropTypes.func,
  onClose: PropTypes.func
};

export default CopyLinksContextMenu;
