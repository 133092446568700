const ProductDetailTabs = Object.freeze({
  ProductOverview: "productOverview",
  Specifications: "specifications",
  Figures: "figures",
  Documents: "documents",
  Faq: "faq",
  CitationsReference: "citationsReference",
  Recommendations: "recommendations",
  OtherRecommendations: "otherRecommendations",
  SafetyAndHandling: "safetyAndHandling",
  Videos: "videos"
});

export default ProductDetailTabs;