import { FiguresActions } from "../actions/actionTypes";
import { SUCCESS, ERROR } from "../actions/status";

export const figuresInitialState = {
    figuresData: null,
    fetched: false
};

const figures = (state = figuresInitialState, action) => {
    const { type, response } = action;

    switch (type) {
        case `${FiguresActions.GetFigures}_${SUCCESS}`:
            return {
                ...state,
                figuresData: response?.figures?.gallery,
                fetched: true
            };
        case `${FiguresActions.GetFigures}_${ERROR}`:
            return {
                ...state,
                figuresData: null,
                fetched: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default figures;