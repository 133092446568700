import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

const InStock = ({ availability }) => {
  return (
    <span className='pdp-availability__in-stock'>
      {availability?.displayAvailableQuantity &&
        availability?.availableQuantity ?
        <span className='pdp-availability__in-stock-qty'>{availability.availableQuantity}</span> :
        ''
      }
      <FormattedMessage id="inStock"></FormattedMessage>
      {availability?.displayAvailabilityMsg &&
        availability.availabilityMsg ?
        <div className='pdp-availability__in-stock-msg'>{availability?.availabilityMsg}</div> :
        ''
      }
    </span>
  );
};

InStock.propTypes = {
  availability: PropTypes.object,
};

export default InStock;
