import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useDeviceType from '../../../hooks/useDeviceType';
import DevicesTypes from '../../../constants/deviceTypes';

const LegacyAccountPrice = ({ itemPrice, isUserLoggedIn, stickyPrice }) => {
  let yourPricelabel = '';
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  if (isMobile && stickyPrice) {
    yourPricelabel = 'yourPriceLabelCurrencyColon';
  }
  else {
    yourPricelabel = 'yourPriceLabel';
  }
  const yourPrice = itemPrice?.formattedPrice?.yourPrice;

  if (isUserLoggedIn) {
    return (
      <div className="pdp-legacy-your-price">
        {yourPrice && (
          <>
            <span className='pdp-legacy-your-price-label'>
              <FormattedMessage id={yourPricelabel} values={{ currency: itemPrice?.currency }} />
            </span>
            <span className="pdp-legacy-your-price-yp-value">
              {yourPrice}
            </span>
          </>

        )}
      </div>
    );
  }
  return null;
};

LegacyAccountPrice.propTypes = {
  itemPrice: PropTypes.object,
  isUserLoggedIn: PropTypes.bool,
  stickyPrice: PropTypes.bool
};

export default LegacyAccountPrice;
