import { MAX_CITATIONS_EDAM_ITEMS } from '../../constants/constants';
import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode } from '../../utils/cookies';
import { CitationActions } from '../actionTypes';

const getCitations = (sku, pageNo, searchValue) => {
  const countryCode = getCountryCode();

  const optionalParams = [`rpp=${MAX_CITATIONS_EDAM_ITEMS}`];

  if (pageNo) {
    optionalParams.push(`pageNo=${pageNo}`);
  }

  if (searchValue) {
    const keyword = window?.encodeURIComponent(searchValue) ?? searchValue;
    optionalParams.push(`keyWord=${keyword}`);
  }

  const url = endpoints.getCitations(sku, countryCode, optionalParams);
  const options = httpOptions.get();

  return apiRequest(CitationActions.GetCitations, url, options);
};

export default getCitations;