import { BundleInformationActions } from '../actionTypes';
import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode, getLanguageCode } from '../../utils/cookies';

export const fetchBundleInformation = (productId) => {
  const countryCode = getCountryCode();
  const languageCode = getLanguageCode();

  const url = endpoints.bundleInformation(productId);
  const options = httpOptions.get({
    countryCode,
    languageCode
  });
  options.payload = {
    sku: productId
  }

  return apiRequest(BundleInformationActions.GetBundleInformation, url, options);
};
