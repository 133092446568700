import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CoreBreadcrumb, CoreBreadcrumbItem } from '@komodo-design-system/komodo-components-react';
import { SHOP_ALL_PRODUCTS_URL } from './Breadcrumbs.Constants';
import DevicesTypes from '../../constants/deviceTypes';

import "./Breadcrumbs.scss";

const Breadcrumbs = ({ activeItem }) => {
  const intl = useIntl();

  const deviceType = useSelector((state) => state.deviceType.deviceType);
  const breadcrumbs = useSelector((state) => state.product?.product?.breadCrumbs);

  const [expand, setExpand] = useState(false);
  
  let actualBreadcrumbs = [
    { name: intl.formatMessage({ id: "home" }), url: "/" },
    { name: intl.formatMessage({ id: "shopAllProducts" }), url: SHOP_ALL_PRODUCTS_URL }
  ];

  const handleExpand = () => {
    setExpand(!expand);
  }

  const showExpand = deviceType === DevicesTypes.Mobile && breadcrumbs;

  if (deviceType !== DevicesTypes.Mobile || expand) {
    actualBreadcrumbs = [...actualBreadcrumbs, ...(breadcrumbs ?? [])];
  }
  else if(showExpand) {
    actualBreadcrumbs.push({ name: '...' })
  }

  return (
    <div className="pdp-breadcrumbs">
      <div>
        <CoreBreadcrumb>
          {actualBreadcrumbs.map(({ name, url }, index) => (
            <CoreBreadcrumbItem key={index} url={url} text={name} divider='▸' />
          ))}
          {(deviceType !== DevicesTypes.Mobile || expand) && (
            <CoreBreadcrumbItem key="product-title">
              <span slot="content"
                className="pdp-breadcrumbs__product-title"
                dangerouslySetInnerHTML={{ __html: activeItem?.productTitleV3 }}
              />
            </CoreBreadcrumbItem>
          )}
        </CoreBreadcrumb>
      </div>
      <div>
      {showExpand && (<i className='php-more-breadcrumb' data-open={expand} onClick={handleExpand} />)}
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  activeItem: PropTypes.object.isRequired
};

export default Breadcrumbs;  