import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CoreModal } from '@komodo-design-system/komodo-components-react';

import "./MobileImageModal.scss";
import MobileImageCarousel from './MobileImageCarousel/MobileImageCarousel';
import { isClientRuntime } from '../../../../../utils/isClientRuntime';
import useDeviceType from '../../../../../hooks/useDeviceType';
import DevicesTypes from '../../../../../constants/deviceTypes';
import { createPortal } from 'react-dom';
import MobileModalSingleImage from './MobileModalSingleImage/MobileModalSingleImage';

const MobileImageModal = ({ isFamily, startImageIdx, images, productTitle, onModalClose }) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  if (!isClientRuntime()) {
    return null;
  }

  const isDesktop = useDeviceType(DevicesTypes.Desktop);

  if (isDesktop === true || !images || images.length === 0) { 
    return null;
  }

  const isSingleImage = images?.length === 1;

  useEffect(() => {
    if (!isDesktop) { 
      const modalWidth = window.innerWidth;
      const modalHeight = 80 * window.innerHeight/100;

      setDimensions({ width: modalWidth, height: modalHeight });
    }
  }, [isDesktop]);

  const handleClose = () => {
    onModalClose?.();
  };

  return createPortal(
    <CoreModal
      className="pdp-mobile-image-modal"
      customcontent
      isopen
      width={dimensions.width}
      height={dimensions.height}
      bgclose="true"
      nooverflow="true"
      closeicon="true"
      onModalClosed={() => handleClose()}
    >
      <div className="pdp-image-mobile-modal-content" style={{ height: `${dimensions.height}px` }} slot='custom-content'>
        
        {isSingleImage && <MobileModalSingleImage isFamily={isFamily} image={images[0]} productTitle={productTitle} />}
        {!isSingleImage && <MobileImageCarousel isFamily={isFamily} startImageIdx={startImageIdx} images={images} productTitle={productTitle} />}

      </div>

    </CoreModal>,
    document.getElementById('modal')
  );
};

MobileImageModal.propTypes = {
  isFamily: PropTypes.bool,
  startImageIdx: PropTypes.number,
  images: PropTypes.array.isRequired,
  productTitle: PropTypes.string,
  onModalClose: PropTypes.func
};

export default MobileImageModal;

