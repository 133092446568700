import { useEffect, useState } from "react";
import useClientApp from "../../../../hooks/useClientApp";

const useAvailabilityTooltipPosition = () => { 
  const isClientApp = useClientApp();
  const [position, setPosition] = useState({ alignment: 'center', placement: 'bottom' });

  useEffect(() => {
    if (isClientApp === false) {
      return;
    }

    const setPositionOnResize = () => { 
      const screenWidth = window.innerWidth;
      if (screenWidth < 575) {
        setPosition({ alignment: 'center', placement: 'top' });
      }
      else if (screenWidth > 992 && screenWidth <= 1300) {
        setPosition({ alignment: 'end', placement: 'top' });
      }
      else if (screenWidth > 1300 && screenWidth < 1650) {
        setPosition({ alignment: 'center', placement: 'top' });
      } else {
        setPosition({ alignment: 'start', placement: 'right' });
      }
    };

    setPositionOnResize();    
    window.addEventListener('resize', setPositionOnResize);

    return () => {
      window.removeEventListener('resize', setPositionOnResize);
    };
  }, []);

  return position;
};

export default useAvailabilityTooltipPosition;