import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PRICE_STATUS } from '../../../../../../constants/constants';
import PriceRFQ from '../../../../../Price/PriceRFQ';
import PricePVNO from '../../../../../Price/PricePVNO';
import PriceDSVP from '../../../../../Price/PriceDSVP';
import PriceLearnOrContactUs from '../../../../../Price/PriceLearnOrContactUs';
import PriceOrderable from '../../../../../Price/PriceOrderable';
import { CoreTooltip } from '@komodo-design-system/komodo-components-react';
import LegacyPriceOrderable from '../../../../../Price/LegacyPrice/LegacyPriceOrderable';

export const BundlePrice = ({
  priceData,
  mobile,
  isLegalChangeAppliedForWOP,
  isSpecialOfferEnabled
}) => {
  const {
    priceAccess,
    formattedPrice,
    currency
  } = priceData;

  let price = null;
  if (priceAccess?.status === PRICE_STATUS.RFQ) {
    price = (
      <PriceRFQ
        priceAccess={priceAccess}
        item={priceData}
      />
    );
  } else if (priceAccess?.status === PRICE_STATUS.CONTACT_US || priceAccess?.status === PRICE_STATUS.LEARN_WHERE_TO_BUY) {
    price = (
      <PriceLearnOrContactUs
        citem={priceData}
        priceAccess={priceAccess}
      />
    );
  } else if (priceAccess?.status === PRICE_STATUS.PVNO) {
    price = (
      <PricePVNO
        priceState={priceData}
      />
    );
  } else if (priceAccess?.status === PRICE_STATUS.ORDERABLE) {
    const content = [
      {
        label: <FormattedMessage id="priceLabel" />,
        value: formattedPrice?.listPrice
      },
      {
        label: <FormattedMessage id="bundleLabelTotalPrice" />,
        value: formattedPrice?.finalPrice
      }
    ];
    if (isSpecialOfferEnabled) {
      price = (
      <>
          <PriceOrderable itemPrice={priceData}/>
          <div>
            <span className="pdp-bundle-cell--total-price pdp-bundle-table__label bold">
              <FormattedMessage id="bundleLabelTotalPrice" />
            </span>
            <span> &nbsp;{formattedPrice?.finalPrice}</span>
          </div>
        </>
      );
    } else {
      price = (
        <>
          {content.map((it, key) => (
            <div
              key={key}
              className={classNames({
                'pdp-bundle-cell--inline': !mobile,
                'pdp-bundle-table__item-detail': mobile
              })}
            >
              <div
                className={classNames({
                  'pdp-bundle-cell-label': !mobile,
                  'pdp-bundle-table__label bold': mobile
                })}
              >
                {it.label}
              </div>
              <div
                className={classNames(
                  {
                    'pdp-bundle-cell-value': !mobile,
                    'pdp-bundle-table__value': mobile
                  },
                  it.className ? it.className : ''
                )}
              >
                {it.value}
                {isLegalChangeAppliedForWOP && key === 0 && (
                  <CoreTooltip
                    alignment='center'
                    placement='bottom'
                  >
                    <span slot="tooltip-content">
                      <FormattedMessage id="listPriceLabel" />
                    </span>
                    <span className='icon-16-info-mono'></span>
                  </CoreTooltip>
                )}
              </div>
            </div>
          ))}
          <LegacyPriceOrderable itemPrice={priceData} />
        </>
      );
    }
  } else if (priceAccess?.status === PRICE_STATUS.NO_PRICE) {
    price = <div> *** </div>;
  } else if (priceAccess?.status === PRICE_STATUS.DSVP) {
    price = <PriceDSVP />;
  }
  //wrap price for mobile
  //add label column and currency as vale
  if (mobile && priceAccess?.status !== PRICE_STATUS.ORDERABLE) {
    price = (
      <div className="pdp-bundle-table__item-detail">
        <div className="pdp-bundle-table__label bold">
          <FormattedMessage
            id="pricelabelcurrency"
            values={{ currency: currency ?? '' }}
          />
        </div>
        <div className="pdp-bundle-table__value">{price}</div>
      </div>
    );
  }
  return price;
};

BundlePrice.propTypes = {
  priceData: PropTypes.object,
  mobile: PropTypes.bool,
  isSubscriptionProduct: PropTypes.bool,
  template: PropTypes.string,
  fromBundle: PropTypes.bool,
  isLegalChangeAppliedForWOP: PropTypes.bool
};
export default BundlePrice;
