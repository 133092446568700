import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode, getLanguageCode } from '../../utils/cookies';
import { MediaFormulationsActions } from '../actionTypes';

const getMediaFormulations = (productId, familyId) => {
  const countryCode = getCountryCode();
  const langCode = getLanguageCode();

  const url = endpoints.getMediaFormulationsUrl(productId, familyId, countryCode, langCode);
  const options = httpOptions.get();

  return apiRequest(MediaFormulationsActions.GetMediaFormulations, url, options);
};

export default getMediaFormulations;
