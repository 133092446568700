import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import StrikeThroughPrice from "./StrikeThroughPrice";

const SpecialOfferPrice = ({ itemPrice }) => {
  return (
    <div className="pdp-price-orderable">
      <div className="pdp-price-orderable__sop-value">
        {itemPrice?.formattedPrice?.specialOffer}
      </div>
      <div className="pdp-price-orderable__sop-text">
        <FormattedMessage id="specialOffer" />
      </div>
      <div className="pdp-price-orderable__exclusive-text">
        <FormattedMessage id="onlineExclusiveText" />
      </div>
      {itemPrice?.specialOfferEndDate && (
        <div className="pdp-price-orderable__sop-enddate">
          <span className="pdp-price-orderable__sop-enddate-text">
            <FormattedMessage id="sopEndsText" />
          </span>
          &nbsp;{itemPrice?.specialOfferEndDate}
        </div>
      )}
      {!!itemPrice?.strikethrough && (
        <StrikeThroughPrice itemPrice={itemPrice} />
      )}
      <div className="pdp-price-orderable__sop-percentage">
        {(itemPrice?.percentageSavings ||
          itemPrice?.formattedPrice?.absoluteSavings) && (
          <span>
            <FormattedMessage id="save"></FormattedMessage>
            {itemPrice?.formattedPrice?.absoluteSavings && (
              <>&nbsp;{itemPrice?.formattedPrice?.absoluteSavings} </>
            )}
            {itemPrice?.percentageSavings && (
              <>{`(${itemPrice?.percentageSavings}%)`}</>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

SpecialOfferPrice.propTypes = {
  itemPrice: PropTypes.object,
};

export default SpecialOfferPrice;
