import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { getCountryCode, getLanguageCode } from '../../utils/cookies';
import { FAQActions } from '../actionTypes';

const getFAQs = (sku) => {
    const countryCode = getCountryCode();
    const langCode = getLanguageCode();

    const url = endpoints.getFAQs(sku, countryCode, langCode);
    const options = httpOptions.get();
  
    return apiRequest(FAQActions.GetFAQs, url, options);
};

export default getFAQs;
