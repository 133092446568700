import { FAQActions } from "../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../actions/status";

export const faqInitialState = {
    faqList: [],
    count: 0,
    loading: false,
    error: null,
    fetched: false
  };
  
  const faq = (state = faqInitialState, action) => {
    const { type, response } = action;
  
    switch (type) {
      case `${FAQActions.GetFAQs}_${SUCCESS}`:      
        return {
          ...state,
          faqList: response?.searchResults?.faqList || [],
          count: response.totalResults || 0,
          loading: false,
          fetched: true
        };
     case `${FAQActions.GetFAQs}_${PENDING}`:      
        return {
          ...state,
          faqList: [],
          loading: true
        };
    case `${FAQActions.GetFAQs}_${ERROR}`:
        return {
          ...state,
          faqList: [],
          loading: false,
          error: action.error,
          fetched: true
        };
    
    default:
        return state;
    }
  };
  
  export default faq;