import { ProductActions } from "../actions/actionTypes";
import { PRODUCT_SIZE_ID } from "../constants/constants";

export const productInitialState = {
  productId: null,
  product: null,
  isFetching: false,
  fetched: false,
  error: null
};

export const processProductResponse = (result) => {
  const newResult = { ...(result ?? {}) };
  newResult.oldSkuDifferentiators = newResult.skuDifferentiatorsV3;

  if (result?.items?.length) {
    newResult.items = result.items.map((item) => {
      if (item.images?.sort && item.images?.length) {
        item.images.sort((a, b) => a.sequence - b.sequence);
      }
      return item;
    });

    // Distinct list of skuDifferentiators from item level
    newResult.skuDifferentiatorsV3 = result.items.reduce((acc, item) => {
      item?.skuDifferentiatorsV3?.forEach((skuDifferentiator) => {
        if (!acc.find((accSkuDifferentiator) => accSkuDifferentiator.id === skuDifferentiator.id)) {
          acc.push(skuDifferentiator);
        }
      });
      return acc;
    }, []).sort((a, b) => a.id === PRODUCT_SIZE_ID ? Number.MAX_VALUE : a.sequence - b.sequence);
  }

  return newResult;
};

const product = (state = productInitialState, action) => {
  const { type, response, error, payload } = action;

  switch (type) {
    case "PRODUCT_FETCH":
      if (error) {
        return {
          ...state,
          error: error
        };
      }
      return {
        ...state,
        product: processProductResponse(response.result)
      };
    case ProductActions.SetActiveItem:
      return {
        ...state,
        productId: payload
      };
    default:
      return state;
  }
};

export default product;