import { QuantityActions } from "../actions/actionTypes";

export const qtyInitialState = {
    quantity: 1
  };
  
  const quantity = (state = qtyInitialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case QuantityActions.setQuantity:      
        return {
          ...state,
          quantity: payload
        };

      default:
        return state;
    }
  };
  
  export default quantity;