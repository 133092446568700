import React from 'react';
import "./PdpTitleAndProductSelector.scss";
import PdpTitle from './PdpTitle/PdpTitle';
import PdpProductSelector from './PdpProductSelector/PdpProductSelector';
import useDeviceType from '../../../hooks/useDeviceType';
import DevicesTypes from '../../../constants/deviceTypes';

const PdpTitleAndProductSelector = () => {
  const isMobile = useDeviceType(DevicesTypes.Mobile);
  const isTablet = useDeviceType(DevicesTypes.Tablet);
  const isTabletOrMobile = isMobile || isTablet;

  return (
    <div className="pdp-title-and-product-selector col-lg-6 col-md-12">
      <PdpTitle showTitle={!isTabletOrMobile} showDescription />
      <PdpProductSelector />
    </div>
  );
};

export default PdpTitleAndProductSelector;