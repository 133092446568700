
import apiRequest from '../../http/apiRequest';
import endpoints from '../../http/endpoints';
import httpOptions from '../../server/http/httpOptions';
import { DictionaryActions } from '../actionTypes';

const getDictionary = (locale) => {

    const url = endpoints.dictionary(locale);
    const options = httpOptions.get();
  
    return apiRequest(DictionaryActions.GetDictionary, url, options);
};

export default getDictionary;

