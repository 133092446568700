import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContentPods } from '../../../../../actions/pod/fetchContentPod';
import './ContentPods.scss';

const ContentPods = ({ contentPods }) => {
  const dispatch = useDispatch();
  const pods = useSelector((state) => state.pods.contentPods?.pods);

  useEffect(() => {
    if (contentPods?.length) {
      contentPods.forEach((pod) => {
        dispatch(fetchContentPods(pod.url, pod.pod));
      });
    }
  }, []);

  let podsContent = [];

  let i = 0;
  for (let podKey in pods) {
    if (pods[podKey]?.length) {
      podsContent.push(
        <div key={i} className='pdp-content-pod-item'>
          <div slot="content" key={`pod-${podKey}`} dangerouslySetInnerHTML={{ __html: pods[podKey] }} ></div>
        </div>
      );
      i++;
    }
  }

  if(podsContent.length > 0) {
    return (
      <div className='pdp-content-pod'>
        {podsContent}
      </div>
    );
  }

  return null;
};

ContentPods.propTypes = {
  contentPods: PropTypes.array,
};

export default ContentPods;