import { MAX_FAQ_SCHEMA_ITEMS } from '../../constants/constants';
export const FAQPAGE_TYPE = 'FAQPage';

const replaceKnownTags = (string) => {
  if (string?.length) {
    string = string.replace(/<br\/>/g, '');
    string = string.replace(/<br \/>/g, '');
    string = string.replace(/<p>/g, '');
    string = string.replace(/<\/p>/g, '');
    return string;
  }
  return '';
};

const getFAQSchema = ({ faq }) => {
  if (faq?.faqList?.length) {
    const faqSchema = {
      '@context': 'https://schema.org',
      '@type': FAQPAGE_TYPE,
      mainEntity: []
    };
    faq.faqList.slice(0, MAX_FAQ_SCHEMA_ITEMS).forEach((question) => {
      faqSchema.mainEntity.push({
        '@type': 'Question',
        name: replaceKnownTags(question.question),
        acceptedAnswer: {
          '@type': 'Answer',
          text: replaceKnownTags(question.answer)
        }
      });
    });
    return faqSchema;
  }
  return null;
};

export default getFAQSchema;
