import React from 'react';
import PropTypes from 'prop-types';

import { CoreButton } from '@komodo-design-system/komodo-components-react';
import { FormattedMessage } from 'react-intl';
import './AddToCart/AddToCart.scss';

const OrderNow = ({ redirectUrl }) => {
  
  const handleOrderNow = (e) => {
    e.preventDefault();
    window.location.href = redirectUrl;
  };

  return (
    <div className='pdp-order-now'>
      <CoreButton
        type="button"
        variant="primary"
        size="medium"
        fullspace
        onClick={(event) => handleOrderNow(event)}
        key="order-now-button"
      >
        <span slot="content">
          <FormattedMessage id="orderNow" />
        </span>
      </CoreButton>
    </div>
  );
};

OrderNow.propTypes = {
  redirectUrl: PropTypes.string
};

export default OrderNow;
