import React from 'react';
import PropTypes from 'prop-types';

import Specifications from '../../NavContainers/ProductOverview/Specifications/Specifications';
import { useIntl } from 'react-intl';
import { MAX_SPECIFICATIONS_CHEMICALS_ROW } from '../../../../constants/constants';
import './ChemicalsIdentifier.scss'

const ChemicalsIdentifier = ({ identifiers }) => {
  const intl = useIntl();
  return (
    <Specifications specifications={identifiers}
      title={intl.formatMessage({ id: 'chemicalIdentifiers' })}
      maxRow={MAX_SPECIFICATIONS_CHEMICALS_ROW}
      showViewMore
      className='col-lg-5 col-md-12'
    />
  );
};

ChemicalsIdentifier.propTypes = {
  identifiers: PropTypes.array,
};

export default ChemicalsIdentifier;