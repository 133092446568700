import { DocumentActions } from "../../actions/actionTypes";
import { SUCCESS, ERROR, PENDING } from "../../actions/status";

const initialState = {
  certificates: [],
  certificatesCount: 0,
  certificatesLoading: false,
  fetched: false,
  showCertificates: false
};

const combineCertificates = (response) => {
  let data;
  const assetTypeData = response?.assetTypes?.[0]?.documentTypes;

  // group all documentTypes for Certificates
  if (assetTypeData?.length) {
    data = assetTypeData?.reduce(
      (accumulator, documentData) => {
        return {
          assets: [...accumulator.assets, ...documentData.assets]
        };
      },
      { assets: [] }
    );
  }

  if (data) {
    return data.assets;
  }
  return null;
};

const documents = (state = initialState, action) => {
  switch (action.type) {
    case `${DocumentActions.GetCertificates}_${SUCCESS}`:
      const isSearchingLotNumbers = !!action.payload.searchValue;
      const numOfCertificates = action.response.assetTypes[0]?.count;

      return {
        ...state,
        certificates: combineCertificates(action.response),
        certificatesCount: numOfCertificates,
        certificatesLoading: false,
        fetched: true,
        // This is to show the certificate links in the UI. Test url that has no lot numbers.
        showCertificates: isSearchingLotNumbers ? state.showCertificates : !!numOfCertificates
      };
    case `${DocumentActions.GetCertificates}_${PENDING}`:
      return {
        ...state,
        certificatesLoading: true,
        fetched: false
      };
    case `${DocumentActions.GetCertificates}_${ERROR}`:
      return {
        ...state,
        certificatesLoading: false
      };
    default:
      return state;
  }
};

export default documents;
