export const calculateProductSelector = (differentiatorId, value, allItems, skuDifferentiators) => {
  if (allItems == null || allItems.length === 0 || skuDifferentiators == null || skuDifferentiators.length === 0) {
    return { differentiators: skuDifferentiators, firstMatchingItem: null };
  }

  allItems = [...allItems];
  
  // Clone
  skuDifferentiators = JSON.parse(JSON.stringify(skuDifferentiators));
  
  const resetActiveForChangingDifferentiator = skuDifferentiators[differentiatorId]?.find((option) => option.value === value)?.active === false;

  skuDifferentiators[differentiatorId]?.forEach((option) => {
    option.active = resetActiveForChangingDifferentiator ? option.value === value : option.active;
    option.selected = option.value === value;
  });

  let allMatchingItems = allItems.filter((item) => item.specifications.find((spec) => spec.id === differentiatorId && spec.value === value) != null);
  const allOtherDifferentiatorIds = Object.keys(skuDifferentiators).filter((everyOtherDiffId) => everyOtherDiffId !== differentiatorId);

  allOtherDifferentiatorIds.forEach((everyOtherDiffId) => {
    const differentiatorValues = skuDifferentiators[everyOtherDiffId];

    let currentlySelectedValue = differentiatorValues?.find((option) => option.selected === true);
    
    differentiatorValues?.forEach((option) => {
      const isApplicable = allMatchingItems.find((item) => item.specifications.find((spec) => spec.id === everyOtherDiffId && spec.value === option.value) != null) != null;
      option.active = isApplicable;
      option.selected = false;
    });

    // Keep previously selected value if it still fits
    if (currentlySelectedValue != null && currentlySelectedValue.active === true) {
      currentlySelectedValue.selected = true;
    }
    // Otherwise select a new one (first) from the active values
    else {
      currentlySelectedValue = differentiatorValues?.find((option) => option.active === true);
      if (currentlySelectedValue != null) {
        currentlySelectedValue.selected = true;
      }
    }    

    allMatchingItems = allMatchingItems.filter((item) => item.specifications.find((spec) => spec.id === everyOtherDiffId && spec.value === currentlySelectedValue?.value) != null);
  });

  return { differentiators: skuDifferentiators, firstMatchingItem: allMatchingItems[0] };
};