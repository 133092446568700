import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SpecificationsIcon from '../../../../../styles/images/icon-specs-sheet.svg'
import { FormattedMessage } from 'react-intl';
import "./Specifications.scss";
import clsx from 'clsx';
import { Log } from '../../../../../utils/log';
import useActiveItem from '../../../../../hooks/useActiveItem';
import Card from '../../../../UI/Card/Card';


const Specifications = ({ title, maxRow = 10, link, showViewMore, addOffset, specifications, className }) => {
  const activeItem = useActiveItem();

  if (!specifications) {
    return null;
  }

  const [isViewMore, setIsViewMore] = useState(specifications.length > maxRow && showViewMore === true ? true : null);

  const getSpecificationsToShow = () => {
    return isViewMore === true ? specifications.slice(0, maxRow) : specifications;
  };

  const onClickViewText = (viewMoreVisible) => {
    setIsViewMore(viewMoreVisible);
  };

  Log("Specifications", { catalogNumber: activeItem?.catalogNumber });

  return (
    <div className={clsx("pdp-specifications", className, { "offset-lg-2": addOffset })}>
      <Card className="pdp-specifications-card nopadding">
        <div className="pdp-specifications__wrapper" slot="content">
          <div className='pdp-specifications__title'>
            <span className='pdp-specifications__text'>{title}</span>
            {link && (
              <span className='pdp-specifications__link'>
                <a onClick={() => window?.open?.(link)} data-testid="specsLink">
                  {<img
                    src={SpecificationsIcon}
                    alt={"Specification Sheet"}
                    className="pdp-specifications__icon"
                  />}
                  <FormattedMessage id="specificationSheet" />
                </a>
              </span>
            )}
          </div>

          {getSpecificationsToShow().map((specification, index) => {
            return (
              <div key={`${specification.id}-${index}`} className='pdp-specification-row'>
                <span className='pdp-specification-row__name'
                  dangerouslySetInnerHTML={{ __html: `${specification?.displayName || specification.name}` }} />
                <span className='pdp-specification-row__value'
                  dangerouslySetInnerHTML={{
                    __html: `${specification.values?.length ? specification.values.join(', ') : specification.value}`
                  }} />
              </div>
            );
          })}

          {isViewMore != null && (
            <div className='pdp-specifications__view-more'>
              <span onClick={() => onClickViewText(!isViewMore)}>
                <FormattedMessage id={isViewMore ? "viewMore" : "viewLess"} />
              </span>
            </div>
          )}

        </div>
      </Card>
    </div>
  );
};

Specifications.propTypes = {
  title: PropTypes.string,
  maxRow: PropTypes.number,
  link: PropTypes.string,
  showViewMore: PropTypes.bool,
  addOffset: PropTypes.bool,
  specifications: PropTypes.array,
  className: PropTypes.string
};

export default Specifications;