import React from 'react';
import PropTypes from 'prop-types';

import './PackageComponents.scss';
import { CoreCard } from '@komodo-design-system/komodo-components-react';
import { FormattedMessage } from 'react-intl';
import PackageComponentsProduct from './PackageComponentsProduct/PackageComponentsProduct';

const PackageComponents = ({ packageComponents }) => { 
  if(!packageComponents?.length) {
    return null;
  }

  const sortedPackageComponents = packageComponents?.sort((a, b) => a.sequenceNum - b.sequenceNum);

  return (
    <div className="pdp-package-components">
      <CoreCard variant='floating' className='pdp-package-components-card' nopadding>
        <div className="pdp-package-components-card-contents" slot="content">
          <div className="pdp-package-components-header">
            <FormattedMessage id='packageComponentsTitle' />
          </div>
          <div className="pdp-package-components-products">
            {sortedPackageComponents.map((product, index) =>
              <PackageComponentsProduct key={index} product={product} />  
            )}
          </div>
        </div>
      </CoreCard>
    </div>
  );
};

PackageComponents.propTypes = {
  packageComponents: PropTypes.array,
};

export default PackageComponents;