import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { CoreNotification } from '@komodo-design-system/komodo-components-react';
import { SkuAlternatives } from '@tfecom-thermofisher/tfcom-sku-alternatives-v2';

import endpoints from '../../../../http/endpoints';
import { getCountryCode, getLanguageCode } from '../../../../utils/cookies';
import { Bold, Link } from '../MessageHelpers/MessageHelpers';
import useSkuAlternatives from '../../../../hooks/useSkuAlternatives';

import "./ProductDiscontinuedBanner.scss";

const ProductDiscontinuedBanner = ({ currentItem, productId, template }) => {
  const intl = useIntl();

  const { ready: skuAlternativesReady, skuAlternatives } = useSkuAlternatives();

  const countryCode = getCountryCode(); 
  const langCode = getLanguageCode(); 

  const hasSkuAlternatives = skuAlternativesReady && skuAlternatives[0]?.skuAlternatives?.length > 0;

  if (currentItem == null) {
    return null;
  }

  if (skuAlternativesReady === false) { 
    return null;
  }

  const catalogNumber = (currentItem.catalogNumber ?? "").toUpperCase();
  const description = currentItem.productTitleV3;
  const discontinued = intl.formatMessage({ id: 'discontinued' });

  const documentSupport = intl.formatMessage({ id: 'documentsSupport' });
  const documentSupportUrl = endpoints.documentsSupportUrl(currentItem.catalogNumber, countryCode, langCode);

  const technicalSupport = intl.formatMessage({ id: 'technicalSupport' });
  const technicalSupportUrl = endpoints.techSupportUrl(currentItem.catalogNumber, countryCode, langCode);

  const analyticsObject = {
    availabilityLocation: template?.toLowerCase(),
    addMethod: 'pdp-commerce-box',
    availabilityPosition: 1,
    totalPositions: 1
  };

  const messageId = hasSkuAlternatives ? "skuAlternativesDiscontdMsg" : "discontinuedProductwarningMessage";
  const message = intl.formatMessage({ id: `${messageId}` }, {
    catalogNumber: <Bold message={catalogNumber} />,
    productTitle: <Bold message={description} />,
    errorStatus: <Bold message={discontinued} />,
    documentsSupportURL: <Link name={documentSupport} url={documentSupportUrl} />,
    technicalSupportURL: <Link name={technicalSupport} url={technicalSupportUrl} />,
    skuAlternativesURL: <span className="pdp-sku-alternatives-link"><SkuAlternatives portalId={"modal"} sku={productId} locale={langCode} analyticsData={analyticsObject} /></span>
  });

  return (
    <div className="product-discontinued-banner">
      <CoreNotification variant="inline" state="warning" nocloseicon>
        <div className="product-discontinued-banner-content" slot="content">
          {message}
        </div>
      </CoreNotification>
    </div>
  );;
};

ProductDiscontinuedBanner.propTypes = {
  currentItem: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  template: PropTypes.string
};

export default ProductDiscontinuedBanner;